import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from "ngx-spinner";
import { HomePageService } from './homepage.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  public courseName: any;
  slider: any;
  homeData = [];
  courseData: any;
  whyTrusityData: any;
  enrollData: any;
  workshopData: any;
  featureData: any;
  videoSection = [];
  secEightData = [];
  testimonials = [];
  secTenData = [];
  secElevenData = [];
  videoSectionLeft : any;
  videoSectionRight : any;
  slideList: any;
  sliderList: any = [];
  courseResponse: any;
  featureResponse: any;
  descData: any;
  activeLanguage;
  actLanguage;
  nodata = false;
  languageList = [];

  top_slider: OwlOptions = {
    loop: true,
    dots: true,
    navSpeed: 200, 
    items:1,
    nav: true,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    slideTransition: 'linear',
    responsive: {
      0: {
        items:1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1
      }
    },
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:30,
    // center:true,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items:1,
        stagePadding: 25,
        margin:10,
      },
      400: {
        items: 1,
        stagePadding: 25,
        margin:10,
      },
      740: {
        items: 2,
        stagePadding: 20,
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
  
  review_slider: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:0,
    navText: ['sas', 'saas'],
    items: 1,
    nav: true,
    responsive: {
      0: {
        items: 1,
        stagePadding: 25,
        margin:10,
      },
      940: {
        items: 1
      }
    },
  }


  logoOption: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:0, 
    items: 5,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items: 2,
        stagePadding: 25,
        margin:10,
      }, 
      400: {
        items: 2,
       stagePadding: 25,
        margin:10,
      },
      740: {
        items: 3,
        stagePadding: 25,
        margin:10,
      },
      940: {
        items: 5
      }
    },
    nav: true,
    
  }

  constructor( private homePageService: HomePageService, private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit(): void {
    sessionStorage.clear()
    this.getHomepage();
  }

  truPreneursClick() {
  // this.route.navigate(['/courses/truPreneurs']);
  window.open("https://forms.zohopublic.com/trusity/form/truIntel/formperma/eE6mqqcIVWy-BPWnJY-HRstO5L49zJzRJqR0PyQPDqE");
  }

  resetLanguage(){
    this.slider  = [];
    console.log(this.slider);
    this.courseData = null
    this.courseResponse  = null;
    this.sliderList  = null;
    this.enrollData  = null;
    this.whyTrusityData  = null;
    this.workshopData  = null;
    this.featureData  = null;
    this.featureResponse  = null;
    this.descData  = null;
    this.videoSection  = null;
    this.videoSectionLeft  = null;
    this.videoSectionRight  = null;
    this.secEightData  = null;
    this.testimonials  = null;
    this.secTenData  = null;
    this.secElevenData  = null;
  }
  getCourse(courseName) {
    console.log(courseName);
    this.courseName = courseName.split(/[ ]+/);
    this.courseName = courseName.replace(/<.*?>/g, '');
    console.log(this.courseName);
    this.router.navigate([`/courses/${this.courseName.toLowerCase()}`]);
}
  
  getHomepage() {
    this.spinner.show();
    if(sessionStorage.getItem('activeLanguage')){
      this.actLanguage = sessionStorage.getItem('activeLanguage');
    } else{
      sessionStorage.setItem('activeLanguage', 'English');
      this.actLanguage = 'English'
    }
    let defaultLanguage;
    const payload = {
      getDataByLanguage: this.actLanguage
    };
    this.homePageService.getHomepage(payload).subscribe(
      (res) => {
        this.homeData = res.data;
        defaultLanguage = res.language;
        if(res.data != null){
         this.spinner.hide();
          if(this.nodata == true){
            this.nodata = false;
          }
          for (let i = 0; i < this.homeData.length; i++) {
            if (this.homeData[i].sectionName == "sectionOne") {
              this.slider = this.homeData[i][defaultLanguage].content.images;
            }
            if (this.homeData[i].sectionName == "sectionTwo") {
              this.courseData = this.homeData[i][defaultLanguage].content;
              this.courseResponse = this.homeData[i][defaultLanguage].content.catMain;
              if (this.courseResponse.length > 0){
                this.sliderList = this.courseResponse[0].slideList;
              }
            }
            if (this.homeData[i].sectionName == "sectionThree") {
              this.enrollData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionFour") {
              this.whyTrusityData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionFive") {
              this.workshopData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionSix") {
              this.featureData = this.homeData[i][defaultLanguage].content;
              this.featureResponse = this.homeData[i][defaultLanguage].content.catLeft;
              if (this.featureResponse.length > 0){
                this.descData = this.featureResponse[0].circleDesc;
              }
            }
            if (this.homeData[i].sectionName == "sectionSeven") {
              // console.log(this.homeData[i][defaultLanguage].content)
              this.videoSection = this.homeData[i][defaultLanguage].content;
              this.videoSectionLeft = this.homeData[i][defaultLanguage].content.catLeft[0];
              this.videoSectionRight = this.homeData[i][defaultLanguage].content.catRight;
            }
            if (this.homeData[i].sectionName == "sectionEight") {
              this.secEightData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionNine") {
              this.testimonials = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionTen") {
              this.secTenData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionEleven") {
              this.secElevenData = this.homeData[i][defaultLanguage].content;
            }
          }
        }
        else{
          this.resetLanguage();
          this.nodata = true;
          this.slider = null;
          this.spinner.hide();
        }
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  selectCourse(id){
    this.sliderList = this.courseResponse[id].slideList;
  }
  selectFeature(id){
    this.descData = this.featureResponse[id].circleDesc;
  }

}
