import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';

@Injectable()
export class QuestionService {
  constructor() {}

  getQuestions(): Observable<any[]> {
    const q = [
      {
        title: "1. Does you child enjoy learning new languages?",
        type: "Linguistic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "1"
      },
      {
        title: "2. Does your child enjoy writing and/or listening to stories?",
        type: "Linguistic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "2"
      },
      {
        title: "3. Does your child enjoy working on new projects, building/creating something with their own hands?",
        type: "Logic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "3"
      },
      {
        title: "4. Does your child enjoy solving logical challenges such as puzzles and games (chess, crosswords and suduko)?",
        type: "Logic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "4"
      },
      {
        title: "5. Can your child easily rearrange shapes, patterns as required, such as in Rubik's cube, puzzles and lego?",
        type: "Spatial",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "5"
      },
      {
        title: "6. Can your child make decisions on their own with respect to their day to day activities?",
        type: "Intrapersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "6"
      },
      {
        title: "7. Does your child love dancing?",
        type: "Kinesthetic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "7"
      },
      {
        title: "8. Does your child feel comfortable in making new friends?",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "8"
      },
      {
        title: "9. Does your child love outings at zoos, parks and beaches?",
        type: "Naturalist",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "9"
      },
      {
        title: "10. Does your child love listening to music?",
        type: "Musical",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "2"
          },
          {
            option: "Somewhat disagree",
            value: "4"
          },
          {
            option: "Neutral",
            value: "6"
          },
          {
            option: "Agree",
            value: "8"
          },
          {
            option: "Strongly agree",
            value: "10"
          }
        ],
        questionId: "10"
      }
    ];
    return of(q);
  }
}
