import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  Channel,
  Event,
  FormatMessageResponse,
  MessageResponse,
  UserResponse,
} from 'stream-chat';
// import { ChatClientService } from '../services/chat-client.service';
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, listUsers } from 'stream-chat-angular';
/**
 * The `ChannelPreview` component displays a channel preview in the channel list, it consists of the image, name and latest message of the channel.
 */
@Component({
  selector: 'app-stream-channel-preview',
  templateUrl: './stream-channel-preview.component.html',
  styles: [],
})
export class ChannelPreviewComponent implements OnInit, OnDestroy {
  /**
   * The channel to be displayed
   */
  @Input() channel: Channel<DefaultStreamChatGenerics> | undefined;
  @Output() channelEvent = new EventEmitter<string>();
  isActive = false;
  isUnread = false;
  unreadCount: number | undefined;
  latestMessage: string = '';
  private subscriptions: (Subscription | { unsubscribe: () => void })[] = [];
  private canSendReadEvents = true;

  constructor(
    private channelService: ChannelService,
    private ngZone: NgZone,
    private chatClientService: ChatClientService
  ) { }

  ngOnInit(): void {
    console.log('channnel')
    console.log(this.channel)
    this.subscriptions.push(
      this.channelService.activeChannel$.subscribe(
        (activeChannel) =>
          (this.isActive = activeChannel?.id === this.channel?.id)
      )
    );
    const messages = this.channel?.state?.latestMessages;
    if (messages && messages.length > 0) {
      this.setLatestMessage(messages[messages.length - 1]);
    }
    this.updateUnreadState();
    const capabilities =
      (this.channel?.data?.own_capabilities as string[]) || [];
    this.canSendReadEvents = capabilities.indexOf('read-events') !== -1;
    this.subscriptions.push(
      this.channel!.on('message.new', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('message.updated', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('message.deleted', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('channel.truncated', this.handleMessageEvent.bind(this))
    );
    this.subscriptions.push(
      this.channel!.on('message.read', () =>
        this.ngZone.run(() => {
          this.updateUnreadState();
        })
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get title() {
    console.log(this.chatClientService?.chatClient?.user)
    
    if (!this.channel) {
      return '';
    }
    return this.getChannelDisplayText(
      this.channel,
      this.chatClientService?.chatClient.user!
    );
  }
  get avatarImage() {
    return this.channel?.data?.image;
  }

  get avatarName() {
    return this.channel?.data?.name;
  }
  getChannelDisplayText(
    channel: Channel<DefaultStreamChatGenerics>,
    currentUser: UserResponse<DefaultStreamChatGenerics>
  ) {
    console.log('preview component')
    if (channel.data?.name) {
      return channel.data.name;
    }
    if (channel.state.members && Object.keys(channel.state.members).length > 0) {
      const members = Object.values(channel.state.members)
        .map((m) => m.user || { id: m.user_id! })
        .filter((m) => m.id !== currentUser?.id);
      return listUsers(members);
    }
    return channel?.id;
  };

  setAsActiveChannel(): void {
    
    console.log('active chnl', this.channel)
    void this.channelService.setAsActiveChannel(this.channel!);
    this.channelEvent.emit(this.channel?.id);
  }

  private handleMessageEvent(event: Event) {
    
    console.log('msg evnt')
    this.ngZone.run(() => {
      if (this.channel?.state.latestMessages.length === 0) {
        this.latestMessage = '';
        return;
      }
      if (
        !event.message ||
        this.channel?.state.latestMessages[
          this.channel?.state.latestMessages.length - 1
        ].id !== event.message.id
      ) {
        return;
      }
      this.setLatestMessage(event.message);
      this.updateUnreadState();
    });
  }

  private setLatestMessage(message?: FormatMessageResponse | MessageResponse) {
    console.log('latest msg', message.text)
    if (message?.deleted_at) {
      this.latestMessage = 'Message deleted';
    } else if (message?.text) {
      this.latestMessage = message.text;
    } else if (message?.attachments && message.attachments.length) {
      this.latestMessage = 'Attachment';
    }
  }

  private updateUnreadState() {
    
    if (this.isActive || !this.canSendReadEvents) {
      this.unreadCount = 0;
      this.isUnread = false;
      return;
    }
    this.unreadCount = this.channel!.countUnread();
    console.log('Unread count')
    console.log(this.unreadCount)
    this.isUnread = !!this.unreadCount;
  }

  async changechannel(username: string, userid: string, usertimestamp: string, channelid: string) {
    // this.activechatwindow = 1;
    // this.uniquechannelid = channelid;
    // this.channelname = username;
    //this.checkopenwindow(channelid, userid);
  }

}