import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

interface CustomCoordinates {
  latitude: number;
  longitude: number;
}

@Injectable({
  providedIn: 'root'
})

export class GeolocationService {
  constructor(private router: Router) {}

  getCurrentLocation(): Promise<CustomCoordinates> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
          },
          error => reject(error)
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }
}