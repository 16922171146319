<div class="w-100 p-2 input-wrap">
  <div class="d-flex align-items-center">
    <textarea placeholder="Type message here..." [(ngModel)]="message" (keyup.enter)="sendmessage()" class="w-90" style="width: 96%;outline: none;border: none"></textarea>
      <input style="display:none" #custominput type="file" accept="image/*,video/*,application/pdf,application/msword,
      application/vnd.openxmlformats-officedocument.wordprocessingml.document,
      application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,
      application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" (change)="fileupload($event)">
      <div (click)="custominput.click()" class="ml-2 mousehover">
      <img src="../../assets/uploadfile.png"/>
      </div>
       <!-- <h3>{{inputname}}</h3> -->
  </div>
</div>
