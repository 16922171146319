import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';

@Injectable()
export class ArDetailedQuestionService {
  constructor() {}

  getQuestions(): Observable<any[]> {
    const q = [
      {
        title: "١  هل يستمتع طفلك بتعلم لغات جديدة ؟",
        type: "Linguistic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "1"
      },
      {
        title: "٢  هل يستطيع طفلك أن يروي قصة بثقة بلغة أخرى ؟",
        type: "Linguistic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "2"
      },
      {
        title: "٣  هل يستمتع طفلك بكتابة القصص و / أو الاستماع إليها ؟",
        type: "Linguistic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "3"
      },
      {
        title: "٤  هل يستمتع طفلك بلعب ألعاب الكلمات وتأليف الشعر ؟",
        type: "Linguistic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "4"
      },
      {
        title: "٥  هل يُعبّر طفلك عن آرائه بثقة ؟",
        type: "Linguistic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "5"
      },
      {
        title: "٦  هل يستمتع طفلك بتصميم ألعاب جديدة ؟",
        type: "Logic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "6"
      },
      {
        title: "٧  هل يستمتع طفلك بحل التحديات المنطقية مثل الألغاز والألعاب (الشطرنج والكلمات المتقاطعة والسودوكو) ؟",
        type: "Logic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "7"
      },
      {
        title: "٨  هل يستمتع طفلك بالعمل على مشاريع جديدة في المدرسة ، وبناء / إنشاء شيء بيديه ؟",
        type: "Logic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "8"
      },
      {
        title: " ٩  هل يستمتع طفلك بفيديوهات / كتب الفضاء أو تجارب المشاريع العلمية ؟",
        type: "Logic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "9"
      },
      {
        title: "١٠  هل طفلك مُنظّم ويحب التخطيط للمستقبل ؟",
        type: "Logic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "10"
      },
      {
        title: "١١ هل بإمكان طفلك / طفلتك إعادة ترتيب الأشكال والأنماط بسهولة كما في لعبة مكعب روبيك والمكعبات ذات الأشكال والليجو ؟",
        type: "Spatial",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "11"
      },
      {
        title: "١٢  هل يمكن لطفلك أن يفهم ويربط بين مفاهيم العدد والمفاهيم المرئية واللون ؟",
        type: "Spatial",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "12"
      },
      {
        title: "١٣  هل يمكن لطفلك اتخاذ القرارات بنفسه فيما يتعلق بأنشطته اليومية ؟",
        type: "Intrapersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "13"
      },
      {
        title: "١٤ هل يفهم طفلك ويقبل ويقدر نقاط القوة والضعف لديه ؟",
        type: "Intrapersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "14"
      },
      {
        title: "١٥  هل يحب طفلك الرقص ؟",
        type: "Kinesthetic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "15"
      },
      {
        title: "١٦  هل يعطي طفلك الأولوية للألعاب خارج المنزل على ألعاب الفيديو ؟",
        type: "Kinesthetic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "16"
      },
      {
        title: "١٧  هل يشعر طفلك بالراحة في تكوين صداقات جديدة ؟",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "17"
      },
      {
        title: "١٨  هل يستمتع طفلك بأن يكون بمفرده في مجموعة ؟",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "18"
      },
      {
        title: "١٩  هل يمد طفلك يد العون للمحتاجين دون أن يطلب منه ذلك ؟",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "19"
      },
      {
        title: "٢٠  هل يتواصل طفلك ويعمل بشكل مريح في المهام الجماعية ؟",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "20"
      },
      {
        title: "٢١  هل يحب طفلك رعاية الحيوانات والنباتات والطبيعة بشكل عام ؟",
        type: "Naturalist",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "21"
      },
      {
        title: "٢٢  هل يحب طفلك التنزه في حدائق الحيوان والمتنزهات والشواطئ ؟",
        type: "Naturalist",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "22"
      },
      {
        title: "٢٣  هل يحب طفلك الاستماع إلى الموسيقى ؟",
        type: "Musical",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "23"
      },
      {
        title: "٢٤  هل يلعب طفلك أو يبدي اهتمامًا بالعزف على آلة موسيقية ؟",
        type: "Musical",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "24"
      },
      {
        title: "٢٥  هل يخلق طفلك الموسيقى الخاصة به ؟",
        type: "Musical",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "1"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "2"
          },
          {
            option: "محايد",
            value: "3"
          },
          {
            option: "موافق",
            value: "4"
          },
          {
            option: "موافق تماماً",
            value: "5"
          }
        ],
        questionId: "25"
      }
    ];
    return of(q);
  }
}
