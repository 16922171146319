import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {
  public displaySpinner;

  constructor() {
    console.log('Hello spinner Provider');
  }

  show() {
    this.displaySpinner = true;
  }

  hide() {
    this.displaySpinner = false;
  }

}
