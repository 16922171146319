<jw-modal id="custom" [ngStyle]="{'display':displayStyle}">
    <!-- <canvas class="popup" id ="confetti-canvas" width="800" height="600"></canvas> -->
  <div style="z-index: 1;">
        <h3>Looking for courses suited for</h3>
        <button class="btn-custom btn-orange2 mt-4" (click)="closePopup()" style="margin: 3%; cursor: pointer;">
            <a class="text-blue" routerLink="">6 - 12 years</a>
        </button>
        <button class="btn-custom btn-orange2 mt-4" style="margin: 3%; cursor: pointer;">
            <a class="text-blue" routerLink="/learning">13 - 21 years</a>
        </button>
    </div>
    <!-- <canvas class="popup" id="confetti-canvas" width="800" height="600"></canvas> -->

</jw-modal>

<div>
    <a href="https://api.whatsapp.com/send?phone=971551411403&text=" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
    </a>
</div>

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<!--  <a href="https://api.whatsapp.com/send?phone=971551411403&text=" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a> --> 

<ngx-spinner bdColor="rgba(255,255,255,0.8)" color="rgb(74 72 72)"></ngx-spinner>
<div *ngIf="nodata" class="nodata-wrap">
    <p>Sorry!! There is no data available.</p>
</div>
    <!-- <div class="flash" style="z-index: 3"> -->
        <!-- <canvas class="popup flash" id="confetti-canvas"></canvas>
        <jw-modal-confetti [@pop] class="flash popup-align center" style="text-align: center">
            <div>
                <h2 class="confetti-font bounce popup">It's our <b style="color: #F79333">BIRTHDAY</b>... <br> We are turning <b style="color: #F79333">THREE</b>!!</h2>
            </div>
            <canvas id ="confetti-canvas"></canvas>
        </jw-modal-confetti> -->
    <!-- </div> -->
    <div class="c_ontainer" style="max-width:100%; padding:0px; margin-bottom: 50px;" *ngIf="slider">
        <div class="row">
            <div class="col-lg-12 top_slider_holder">
                <owl-carousel-o [options]="top_slider"> 
                    <ng-template carouselSlide *ngFor="let slide of slider;">
                        <!-- <ngx-picture src="{{slide.url}}" alt="Proficiency Test" [lazyLoad]="true" class="cursor" (click)="truPreneursClick()"></ngx-picture> -->
                        <!-- <img class="img-fluid cursor" src="{{slide.url}}" (click)="truPreneursClick()" alt="Proficiency Test" /> -->
                        <a href={{slide.link}} aria-label="Trusity"><img class="img-fluid cursor" src="{{slide.url}}" alt="Trusity Banner" /></a>
                        <!-- <img class="img-fluid cursor" src="../../assets/home-latest/banner_home.png" (click)="truPreneursClick()" alt="Proficiency Test" /> -->
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
    <section id="popular_course" class="course_align">
        <!-- <img class="wave_courses" src="../assets/home-latest/wave_2.png" alt="Wave"> -->
        <img class="wave_courses" src="../assets/home-latest/wave1.png" alt="Wave">
            <div class="cards-list">
                <div class="card_info">
                    <div class="card_title title-white" *ngIf="courseData">
                        <h2 class="courses" [innerHTML]="courseData.heading | stripTags"></h2>
                        <h5 class="courses_sub" [innerHTML]="courseData.subheading | stripTags"></h5>
                    </div>
                    <div class="courses session-btn" >
                        <a [routerLink]="['/live-classes']" aria-label="Learn More">Learn More</a>
                    </div>
                </div>
                <div class="card 1"  *ngFor="let course of sliderList">
                    <div class="card_image"> 
                        <img src={{course.slideImg}} alt="Courses"/> 
                    </div>
                    <div class="session-btn1">
                        <a routerLink="/courses/{{course.courseName.toLowerCase() | stripTags}}" aria-label="courses" [innerHTML]="course.linkName | stripTags"></a>
                    </div>
                    <div class="card_title title-white">
                        <h3 class="course_title" [innerHTML]="course.courseName | stripTags"> </h3>
                        <p class="col-sm-6 course_cont" [innerHTML]="course.content | stripTags"></p>
                        <div class="col-sm-6 row course_row">
                            <p class="col-sm-6 course_age" [innerHTML]="course.age | stripTags"></p>
                            <p class="col-sm-5 course_live" [innerHTML]="course.buttonLabel | stripTags"></p>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    <div id="course_carousel" class="c_ontainer" *ngIf="courseData">
        <div class="course_main_title" [innerHTML]="courseData.heading | stripTags"></div>
        <div class="backimage">
            <div class="two-Landing p-0">
                <div class="col-12 p-0 courses-info-holder">
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide *ngFor="let course of sliderList">
                            <div class="courses-info-box">
                                <div class="">
                                    <div>   
                                        <img class="mask_img" src={{course.slideImg}} />
                                        <!-- <ngx-picture class="mask_img" src="{{course.slideImg}}" alt="Purpose" [lazyLoad]="true"></ngx-picture> -->
                                        <h6 class="" [innerHTML]="course.courseName | stripTags"></h6>
                                        <p class="text-light" [innerHTML]="course.content | stripTags"></p>
                                        <div class="session-btn"><a routerLink="/courses/{{course.courseName | stripTags}}" aria-label="live-classes" class="" [innerHTML]="course.buttonLabel | stripTags"></a></div>
                                    </div> 
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
<section *ngIf="secElevenData">
    <div class="title_align">
        <h1 class="tag_hide">Summer Camps for Kids Dubai</h1>
        <h2 class="main_title_journey" [innerHTML]="secElevenData.heading | stripTags"> </h2>
    </div>
    <div class="c_ontainer" *ngIf="whyTrusityData">
        <div class="background"  *ngFor="let news of secElevenData.sliderImages;">
            <!-- <ngx-picture src="{{news.url}}" [lazyLoad]="true" class="img_learning"></ngx-picture> -->
            <img class="img_learning" src="{{news.url}}" />
        </div>
    </div>
</section>

<section class="why_trusity trulntel_project">
    <div class="c_ontainer" *ngIf="whyTrusityData">
        <div class="why_trusity_box">
            <div class="w_box bottomDiv">
                <animated-digit [digit]="23200" [duration]="4000"></animated-digit>
                <p>Students Registered</p>
            </div>
            <div class="w_box bottomDiv">
                <animated-digit [digit]="550" [duration]="4000"></animated-digit>
                <p>Professional Teachers</p>
            </div>
            <div class="w_box bottomDiv">
                <animated-digit [digit]="30000" [duration]="4000"></animated-digit>
                <p>Sessions Delivered</p>
            </div>
        </div>
    </div>
</section>

<!-- <section class="why_trusity trulntel_project">
    <div class="c_ontainer" *ngIf="whyTrusityData">
        <div class="why_trusity_box">
            <div *ngFor="let item of whyTrusityData.catOne;">
                <div class="w_box bottomDiv">
                    <h2 [innerHTML]="item.secHeading | stripTags"></h2>
                    <p [innerHTML]="item.content | stripTags"></p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="grid-container">
    <div class="grid-item">
        <img class="precent_img" src="../../assets/home-professional/Teacher-Quality.png">
    </div>
    <div class="grid-item">
        <img class="precent_img" src= "../../assets/home-professional/Referral.png">
    </div>
    <div class="grid-item">
        <img class="precent_img" src="../../assets/home-professional/Satisfaction_Rate.png">
    </div>
</div>


<section class="mobile_app" >
    <div class="col-sm-12 c_ontainer_app row" *ngIf="featureData">
        <div class="col-sm-6 app_background">
            <img class="img_app" src="../../assets/home-latest/App.png" alt="Trusity App">
        </div>
        <div class="col-sm-6 align_left">
            <h2 class="heading_app" [innerHTML]="featureData.heading | stripTags"></h2>
            <h3 class="brief_app" [innerHTML]="featureData.centerHeading | stripTags"></h3>
            <div class="row app_sec_align" *ngIf="descData">
                <a href="https://play.google.com/store/apps/details?id=com.trusity" _target="blank" aria-label="Play Store" class="cursor app_icon"> <img src="../assets/home-latest/google_play_app.webp" alt="Play Store"> </a>
                <a href="https://apps.apple.com/ae/app/trusity/id1591877200"  target="_blank" aria-label="App Store" class="cursor app_icon"> <img src="../assets/home-latest/app_store.webp" alt="App Store"> </a>
            </div>
        </div>
    </div>
</section>




<section class="course_offred mt-4">
    <div class="c_ontainer" *ngIf="secThirteenData">
        <div class="main_title mb-4" [innerHTML]="secThirteenData.heading | stripTags"></div>
        <div class="backimage">
            <div class="two-Landing p-0">
                <div class="col-12 p-0 courses-info-holder">
                    <owl-carousel-o [options]="customOptions" >
                        <ng-template carouselSlide *ngFor="let content of secThirteenData.catOne;">
                            <div class="purpose courses-info-box">
                                <div>
                                    <div>   
                                        <img class="mask_img" src={{content.url}} alt="Workshop"/>
                                        <!-- <ngx-picture class="mask_img" src={{content.url}} alt="Workshop" [lazyLoad]="true"></ngx-picture> -->
                                        <h6 [innerHTML]="content.headinglabel | stripTags"></h6>
                                        <p class="text-light" [innerHTML]="content.content | stripTags"></p>
                                        <!-- <div class="session-btn">
                                            <a href={{content.date}} aria-label="Workshop Date" [innerHTML]="content.subheading | stripTags"></a>
                                        </div> -->
                                    </div> 
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="teachers_offred">
    <div class="container1" *ngIf="secTwelveData">
        <div class="float-right" >
            <!-- <img src='../assets/home-latest/wave_2.png' alt="Wave"/> -->
            <img src='../assets/home-latest/wave2.png' alt="Wave"/>
        </div> 
       <br>
        <div>
        <div class="title3" [innerHTML]="secTwelveData.heading | stripTags"></div>
        <h5 class="sub_title" [innerHTML]="secTwelveData.subHeading | stripTags"></h5> 
        <br>
                    <div >
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let content of secTwelveData.catOne;">
                                    <div class="container3">
                                        <img class="mask_img1" src={{content.url}} alt="Teachers"/>
                                        <!-- <ngx-picture class="mask_img1" alt="Teachers" src={{content.url}} [lazyLoad]="true"></ngx-picture> -->

                                        <button class="btn1">
                                            <h6 class="teacher_name" [innerHTML]="content.headinglabel | stripTags"></h6>
                                            <h6 class="teacher_brief" [innerHTML]="content.subheading | stripTags"></h6>
                                            <p class="teacher_profile i" [innerHTML]="content.description | stripTags"></p>
                                        </button>
                                    </div>               
                            </ng-template>
                        </owl-carousel-o>
                    </div>
        </div>
    </div>
</section>


<!-- <section id="review_box" class="review_box">
  
    <div class="c_ontainer" *ngIf="testimonials">
        <div class="main_title" [innerHTML]="testimonials.heading | stripTags" ></div>
        <div class="h_holder">
            <owl-carousel-o [options]="review_slider"  [items]="images" [carouselClasses]="['owl-theme', 'sliding']"> 
                <ng-template carouselSlide *ngFor="let test of testimonials.slider;">
                    <div class="courses-info-box">
                         <div id="review_image">
                            <img src="../../assets/home-latest/shape_quote.png" alt="Testimonials">
                             <img class="image_back" src={{test.url}} />
                             

                         </div>
                         <div>
                             <img src="../../assets/home-latest/quote_org.png" alt="Wave"/>
                             <p class="text-light" [innerHTML]="test.content | stripTags" ></p>
                             <h4 [innerHTML]="test.name | stripTags" ></h4>
                         </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->
<section id="review_box" class="review_box">
   
    <div class="c_ontainer" *ngIf="testimonials">
        <div class="main_title_student" [innerHTML]="testimonials.heading | stripTags" ></div>

            <ngb-carousel [options]="review_slider" [items]="images" [carouselClasses]="['owl-theme', 'sliding']"> 
                <ng-template ngbSlide *ngFor="let test of testimonials.slider;">
                    <div class="card courses-info-box">
                         <div id="review_image" alt="Testimonials">
                             <img class="float-left" src={{test.url}} />

                         </div>
                         <div class="session-btnTest">
                            <h4 [innerHTML]="test.name | stripTags" ></h4>
			           
                         </div>
 			            <div class="card_title title-white">
                            <img class ="float-left top" src="../../assets/home-latest/quote_org.png" />
                            <p class="paragraph" [innerHTML]="test.content" ></p>  
                            <img class ="float-right bottom" src="../../assets/home-latest/quote_org.png" />
                        </div>  
                    </div>
                </ng-template>
            </ngb-carousel>

    </div>
</section>
<!-- <section class="events course_offred mt-4">
    <img src="../assets/home-latest/wave_purpose.png">
    <div class="c_ontainer event_align" *ngIf="workshopData">
        <div class="col-sm-12 row">
            <div class="col-sm-4 workshp_main_title mb-4">
                <h2 class="timer_white"> Upcoming Events & Competition </h2>
                <h4 class="timer_white">Join our events and keep up your learning momentum.</h4>
            </div>
            <table class="col-sm-8">
                <thead>
                  <tr>
                    <th>{{daysToDday}} </th>
                    <th>{{hoursToDday}}</th>
                    <th>{{minutesToDday}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="timer_white">Days</td>
                    <td class="timer_white second_td">Hours</td>
                    <td class="timer_white third_td">Minutes</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div class="backimage">
            <div class="two-Landing p-0">
                <div class="col-12 p-0 courses-info-holder">
                    <div class="paddingBlock" >
                        <div class="equalHMRWrap eqWrap">
                            <div class="equalHMR eq" *ngFor="let workshop of workshopData.catOne;">
                                <div class="col-sm-4 events_margin"> <img class="mask_img img_events" src={{workshop.url}} /> </div>
                                <div class="col-sm-5">
                                    <div class="event_sub" style="color: #111F43;" [innerHTML]="workshop.subheading | stripTags"></div> 
                                    <a class="workshops events_date" ><i class="fa fa-calendar margin_icon"></i><span [innerHTML]="workshop.date | stripTags"></span></a>  <br />
                                    <a class="workshops events_time" ><i class="fa fa-clock-o margin_icon"></i><span [innerHTML]="workshop.time | stripTags"></span></a>  <br />
                                    <div class="btn-event">
                                        <a href="https://zfrmz.com/6Hws7ZFGx1jA610hT4qJ">Register</a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="c_ontainer logo_slider slider_container" *ngIf="secTenData">
    <h2 class="main_title_logo" [innerHTML]="secTenData.heading | stripTags"></h2>
    <!-- <div class="main_title" [innerHTML]="secTenData.heading | stripTags"></div> -->
    <div class="slider_container_owl">
        <button class="next" (click)="owlCar.prev()"><img src="../../assets/home-new/arrow_left.png" alt="Left Arrow" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar> 
            <ng-template carouselSlide *ngFor="let school of secTenData.sliderImages;">
                <img src={{school.url}}  class="logo_img" style="height: auto !important" alt="School Logo"/>
                <!-- <ngx-picture style="height: auto !important" class="logo_img" alt="School Logo" src={{school.url}} [lazyLoad]="true"></ngx-picture> -->

            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" alt="Right Arrow"/></button>
    </div>
</div>

<div class="c_ontainer logo_slider slider_container" *ngIf="secOrganizationData">
    <h2 class="main_title_logo1" [innerHTML]="secOrganizationData.heading | stripTags"></h2>
    <!-- <div class="main_title" [innerHTML]="secTenData.heading | stripTags"></div> -->
    <div class="slider_container_owl">
        <button class="next" (click)="owlCar.prev()"><img src="../../assets/home-new/arrow_left.png" alt="Left Arrow" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar> 
            <ng-template carouselSlide *ngFor="let organization of secOrganizationData.sliderImages;">
                <img src={{organization.url}}  class="logo_img" alt="Organization Logo" />
            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" alt="Right Arrow"/></button>
    </div>
</div>
