  
<nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent p-0" style="position: fixed;">
    <a class="navbar-brand" [routerLink]="['/learning']"><img src="./assets/trusity_logo_new.png"></a>

    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
        <!-- <li class="d-block d-md-none"><a href="https://zfrmz.com/OBKYc2XkWIl754fWiBKi" target="_blank">Spring Camp</a></li> -->
        <li class="d-block d-md-none"><a [routerLink]="['/news-events']" target="_blank">News & Events</a></li>
        <li class="d-block d-md-none"><a [routerLink]="['/learning-courses']">Courses</a></li>
        <ul class="navbar-nav align-items-lg-center ml-auto navbar-nav-hover align-items-lg-center">
            <!-- <li><a [routerLink]="['/courses/International-STREAM-Olympiads']">Home</a></li> -->
            <!-- <li><a href="https://zfrmz.com/tVsFPpDxUM8vd4jdifk8" target="_blank">Math Whizkid Junior</a></li> -->
            <!-- <li><a [routerLink]="['/winter-camp']">Winter Camp</a></li> -->
            <!-- <li><a [routerLink]="['/live-classes']">Courses</a></li> -->
            <!-- <li><a href="https://online.trusity.com/">Pilot </a></li>   -->
            <li class="d-block d-md-none"><a href="https://forms.trusity.com/trusity/form/BookAnIntroClass/formperma/6c8eW8LQeVsO-1S5V9zmbXw12rAsw1kmRzRo0yqwa_Q"  target="_blank">Book a Demo</a></li>
            <li class="d-block d-md-none"><a [routerLink]="['/login']">Login / sign up</a></li>
            <!-- <li class="d-block d-md-none"><a href="https://zohosecurepay.com/checkout/jn0k0m8-gpmy140nwt7qz/Your-One-Stop-Learning-Solution" target="_blank">Pay Now</a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['']">Why Trusity? </a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/about-us']"> About Us</a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
            <li class="d-block d-md-none"><a class="" [routerLink]="['/terms-and-conditions']">Terms and Conditions</a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/how-it-works']">How Trusity Works?</a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/become-a-teacher']">Become a Teacher</a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/faq']"> FAQs</a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/blog']"> Blog </a></li> -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/contact-us']"> Contact Us</a></li> -->
            <!-- <li><a href="https://zohosecurepay.com/checkout/jn0k0m8-gpmy140nwt7qz/Your-One-Stop-Learning-Solution">Pay Now</a></li>   -->
            <!-- <li class="d-block d-md-none"><a class="" [routerLink]="['/site-map']"> Site Map </a></li> -->
        </ul>
        <ul class="lg-Btns login_sign_up d-none d-md-inline-flex">
            <!-- <li><a href="https://www.trusity.com/news-events" target="_blank" class="courses">News & Events</a></li> -->
            <li><a [routerLink]="['/news-events']" target="_blank">News & Events</a></li>
            <li><a [routerLink]="['/learning-courses']" class="courses">Courses</a></li>
            <!-- <li class="d-block d-md-none"><a href="https://www.trusity.com/news-events" target="_blank">News & Events</a></li> -->
            <li><a [routerLink]="['/login']">Login / Sign up</a></li>
            <li><a [routerLink]="['/enquire-learning']"><i aria-hidden="true" class="fa fa-calendar"></i>Book a Free Intro</a></li>
        </ul>

        <!-- <ul style="margin-left: 2% !important;" class="payNow navbar-nav align-items-lg-center ml-auto navbar-nav-hover align-items-lg-center"> -->
        <!-- <a class="navbar-brand" style="margin-right: -8%; margin-left: 2%;" [routerLink]="['']"><img src="./assets/home-new/footer_logo_white.jpg"></a> -->
            <!-- <li><a href="https://zohosecurepay.com/checkout/jn0k0m8-gpmy140nwt7qz/Your-One-Stop-Learning-Solution" target="_blank">Pay Now</a></li>   -->
        <!-- </ul> -->
    </div>
</nav>
<div class="nav_space"></div>
 
