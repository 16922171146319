import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hasObjInArray' })
export class HasObjInArrayPipe implements PipeTransform {
    transform(arr: any, itemValue: any, itemName: any): boolean {

        let retval = false;
        if (Array.isArray(arr) && arr.length > 0) {
            if (itemName) {
                let x = arr.filter(u => u[itemName] == itemValue);
                if (x && x.length > 0) {
                    retval = true;
                }
            }
            else {
                let x = arr.filter(u => u == itemValue);
                if (x && x.length > 0) {
                    retval = true;
                }
            }
        }

        return retval;
    }
}

@Pipe({ name: 'hasItemInArrayString' })
export class HasItemInArrayString implements PipeTransform {
    transform(arr: any[], value): boolean {
        if (arr) {
            return (arr.indexOf(value) > -1)
        } else
            return false;
    }
}


@Pipe({ name: 'getItemCountFromArray' })
export class GetItemCountFromArray implements PipeTransform {
    transform(arr: any, itemValue: any, itemName: any): number {
        if (Array.isArray(arr) && arr.length > 0) {
            if (itemName) {
                return arr.filter(u => u[itemName] == itemValue).length;
            }
            else {
                return 0;
            }
        }
        return 0;
    }
}


@Pipe({ name: 'hasTimeInDayOfWeekCustom' })
export class HasTimeInDayOfWeekCustom implements PipeTransform {
    transform(availableDaysAndTimingsArray: any[], dayOfWeek: string, time: string): boolean {

        let retVal = false;
        if (Array.isArray(availableDaysAndTimingsArray) && availableDaysAndTimingsArray.length > 0) {
            var lst = availableDaysAndTimingsArray.filter(u => u.day == dayOfWeek);
            if (lst.length > 0) {
                var timings = lst[0].timings;
                if (Array.isArray(timings) && timings.length > 0) {
                    retVal = (timings.filter(u => u == time).length > 0);
                }
            }
        }
        return retVal;
    }
}


@Pipe({ name: 'hasVacentTimings' })
export class HasVacentTimings implements PipeTransform {
    transform(availableDaysAndTimingsArray: any[], dayOfWeek: string): boolean {

        let retVal = true;
        if (Array.isArray(availableDaysAndTimingsArray) && availableDaysAndTimingsArray.length > 0) {
            var lst = availableDaysAndTimingsArray.filter(u => u.day == dayOfWeek);
            if (lst.length > 0)
                retVal = false;
        }
        return retVal;
    }
}


@Pipe({ name: 'convertToArray' })
export class ConvertToArray implements PipeTransform {
    transform(availableDaysAndTimingsArray): any[] {
        return [];
    }
}