<ngx-spinner bdColor = "rgba(230, 230, 230, 0.4)" size = "large" color = "#111F43"></ngx-spinner>
<main>

  <section class=" section-shaped">
 
    <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 container" style="margin: 0; padding-left: 0px;">
        <div class="row justify-content-center marginmod" style="margin-left: 20%; margin-right: 20%; padding-left: 5px;">
          <!-- <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12" style="margin-left: 0%; margin-right: 0%; padding-left: 5px; "> -->
            <div class="card bg-secondary shadow border-0 card-align">
              <div class="card-body dispchange">
                <div>
                <div  style="text-align: center;">
                  <div class="text-muted text-center" style="font-family: Helvetica-Light">
                    <h3>Forgot Password</h3>
                  </div>
                  <div class="text-muted text-center" style="font-family: Helvetica-Light">
                  <h6>Enter your Email Id registered with Trusity</h6>
                  </div>
                <form role="form" class="dispchange" style="margin:20px; ">
                  
                  <div class="fp-box">
                    <div class="form-group mb-3" [ngClass]="{'focused':focus===true}">
                      <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                        </div>
                        <input class="form-control" placeholder="Email" type="text" (keyup)="errorUsername = false" [(ngModel)]="username" name="username"
                        (focus)="focus=true" (blur)="focus=false" style="font-family: Helvetica-Light">
                      </div>
                      <div *ngIf="errorUsername" class="text-danger small">Please enter your registered Email</div>
                    </div>
                    <div class="text-center" style="margin: auto; padding-top: 20px;">
                      <button type="button" class="btn1 btn-primary hovcolor" style="background:  #172b4d; font-family: Helvetica Bold" (click)="forgotpassword()">Send code</button>
                    </div>
                    
                  </div>
                  
                </form>
              </div>
              </div>

            </div>
          <!-- </div> -->
          </div>
      </div>
    </div>
  </section>
</main>
<!-- <ng-template #changePassword class="modal-content">
  <div class="modal-header">
      <h4 class="modal-title pull-left Helvetica Bold">Enter new password</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="form-group text-center">
          <form novalidate #f="ngForm">
              <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12" style="padding-left: 0px;">
                  <input #pwd="ngModel" [pattern]="passwordPattern" class="form-control Helvetica-Light" id="newpswd" type="password"
                      placeholder="New Password" [(ngModel)]="newPassword" name="new-password">
                  <br>
                  <input #rpwd="ngModel" [pattern]="passwordPattern" class="form-control Helvetica-Light" id="reppswd" type="password"
                      placeholder="Repeat Password" [(ngModel)]="repeatPassword" name="repeat-password">
                  <div class="small text-danger p-t-5" *ngIf="f.invalid">{{passwordError}}</div>
                  <button class="btn btn-primary change-password hovcolor Helvetica Bold  btn-nikiblu col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-t-30" id="change-password" [disabled]="newPassword != repeatPassword"
                      (click)="changePasswordClicked()">Change Password</button>
                  <div class="small text-danger p-t-5" *ngIf="newPassword && repeatPassword && (newPassword != repeatPassword)">Password
                      Mismatch!</div> 
              </div>
          </form>
      </div>
  </div>
</ng-template> -->


<ng-template #changePassword>
  <div class="modal-header">
      <h4 class="modal-title Helvetica-Light pull-left">Forgot password</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="form-group text-center">
          <form novalidate #f="ngForm">
              <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                  <input class="form-control newPassword Helvetica-Light m-b-15" id="otp" type="text" placeholder="Recieved code" [(ngModel)]="otp" name="otp">
                  <!-- <br> -->
                  <input #pwd="ngModel" [pattern]="passwordPattern" class="form-control newPassword Helvetica-Light m-b-15" id="newpswd" type="password" placeholder="New Password"
                      [(ngModel)]="newPassword" name="new-password">
                  <!-- <br> -->
                  <input #rpwd="ngModel" [pattern]="passwordPattern" class="form-control newPassword Helvetica-Light m-b-15" id="reppswd" type="password" placeholder="Repeat Password"
                      [(ngModel)]="repeatPassword" name="repeat-password">
                  <div class="small text-danger p-t-10 Helvetica-Light" *ngIf="f.invalid">{{passwordError}}</div>
                  <div class="small text-danger p-t-10 Helvetica-Light" *ngIf="newPassword && repeatPassword && (newPassword != repeatPassword)">Password Mismatch!</div>
                  <button class="btn hovcolor m-t-30" style="width: auto; color: white; background:  #172b4d; margin-top: 10px; font-family: Helvetica Bold" id="change-password" [disabled]="newPassword != repeatPassword" (click)="changePasswordClicked()">Change Password</button>
              </div>
          </form>
      </div>
  </div>
</ng-template>