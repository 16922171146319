import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-session',
  templateUrl: './view-session.component.html',
  styleUrls: ['./view-session.component.css']
})
export class ViewSessionComponent implements OnInit {
  sessionId;
  name = 'Set iframe source';
  // url: string = "https://www.google.com" + "/webhp?igu=1"
  urlSafe: SafeResourceUrl;

  constructor(private activatedRoute: ActivatedRoute, public sanitizer: DomSanitizer) {
    // this.sessionId = this.activatedRoute.snapshot.params['id'];
    // console.log(this.sessionId);
    // var newLink = "https://www.thelessonspace.com/space/" + this.sessionId;
    //this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(newLink);
    let newlink = sessionStorage.getItem('lessonSpaceLink');
    //console.log(newlink);
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(newlink);

    //console.log(this.urlSafe);

   }

  ngOnInit(): void {

  }

}
