import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import {trigger,state,style,animate,transition}from '@angular/animations';
// import { flipAnimation } from 'angular-animations';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; 
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { OwlModule } from 'ngx-owl-carousel';
// import { ConfettiService } from './services/confetti.service';
// import { CLOUDINARY_CONFIG, NgxPictureModule } from 'ngx-picture';
import { AnimatedDigitComponent } from "./animated/animated-digit.component";
import { AppComponent } from './app.component';
import { HomepageComponent } from '././home-all/homepage/homepage.component';
import { HomeIndiaComponent } from './home-india/home-india.component';
import { HomeLatestComponent } from './home-latest/home-latest.component';
import { HomeProfessionalComponent } from './home-professional/home-professional.component';
import { HomePageService } from './home-all/homepage/homepage.service';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { NavbarSetComponent } from './shared/navbar-set/navbar-set.component';
import { NavbarEnquireComponent } from './shared/navbar-enquire/navbar-enquire.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FooterRedirectComponent } from './shared/footer-redirect/footer-redirect.component';
import { NavbarIndiaComponent } from './shared/navbar-india/navbar-india.component';
// import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ModalModule } from "ngx-bootstrap/modal";
import { NewModalModule } from './_modal';
import { NewModalConfettiModule } from './_modal-confetti';

// Services
import { DataService } from './services/data.service';
import { QuestionService } from './services/questions.service';
import { StdFormService } from './services/std-forms.service';
import { TeacherFormService } from './services/teacher-forms.service';
import { ArQuestionService } from './services/ar-questions.service';
import { DetailedQuestionService } from './services/detailedQuestions.service';
import { ArDetailedQuestionService } from './services/ar-detailedQuestions.service';
import { HttpClientModule } from '@angular/common/http';
import { Constants } from './services/constants';
import { ToastMsgService } from './services/toasterMsg.service';
import { CacheService } from './services/cache.service';
import { UrlConstants } from './services/url.constants';
import { SpinnerService } from './services/spinner.service'; 
import { HelperService } from './services/helper.service';
import { CustomPreloadingStrategy } from './services/CustomPreloadingStrategy.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserLoginService } from "./services/user.service";
import { CognitoUtil } from "./services/cognito.service";
import { UploadFileService } from "./services/fileUpload.service";
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CommonModule, DatePipe } from '@angular/common'; 
import { ArrayPipeModule } from './array-pipe/array-pipe.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { StripTagsPipe } from './home-all/homepage/removeHtmlPipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// stream io chat module added by Kunal bansal on o4 july 2022 start
import { StreamChatModule, StreamAutocompleteTextareaModule } from 'stream-chat-angular';
import { TranslateModule } from '@ngx-translate/core';
// ...end...
// .................added by Kunal bansal on 15 july 2022 start...............
import { StreamIoComponent } from './streamiotesting/streamio.component';
import { ChatHeaderComponent } from './chat-header/chat-header.component';
import { SidebarComponent } from './chat-sidebar/chat-sidebar.component';
// .................added by Kunal bansal on 20 july 2022 start...............
import { CustomStreamInputComponent } from './customstreaminput/customstreaminput.component';
import { ChannelPreviewComponent } from './stream-channel-preview/stream-channel-preview.component';
import { ExternalRedirectComponent } from './external-redirect/external-redirect.component';
// ..........end............
// import { DemoComponent } from './demo/demo-form/demo-form.component';
// import { DemoRescheduleComponent} from './demo/demo-reschedule/demo-reschedule.component';
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        NavbarComponent,
        NavbarSetComponent,
        NavbarEnquireComponent,
        HomeProfessionalComponent,
        FooterComponent,
        FooterRedirectComponent,
        NavbarIndiaComponent,
        AnimatedDigitComponent,
        // LoginComponent,
        ForgotPasswordComponent,
        ForgotPasswordComponent,
        HomeLatestComponent,
        HomeIndiaComponent,
        HomepageComponent,
        StripTagsPipe,
        // DemoComponent,
        StreamIoComponent,
        ChatHeaderComponent,
        SidebarComponent,
        CustomStreamInputComponent,
        ChannelPreviewComponent,
        ExternalRedirectComponent,
        // DemoRescheduleComponent,
    ],
    // entryComponents: [AgGridNoteListComponent, AgGridNotesCustomTooltipComponent, AgGridResourceListComponent, AgGridResourceCustomTooltipComponent],
    imports: [
        // NgxPictureModule.forRoot(CLOUDINARY_CONFIG),
        EditorModule,
        CarouselModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppRoutingModule,
        HttpClientModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        OwlModule,
        ModalModule.forRoot(),
        NgxSpinnerModule,
        ToastrModule.forRoot(), // ToastrModule added
        // FullCalendarModule, // register FullCalendar with you app
        NgMultiSelectDropDownModule.forRoot(),
        FullCalendarModule, // register FullCalendar with you app
        CommonModule,
        ArrayPipeModule,
        
        // DemoComponent,
        NewModalModule,
        NewModalConfettiModule,
        // stream io chat module added by Kunal bansal on o4 july 2022 start
        TranslateModule.forRoot(), StreamAutocompleteTextareaModule, StreamChatModule,
        // AgGridModule.withComponents([AgGridNotesCustomTooltipComponent, AgGridResourceCustomTooltipComponent]),
    ],
    providers: [
        CustomPreloadingStrategy,
        // ConfettiService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: AuthHttpInterceptor,
        //     multi: true,
        // },
        DatePipe,
        DataService,
        NgxSpinnerService,
        ToastMsgService,
        StdFormService,
        TeacherFormService,
        QuestionService,
        ArQuestionService,
        DetailedQuestionService,
        ArDetailedQuestionService,
        Constants,
        CacheService,
        UrlConstants,
        SpinnerService,
        HelperService,
        UserLoginService,
        CognitoUtil,
        UploadFileService,
        Meta,
        HomePageService,
        FooterComponent,
        FooterRedirectComponent,
        NavbarIndiaComponent,
        HomepageComponent,
        StripTagsPipe,
    ],

        
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule { }
