import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';

@Injectable()
export class ArQuestionService {
  constructor() {}

  getQuestions(): Observable<any[]> {
    const q = [
      {
        title: "١  هل يستمتع طفلك بتعلم لغات جديدة ؟",
        type: "Linguistic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "1"
      },
      {
        title: "٢  هل يستمتع طفلك بكتابة القصص و / أو الاستماع إليها ؟",
        type: "Linguistic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "2"
      },
      {
        title: "٣  هل يستمتع طفلك بالعمل على مشاريع جديدة في المدرسة ، وبناء / إنشاء شيء بيديه ؟",
        type: "Logic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "3"
      },
      {
        title: "٤  هل يستمتع طفلك بحل التحديات المنطقية مثل الألغاز والألعاب (الشطرنج والكلمات المتقاطعة والسودوكو) ؟",
        type: "Logic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "4"
      },
      {
        title: "٥  هل بإمكان طفلك / طفلتك إعادة ترتيب الأشكال والأنماط بسهولة كما في لعبة مكعب روبيك والمكعبات ذات الأشكال والليجو ؟",
        type: "Spatial",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "5"
      },
      {
        title: "٦  هل يمكن لطفلك اتخاذ القرارات بنفسه فيما يتعلق بأنشطته اليومية ؟",
        type: "Intrapersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "6"
      },
      {
        title: "٧  هل يحب طفلك الرقص ؟",
        type: "Kinesthetic",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "7"
      },
      {
        title: "٨  هل يشعر طفلك بالراحة في تكوين صداقات جديدة ؟",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "8"
      },
      {
        title: "٩  هل يحب طفلك التنزه في حدائق الحيوان والمتنزهات والشواطئ ؟",
        type: "Naturalist",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "9"
      },
      {
        title: "١٠  هل يحب طفلك الاستماع إلى الموسيقى ؟",
        type: "Musical",
        selected_answer: [
          {
            option: "لا أوافق تماماً",
            value: "0"
          },
          {
            option: "لا أوافق",
            value: "2"
          },
          {
            option: "لا أوافق إلى حد ما",
            value: "4"
          },
          {
            option: "محايد",
            value: "6"
          },
          {
            option: "موافق",
            value: "8"
          },
          {
            option: "موافق تماماً",
            value: "10"
          }
        ],
        questionId: "10"
      }
    ];
    return of(q);
  }
}
