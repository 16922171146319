<footer class="footer">
	<div class="c_ontainer" *ngIf="footerData">
		<div class="row f_top">
			<div class="col">
				<img class="" src="{{footerData.catOne.footerCatOneImageUrl}}" class="image-trusity" alt="Trusity Logo" height="50" />
				<p class="mt-3"><i aria-hidden="true" class="white-text p-l-0"></i>{{footerData.catOne.address | stripTags}}</p>
				<p class="m-0"><i aria-hidden="true" class="white-text p-l-0"></i><span [innerHTML]="footerData.catOne.phoneNo | stripTags"></span></p>
				<!-- <p><i _ngcontent-serverapp-c127="" aria-hidden="true" class="white-text p-l-0"></i> <span [innerHTML]="footerData.catOne.emailId | stripTags"></span></p> -->
				<div>
					<button class="btn btn-KHDA">KHDA PERMITTED</button>
				</div>
				<!-- <img  src="../../../assets/home-new/footer_logo_white.jpg" class="footer_khadi_logo"> -->
        	</div>
				
				<div class="column">
					<ul class="white-text">
						<h4 class="white-text" [innerHTML]="footerData.catThree.headingThree | stripTags"></h4>
						<li *ngFor="let link of footerData.catThree.linkThreeDyanamic">
							<!-- routerLink="{{link.linkUrl | stripTags}}"  -->
							<a class="white-text" (click)="routeToLink(link.linkUrl)" aria-label="Footer Links" [innerHTML]="link.linkName | stripTags"> </a>
							
						</li>
					</ul>
					
				</div>
				<!-- <div class="col">
					<ul class="white-text">
						<h4 class="white-text" [innerHTML]="footerData.catThree.headingThree | stripTags"></h4>
						<li *ngFor="let link of footerData.catThree.linkThreeDyanamic">
					
							<a class="white-text" (click)="routeToLink(link.linkUrl)" aria-label="Footer Links" [innerHTML]="link.linkName | stripTags"> </a>
						</li>
					</ul>
				</div> -->
				<!-- <div class="col white-text">
					<h4 class="white-text" [innerHTML]="footerData.catFour.headingFour | stripTags"></h4>
      				<div class="sec mt-3" *ngFor="let info of footerData.catFour.infoMore">
						<h5 class="white-text" [innerHTML]="info.heading | stripTags"></h5>
						<p [innerHTML]="info.content | stripTags"></p>
					</div>
				</div> -->

				<div class="col subscribe">
				    <p class="news">Subscribe to our Newsletter</p>
					<form [formGroup]="subscribeForm" (ngSubmit)="subscribe(subscribeForm.value)">
						<div>
							<input type="text" id="inputEmail" class="emailAddr" formControlName="emailId" placeholder="Enter Your Email">
							<div>
								<span *ngIf="subscribeForm.get('emailId').touched && subscribeForm.get('emailId').hasError('required')" class="error-msg">Please enter your Email.</span>
							</div>
						</div>
						<div>
							<button type="submit" class="btn_1 cursor">Subscribe</button>
						</div>
					</form>
					<p>
						<a *ngFor="let item of footerData.catOne.socialIcons" aria-label="Social Media" class="btn btn-icon-only" target="_blank" href="{{item.url}}" ngbTooltip="Like us">
							<img [src]="item.socialImg" alt="Social Icons">
						</a>
						
					</p>
				</div>
				<!-- <div class="col">
					<ul class=""> 
						<h4 class="white-text" [innerHTML]="footerData.catTwo.headingTwo | stripTags"></h4>
						<li *ngFor="let link of footerData.catTwo.linkTwoDyanamic" class="white-text">
							<a href="tel:{{link.linkName | stripTags}}" class="white-text" aria-label="Footer Link" [innerHTML]="link.linkName | stripTags"> </a>
						</li>
						<li *ngFor="let info of footerData.catFour.infoMore" class="white-text">
							<a href="mailto:{{info.heading | stripTags}}" class="white-text" aria-label="Footer Link" [innerHTML]="info.heading | stripTags"> </a>
						</li>
					</ul>
			
				</div> -->
        	</div>
			
      	</div>
		<div class="f_bottom">
			<div class="c_ontainer">
				<div class="row align-items-center">
					<div class="col">
						Copyright © 2023 Trusity Innovations Learning. All Rights Reserved
					</div>
					<div class="Helvetica-Light justify-p">
						<!-- <h4 class="title white-text m-l-15">Payments</h4> -->
						<!-- <div class="m-l-20">
							<a><img
								src="../../assets/img/icons/common/payment-logos.png" width="122" height="38"
								alt="Merchant Equipment Store Credit Card Logos"/></a>
						</div> -->
					</div>
					<!-- <div class="col text-right row white-text">
						<a  class="white-text" routerLink="['/terms-and-conditions']">
							Terms of service
						</a>
						<a  class="white-text" routerLink="['/privacy-policy']">
							Privacy Policy
						</a>
						<a  class="white-text" routerLink="['/site-map']">
							Sitemap
						</a>
					</div> -->
				</div>
			</div>
		</div> 
    </footer>


