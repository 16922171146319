<ngx-spinner bdColor="rgba(255,255,255,0.8)" color="rgb(74 72 72)"></ngx-spinner>
<!-- <app-navbar></app-navbar> -->
<div *ngIf="nodata" class="nodata-wrap">
    <p>Sorry!! There is no data available.</p>
</div>
<!-- <app-sections></app-sections> -->
<div class="c_ontainer" style="max-width:100%; padding:0px; margin-bottom: 50px;" *ngIf="slider">
    <div class="row">
        <div class="col-lg-12 top_slider_holder">
            <owl-carousel-o [options]="top_slider" class=""> 
                <ng-template carouselSlide *ngFor="let slide of slider;">
                    <img class="img-fluid" src="{{slide.url}}" (click)="truPreneursClick()" alt="Proficiency Test" />
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>

<section class="why_trusity">
    <div class="c_ontainer" *ngIf="whyTrusityData">
        <div class="main_title" [innerHTML]="whyTrusityData.heading | stripTags"></div>
        <div class="why_trusity_box">
            <div class="w_box" *ngFor="let item of whyTrusityData.catOne;">
                <img src="{{item.url}}" />
                <h2 [innerHTML]="item.secHeading | stripTags"></h2>
                <p [innerHTML]="item.content | stripTags"></p>
            </div>
        </div>
    </div>
</section>

<!-- <section class="trulntel_project">
    <div class="c_ontainer" *ngIf="secEightData">
        <div class="row">
            <div class="col-sm-6">
                <img src="{{secEightData.url}}" class="img-fluid" />
            </div>
            <div class="col-sm-6">
                <h1 [innerHTML]="secEightData.heading | stripTags"></h1>
                <h2 [innerHTML]="secEightData.secondHeadings | stripTags"></h2>
                <p [innerHTML]="secEightData.content | stripTags"></p>
                <a href="https://zfrmz.com/AyQKwmSaV7tsD2y4z6oD" target="_blank" class="btn-custom" [innerHTML]="secEightData.buttonLabel | stripTags"></a>
            </div>
        </div>
    </div>
</section> -->

<div class="c_ontainer" *ngIf="courseData">
    <div class="main_title" [innerHTML]="courseData.heading | stripTags"></div>
    <div class="backimage">
        <div class="two-Landing p-0">
            <div class="row courses_icons" >
                <div class="" (click)="selectCourse(i)" *ngFor="let item of courseData.catMain; let i=index">
                    <img src="{{item.labelIcon}}" >
                    <span [innerHTML]="item.label | stripTags"></span>
                </div>
            </div>
            <!-- <div class="col-12 p-0 courses-info-holder" *ngFor="let slide of courseData.catMain"> -->
            <div class="col-12 p-0 courses-info-holder">
                <owl-carousel-o [options]="customOptions" class=""  >
                    <ng-template carouselSlide *ngFor="let course of sliderList">
                        <div class="courses-info-box">
                            <div class="">
                                <div>   
                                    <img class="mask_img" src={{course.slideImg}} />
                                    <h6 class="" [innerHTML]="course.courseName | stripTags"></h6>
                                    <p [innerHTML]="course.content | stripTags"></p>
                                    <div (click)="getCourse(course.courseName)" class="learnMore" [innerHTML]="course.linkName | stripTags"></div> &#8594;
                                    <div class="session-btn"><a [routerLink]="['/enquire']" class="" [innerHTML]="course.buttonLabel | stripTags"></a></div>
                                </div> 
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>

            </div>
        </div>
    </div>

    <!-- <section class="get_started" *ngIf="courseData">
        <div class="row">
            <div class="col-sm-6">
                <p [innerHTML]="courseData.catAction.actionHeading | stripTags"></p>
                <a [routerLink]="['/enquire']" class="btn-custom btn-orange2" [innerHTML]="courseData.catAction.actionButton | stripTags"></a>
            </div>
            <div class="col-sm-6">
                <img src="{{courseData.catAction.actionImg}}" />
            </div>
        </div>
    </section> -->

    <!-- <section class="learn_more">
        <div class="row" *ngIf="enrollData">
            <div class="col-sm-7">
                <img src="{{enrollData.url}}" />
            </div>
            <div class="col">
                <div class="learn-more-info">
                    <div class="main_title" [innerHTML]="enrollData.heading | stripTags"></div>
                    <p [innerHTML]="enrollData.content | stripTags"></p>
                    <a [routerLink]="['/enquire']" class="btn-custom btn-orange2 mt-4" [innerHTML]="enrollData.buttonLabel | stripTags"></a>
                </div>
            </div>
        </div>
    </section> -->

    <!-- <section class="get_started get_started2">
        <div class="row">
            <div class="col-sm-6">
                <p>Book a FREE Introductory Session for any course of your choice</p>
                <a href="" class="btn-custom btn-orange">GET STARTED</a>
            </div>
            <div class="col-sm-6">
                <img src="../../assets/home-new/get_started_img.png" />
            </div>
        </div>
    </section> -->
</div>

<section class="course_offred mt-4">
    <div class="c_ontainer" *ngIf="workshopData">
        <div class="main_title mb-4" [innerHTML]="workshopData.heading | stripTags"></div>
        <div class="backimage">
            <div class="two-Landing p-0">
                <div class="col-12 p-0 courses-info-holder">
                    <owl-carousel-o [options]="customOptions" >
                        <ng-template carouselSlide *ngFor="let workshop of workshopData.catOne;">
                            <div class="courses-info-box">
                                <div>
                                    <img class="mask_img" src={{workshop.url}} />
                                    <!-- <h6 class="" [innerHTML]="workshop.heading | stripTags"></h6> -->
                                    <!-- <p [innerHTML]="workshop.content | stripTags"></p> -->
                                    <div class="bold" style="color: #111F43;" [innerHTML]="workshop.subheading | stripTags"></div>
                                    <div ><a class="workshops" ><span [innerHTML]="workshop.date | stripTags"></span></a> 
                                    </div>
                                    <div ><a class="workshops"><span [innerHTML]="workshop.time | stripTags"></span></a> </div>
                                    <div style="margin-bottom: 18px;"><a class="workshops"><span [innerHTML]="workshop.tutor | stripTags"></span></a> </div>
                                    <div (click)="getCourse(course.name)"><a href="" class="learnMore">Learn More &#8594;</a></div>
                                    <div class="session-btn">
                                        <a href= "https://zfrmz.com/6Hws7ZFGx1jA610hT4qJ" target="_blank" [innerHTML]="workshop.buttonLabel | stripTags"></a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="learning_exp">
    <div class="c_ontainer" *ngIf="featureData">
        <div class="main_title"  [innerHTML]="featureData.heading | stripTags"></div>
        <div class="learning_exp_c">
            <div class="learning_exp_l">
                <div class="learning_exp_lh">
                    <div (click)="selectFeature(i)"  [innerHTML]="feature.centerLabel | stripTags" *ngFor="let feature of featureData.catLeft; let i=index"></div>
                    <p  [innerHTML]="featureData.centerHeading | stripTags"></p>
                </div>
            </div>
            <div class="learning_exp_r">
                <div class="">
                    <div *ngIf="descData">
                        <h5 class="" [innerHTML]="descData.subHeading | stripTags"></h5>
                        <img src={{descData.desImg}} class="">
                        <div class="">
                            <span class="" [innerHTML]="descData.subContent | stripTags"></span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>

<!-- <section class="how_it_works">
    <div class="c_ontainer" *ngIf="videoSection">
        <div class="main_title" [innerHTML]="videoSection.heading | stripTags"></div>
        <div class="h_holder">
            <div class="h_holder_l" *ngIf="videoSectionLeft">
                <video width="320" height="240" controls>
                    <source [src]="videoSectionLeft.videoUrl  | stripTags" type="video/mp4">
                    Your browser does not support the video tag.
                  </video> 
                <div class="watch_video" [innerHTML]="videoSectionLeft.videoContent | stripTags">
                </div>
            </div>
            <div class="h_holder_r" *ngIf="videoSectionRight">
            <h4 [innerHTML]="videoSectionRight.stepHeading | stripTags"></h4>
                <p *ngFor="let item of videoSectionRight.points" [innerHTML]="item.stepContent | stripTags"></p>
            </div>
        </div>
    </div>
</section> -->


<section class="review_box">
    <div class="c_ontainer" *ngIf="testimonials">
        <div class="main_title" [innerHTML]="testimonials.heading | stripTags" ></div>
        <div class="h_holder">
            <owl-carousel-o [options]="review_slider"  [items]="images" [carouselClasses]="['owl-theme', 'sliding']"> 
                <ng-template carouselSlide *ngFor="let test of testimonials.slider;">
                    <div class="courses-info-box">
                         <div>
                             <img src={{test.url}} />
                         </div>
                         <div>
                             <img src="../../assets/home-new/quotes.png" />
                             <p [innerHTML]="test.content | stripTags" ></p>
                             <h4 [innerHTML]="test.name | stripTags" ></h4>
                         </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

<div class="c_ontainer logo_slider slider_container"  *ngIf="secTenData">
    <div class="main_title" [innerHTML]="secTenData.heading | stripTags"></div>
    <div class="slider_container_owl">
        <button class="next" (click)="owlCar.prev()"><img src="../../assets/home-new/arrow_left.png" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar> 
            <ng-template carouselSlide *ngFor="let school of secTenData.sliderImages;">
                <img src={{school.url}}  class="logo_img" />
            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" /></button>
    </div>
</div>

<div class="c_ontainer logo_slider slider_container"  *ngIf="secElevenData">
    <div class="main_title" [innerHTML]="secElevenData.heading | stripTags"></div>
    <div class="slider_container_owl">
        <button class="next" (click)="owlCar2.prev()"><img src="../../assets/home-new/arrow_left.png" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar2> 
            <ng-template carouselSlide *ngFor="let news of secElevenData.sliderImages;">
                <img src={{news.url}}  class="logo_img" />
            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" /></button>
    </div>
</div>
<!-- <app-footer></app-footer> -->