import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterNavbarSharedService {
  private currentData = new BehaviorSubject<any>('');
  currentDataFetch = this.currentData.asObservable();
  
  constructor() { }
  
  emitData(newData){
    this.currentData.next(newData);
  }
}
