  
<nav *ngIf="headerData" id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent p-0" [ngClass]="{ sticky: scrolled }">
	<a class="navbar-brand" href="https://trusity.com" aria-label="Trusity"><img src="{{headerData.logoUrl}}" alt="Trusity Logo"></a>
	<button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
      <span class="navbar-toggler-icon"></span>
	</button>
	<div class="navbar-collapse collapse new-nav dy-nav" id="navbar_global" [ngbCollapse]="isCollapsed">
		<ul *ngIf="!loggedVisible" class="cursor navbar-nav align-items-lg-center ml-auto navbar-nav-hover align-items-lg-center">
			<li *ngFor="let item of menuList">
				<!-- <a routerLink="{{item.url | stripTags}}" [innerHTML]="item.menu | stripTags"></a></li> -->
			<a class="" (click)="routeToLink(item)" aria-label="Nav Links" [innerHTML]="item.menu | stripTags"> </a>
			<!-- <li> <a class="navbar-brand" style="margin-right: -8%; margin-left: -3%; border: none !important; background: transparent !important;" [routerLink]="['']"><img src="./assets/home-new/footer_logo_white.jpg"></a> </li> -->
		</ul>
		<ul class="navbar-nav align-items-lg-center mr-auto navbar-nav-hover align-items-lg-center lang-box">
			<li>
				<select class="form-control" (change)="changeLanguage($event)" [(ngModel)]="activeLanguageMain">
					<option [value]="language" *ngFor="let language of languageList" >{{language}}</option>
				  </select>
			</li>
		</ul>
	</div>
	<!-- <div class="navbar-collapse collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
		<ul *ngIf="!showFooter() && !hideFooter() && !hideFooterSchedule() && !hideFootermyChildCalender() && !hideInvoices() && !hideEditAccount() && !hideAddChild() && !hideFooterChangePwd()"  class="navbar-nav align-items-lg-center ml-auto navbar-nav-hover align-items-lg-center">
			<li><a href="https://zfrmz.com/tVsFPpDxUM8vd4jdifk8" target="_blank">Math Whizkid Junior</a></li>
			<li><a [routerLink]="['/live-classes']">Courses</a></li>
			<li><a href="https://online.trusity.com/">Pilot </a></li>  
			<li class="d-block d-md-none"><a [routerLink]="['/enquire']">Book a Free Demo</a></li>
			<li class="d-block d-md-none"><a [routerLink]="['/login']">Login / sign up</a></li>
			<li class="d-block d-md-none"><a href="https://zohosecurepay.com/checkout/jn0k0m8-gpmy140nwt7qz/Your-One-Stop-Learning-Solution" target="_blank">Pay Now</a></li>
			<li class="d-block d-md-none"><a class="" [routerLink]="['']">Why Trusity? </a></li>
			<li class="d-block d-md-none"><a class="" [routerLink]="['/about-us']"> About Us</a></li>
			<li class="d-block d-md-none"><a class="" [routerLink]="['/how-it-works']">How Trusity Works?</a></li>
			<li class="d-block d-md-none"><a class="" [routerLink]="['/faq']"> FAQs</a></li>
			<li class="d-block d-md-none"><a class="" [routerLink]="['/blog']"> Blog </a></li>
			<li class="d-block d-md-none"><a class="" [routerLink]="['/contact-us']"> Contact Us</a></li>
		</ul>
		<ul class="lg-Btns login_sign_up d-none d-md-inline-flex">
			<li><a [routerLink]="['/login']">Login / Sign up </a></li>
			<li><a [routerLink]="['/enquire']"><i aria-hidden="true" class="fa fa-calendar"></i> Book a Free Demo</a></li>
		</ul>

		<ul style="margin-left: 2% !important;" class="payNow navbar-nav align-items-lg-center ml-auto navbar-nav-hover align-items-lg-center">
			<li><a href="https://zohosecurepay.com/checkout/jn0k0m8-gpmy140nwt7qz/Your-One-Stop-Learning-Solution" target="_blank">Pay Now</a></li>  
		</ul>
	</div> -->
</nav>
<div class="nav_space"></div>
 