import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';

@Injectable()
export class TeacherFormService {
  constructor() {}

  getQuestions(): Observable<any[]> {
    const q = [
      {
        title: "How satisfied are you with your experience as a 1:1 teacher with Trusity?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "1"
      },
      {
        title: "How effective do you find Trusity's teaching platform in facilitating student learning and engagement during your sessions?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "2"
      },
      {
        title: "To what extent do you feel that your teaching strategies and methods, combined with Trusity's platform, are effective in helping your students make progress?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "3"
      },
      {
        title: "How satisfied are you with the support and resources provided by Trusity to enhance your teaching experience?",
        selected_answer: [
          {
            option: "0",
            value: "0",
            label: "Poor"
          },
          {
            option: "1",
            value: "1",
            label: ""
          },
          {
            option: "2",
            value: "2",
            label: ""
          },
          {
            option: "3",
            value: "3",
            label: ""
          },
          {
            option: "4",
            value: "4",
            label: ""
          },
          {
            option: "5",
            value: "5",
            label: "Excellent"
          }
        ],
        questionId: "4"
      },
    ];
    return of(q);
  }
}
