<nav id="navbar-main"
  class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-dark headroom headroom--top headroom--pinned"
  style="padding-top: 0px; padding-bottom: 0px; height: 70px;">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 container">
    <button class="navbar-toggler" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" [routerLink]="['']" style="margin-right: 3rem;">
      <img src="../../../assets/img/brand/Trusity_Logo_New.png">
    </a>
    <div class="navbar-collapse collapse" id="navbar_global" >
      <ul class="navbar-nav align-items-lg-center ml-lg-auto navbar-nav-hover align-items-lg-center">
        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link no-caret" id="profileDropdown" style="display: flex;" ngbDropdownToggle></a>
        </li>
        <li class="nav-item nav-logout d-none d-lg-block">
          <a (click)="loggedOut()" class="nav-link" href="">
            <i class="fa fa-power-off icon-size" aria-hidden="true"></i>
          </a>
        </li>
        <!-- <li>
          <select class="form-control" (change)="changeLanguage($event)" [(ngModel)]="activeLanguageMain">
            <option [value]="language" *ngFor="let language of languageList" >{{language}}</option>
          </select>
        </li> -->
        <li class="nav-item nav-settings d-none d-lg-block">
          <a class="nav-link">
            <i class="mdi mdi-format-line-spacing"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #changePassword class="modal-content-radius">
  <div class="modal-header">
    <h4 class="modal-title pull-left Helvetica Bold">Change Password</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group text-center">
      <form novalidate #f="ngForm">
        <div class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
          <input #pwd="ngModel" [pattern]="passwordPattern" class="form-control Helvetica-Light" id="oldpswd" type="password"
            placeholder="Old Password" [(ngModel)]="oldPassword" name="old-password">
          <br>
          <input #pwd="ngModel" [pattern]="passwordPattern" class="form-control Helvetica-Light" id="newpswd" type="password"
            placeholder="New Password" [(ngModel)]="newPassword" name="new-password">
          <br>
          <input #rpwd="ngModel" [pattern]="passwordPattern" class="form-control Helvetica-Light" id="reppswd"
            type="password" placeholder="Repeat Password" [(ngModel)]="repeatPassword" name="repeat-password">
          <div class="small text-danger p-t-5" *ngIf="f.invalid">{{passwordError}}</div>
          <button
            class="btn btn-primary change-password hovcolor Helvetica Bold btn-nikiblu col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-t-30"
            id="change-password" [disabled]="newPassword != repeatPassword" (click)="changePasswordClicked()">Change
            Password</button>
          <div class="small text-danger p-t-5" *ngIf="newPassword && repeatPassword && (newPassword != repeatPassword)">
            Password
            Mismatch!</div>
        </div>
      </form>
    </div>
  </div>
</ng-template>