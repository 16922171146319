<!-- <app-navbar-professional></app-navbar-professional> -->
<app-set-navbar></app-set-navbar>
<!-- <canvas class="popup flash" id="confetti-canvas"></canvas>
<jw-modal-confetti [@pop] class="flash popup-align center" style="text-align: center">
    <div>
        <h2 class="confetti-font bounce popup">It's our <b style="color: #F79333">BIRTHDAY</b>... <br> We are turning <b style="color: #F79333">THREE</b>!!</h2>
    </div>
    <canvas id ="confetti-canvas"></canvas>
</jw-modal-confetti> -->

<div>
    <a href="https://api.whatsapp.com/send?phone=971551411403&text=" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
    </a>
</div>

<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<!-- <a href="https://api.whatsapp.com/send?phone=971551411403&text=" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a> -->

<div class="c_ontainer" style="max-width:100%; padding:0px; margin-bottom: 50px; margin-top: 4%;">
    <div class="row">
        <div class="col-lg-12 top_slider_holder">
            <owl-carousel-o [options]="top_slider"> 
                <ng-template carouselSlide *ngFor="let slide of sliderBanner;">
	                <a href={{slide.link}} target="_blank" aria-label="Trusity"><img class="img-fluid cursor" src="{{slide.sliderImage}}" alt="Trusity Banner" /></a>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>



<!-- <section class="mobile_app" >
    <div class="col-sm-12 c_ontainer_app row" *ngIf="featureData">
        <div class="col-sm-6 app_background">
            <img class="img_app" src="../../assets/home-professional/app_image.png" alt="Trusity App">
        </div>
        <div class="col-sm-6 align_left">
            <h2 class="heading_app">Learning Simplified with Trusity</h2>
            <h3 class="brief_app">Download our app and enjoy free access to learning materials, assessments and quizzes.</h3>
            <div class="row app_sec_align" *ngIf="descData">
                <a href="https://play.google.com/store/apps/details?id=com.trusity" _target="blank" aria-label="Play Store" class="cursor app_icon"> <img src="../assets/home-latest/google_play_app.webp" alt="Play Store"> </a>
                <a href="https://apps.apple.com/ae/app/trusity/id1591877200"  target="_blank" aria-label="App Store" class="cursor app_icon"> <img src="../assets/home-latest/app_store.webp" alt="App Store"> </a>
            </div>
        </div>
    </div>
</section> -->

<section id="popular_course" class="course_align">
    <h2 class="main_title_journey">Popular Courses</h2>
        <div class="cards-list">
            <div class="card 1 cursor" (click)="getCoursePg(course.courseName)" *ngFor="let course of courseList">
                <div class="card_image cursor" > 
                    <img src={{course.courseImg}} alt="Courses Images"/> 
                </div>
                <div class="session-btn1">
                    <a aria-label="courses">Join Class</a>
                </div>
                <div (click)="getCoursePg(course.courseName)" class="card_title title-white cursoe">
                    <h3 class="course_title">{{course.courseName}}</h3>
                    <p class="col-sm-6 course_cont">{{course.brief}}</p>
                </div>
            </div>
        </div>
</section>

<div id="course_carousel" class="c_ontainer">
    <div class="course_main_title">Popular Courses</div>
    <div class="backimage">
        <div class="two-Landing p-0">
            <div class="col-12 p-0 courses-info-holder" >
                <owl-carousel-o [options]="courseOptions">
                    <ng-template carouselSlide *ngFor="let course of shortCourseList">
                        <div class="courses-info-box">
                            <a [routerLink]="['/enquire-learning']"></a>
                            <div class="">
                                <div>   
                                    <img class="mask_img" src={{course.courseImg}} />
                                    <h6 class="">{{course.courseName}}</h6>
                                    <p class="text-light" >{{course.brief}}</p>
                                    <div class="session-btn" (click)="getCoursePg(course.courseName)"><a aria-label="live-classes" class="">Join Class</a></div>
                                </div> 
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
<section>
    <div class="title_align">
        <h1 class="tag_hide">Summer Camps for Kids Dubai</h1>
        <h2 class="main_title_journey"> Start your Upskilling Journey Today! </h2>
    </div>
    <div class="c_ontainer">
        <div class="background">
            <img class="img_learning" src="../../assets/home-professional/JourneyProfessional1.png" />
        </div>
    </div>
</section>
<div class="c_ontainer" >
    <div class="course_main_title discover_head_align"> Discover Your Competitive Edge </div>
    <div class="backimage1">
        <div class="two-Landing p-0">
            <div class="col-12 p-0 courses-info-holder">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let title of discoverList">
                        <div class="courses-info-box">
                            <div class="">
                                <div>   
                                    <img class="mask_img" src={{title.img}} />
                                    <h6 class="" >{{title.name}}</h6>
                                    <p class="text-light">{{title.content}}</p>
                                    <!-- <div class="session-btn"><a [routerLink]="['/learning']" aria-label="live-classes">Learn More</a></div> -->
                                </div> 
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>

<section class="teachers_offred">
    <div class="container1">
        <div class="float-right" >
            <img src='../assets/home-latest/wave2.png' alt="Wave"/>
        </div> 
       <br>
        <div>
        <div class="title3"> Meet the Teachers </div>
        <h5 class="sub_title"></h5> 
        <br>
                    <div >
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let content of teacherList;">
                                    <div class="container3">
                                        <img class="mask_img1" src={{content.img}} alt="Teachers"/>
                                        <button class="btn1">
                                            <h6 class="teacher_name">{{content.teacherName}}</h6>
                                            <h6 class="teacher_brief">{{content.brief}}</h6>
                                            <p class="teacher_profile">{{content.description}}</p>
                                        </button>
                                    </div>               
                            </ng-template>
                        </owl-carousel-o>
                    </div>
        </div>
    </div>
</section>


<section  class="review_box">
    <div class="c_ontainer">
        <div class="main_title">Our Students Love Us</div>
        <ngb-carousel [options]="review_slider" [items]="images" [carouselClasses]="['owl-theme', 'sliding']"> 
            <ng-template ngbSlide *ngFor="let test of testimonials;">
                <div class="card courses-info-box">
                     <div id="review_image" alt="Testimonials">
                         <img class="float-left" src={{test.image}} />

                     </div>
                     <div class="session-btnTest">
                        <h4 >{{test.name}}</h4>
                   
                     </div>
                     <div class="card_title title-white">
                        <img class ="float-left top" src="../../assets/home-latest/quote_org.png" />
                        <p class="paragraph" >{{test.testimonial}}</p>  
                        <img class ="float-right bottom" src="../../assets/home-latest/quote_org.png" />
                    </div>  
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</section>

<div class="c_ontainer logo_slider slider_container">
    <h2 class="main_title">Corporates We Work With</h2>
    <div class="slider_container_owl">
        <button class="next" (click)="owlCar.prev()"><img src="../../assets/home-new/arrow_left.png" alt="Left Arrow" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar> 
            <ng-template carouselSlide *ngFor="let school of partnerList">
                <img src={{school.images}}  class="logo_img" style="height: auto !important" alt="School Logo"/>
            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" alt="Right Arrow"/></button>
    </div>
</div>

<div class="c_ontainer logo_slider slider_container">
    <h2 class="main_title">Accreditation</h2>
    <div class="slider_container_owl">
        <button class="next" (click)="owlCar.prev()"><img src="../../assets/home-new/arrow_left.png" alt="Left Arrow" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar> 
            <ng-template carouselSlide *ngFor="let organization of accreditationList">
                <img src={{organization.img}}  class="logo_img" alt="Organization Logo" />
            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" alt="Right Arrow"/></button>
    </div>
</div>

<div class="grid-container">
    <div class="grid-item">
        <img class="precent_img" src="../../assets/home-professional/Teacher-Quality.png">
    </div>
    <div class="grid-item">
        <img class="precent_img" src= "../../assets/home-professional/Referral.png">
    </div>
    <div class="grid-item">
        <img class="precent_img" src="../../assets/home-professional/Satisfaction_Rate.png">
    </div>
</div>



