import { Component, OnInit ,Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from "ngx-spinner";
import { HomeProfessionalService } from './home-professional.service';
import { Subscription, interval } from 'rxjs';
import { FooterNavbarSharedService } from '../shared/footer-navbar-shared/footer-navbar-shared.service';
// import { NewModalConfettiModule } from '../_modal-confetti';
// import * as confetti from 'canvas-confetti';
// import { ConfettiService } from '../services/confetti.service';
// import ConfettiGenerator from 'confetti-js';
// import { trigger, state, style, animate, transition } from '@angular/animations';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home-professional',
  templateUrl: './home-professional.component.html',
  styleUrls: ['./home-professional.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // animations: [
  //   trigger('pop', [
  //     state('void', style({
  //       transform: 'scale(0)'
  //     })),
  //     transition('void => *', [
  //       animate('300ms ease-out', style({
  //         transform: 'scale(1)'
  //       }))
  //     ])
  //   ])
  // ]
})
export class HomeProfessionalComponent implements OnInit {
  public courseName: any;
  slider: any;
  homeData = [];
  courseData: any;
  whyTrusityData: any;
  enrollData: any;
  workshopData: any;
  secThirteenData: any;
  featureData: any;
  videoSection = [];
  secEightData = [];
  // testimonials = [];
  secTenData = [];
  secOrganizationData = [];
  secElevenData = [];
  secTwelveData: any;
  videoSectionLeft : any;
  videoSectionRight : any;
  slideList: any;
  sliderList: any = [];
  courseResponse: any;
  featureResponse: any;
  descData: any;
  activeLanguage;
  actLanguage;
  nodata = false;
  languageList = [];
  private subscription: Subscription;
  public dateNow = new Date();
  public dDay = new Date('Feb 26 2022 10:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;
  images;
  // private confettiGenerator: ConfettiGenerator;


  sliderBanner: any = [
    // {
    //   sliderImage: "../../assets/banner/Back to school banners (1) (2).png",
    //   link: "https://backtoschool.trusity.com/"
    // },
    {
      sliderImage: "../../assets/banner/main banner.png",
      link: "enquire-learning"
    },
    {
      sliderImage: "../../assets/UN-banner.png",
      link: ""
    }
  ]

  courseList: any = [
    {
      courseImg: '../../assets/learning-courses- prot/tru.png',
      courseName: "truPreneurs",
      brief: "truPreneurs programme aims to provide youth with the ability to build knowledge and entrepreneurial skills. ", 
    },
    // {
    //   courseImg: '../../assets/learning-courses- prot/young.png',
    //   courseName: "Youngpreneurship",
    //   brief: "Develop skills and foundational knowledge such as risk-taking, business & financial planning,  problem-solving and entrepreneurship.", 
    // },
    {
      courseImg: "../../assets/learning-courses- prot/Finance.png",
      courseName: "Financial Literacy",
      brief: "Improve understanding of finances, the importance of savings, investments and money.",
    },
    {
      courseImg: '../../assets/learning-courses- prot/robo.png',
      courseName: "Robotics",
      brief: "Learn STEM concepts, that deals with making, programming and designing of robots.", 
    },
    {
        courseImg: '../../assets/liveClasses/Neet.jpg',
        courseName : "IIT JEE and NEET",
        brief: "Prepare for examinations such as JEE & NEET to attain admissions into prestigious universities.", 
      },
      {
        courseImg: '../../assets/liveClasses/GMAT_1.jpg',
        courseName: "GMAT",
        brief : "Prepare for examination such as GMAT to attain admissions into prestigious universities.", 
      },
  ];

  shortCourseList: any = [
    {
      courseImg: '../../assets/learning-courses- prot/tru.png',
      courseName: "truPreneurs",
      brief: "truPreneurs programme aims to provide youth with the ability to build knowledge and entrepreneurial skills. ", 
    },
    // {
    //   courseImg: '../../assets/learning-courses- prot/young.png',
    //   courseName: "Youngpreneurship",
    //   brief: "Develop skills and foundational knowledge such as risk-taking, business & financial planning,  problem-solving and entrepreneurship.", 
    // },
    {
      courseImg: "../../assets/learning-courses- prot/Finance.png",
      courseName: "Financial Literacy",
      brief: "Improve understanding of finances, the importance of savings, investments and money.",
    },
    {
      courseImg: '../../assets/learning-courses- prot/robo.png',
      courseName: "Robotics",
      brief: "Learn STEM concepts, that deals with making, programming and designing of robots.", 
    },
    {
        courseImg: '../../assets/liveClasses/Neet.jpg',
        courseName : "IIT JEE and NEET",
        brief: "Prepare for examinations such as JEE & NEET to attain admissions into prestigious universities.", 
      },
      {
        courseImg: '../../assets/liveClasses/GMAT_1.jpg',
        courseName: "GMAT",
        brief : "Prepare for examination such as GMAT to attain admissions into prestigious universities.", 
      }
  ];

  discoverList: any = [
    {
      img: "../../assets/home-professional/discover/lifelong_learner.png",
      name: "Lifelong Learning",
      content: "Keep up with the times and constantly upskill yourself with relevant professional skills.",
    },
    {
      img: "../../assets/home-professional/discover/career_growth.png",
      name: "Career Growth",
      content: "Grow in your career with the top in-demand skills of the business world.",
    },
    {
      img: "../../assets/home-professional/discover/teacher_quality.png",
      name: "Teachers Quality",
      content: "450+ handpicked tutors from around the world with extensive professional experience.",
    },
  ]
  public testimonials = [
    {
      "image": "../../assets/home-india/Mythili.png",
      "name": "Mythili",
      "testimonial": "I have been with Trusity over a year now and it has been a great experience. Have learnt so many things and I would trade it for nothing. I have learnt from coding to communication, create and present a pitch deck. It has been an amazing experience and it has given so many valuable insights to the real world, which I will definitely be using in the future.",
    }, 
    {
      "image": "../../assets/home-india/Alec.PNG",
      "name": "Alec",
      "testimonial": "Being a truPreneurs was an amazing experience. I got to learn how to innovate, make elevator pitches and so much more. I would never trade this for anything else in my life. Thank you Trusity for everything you have done for me.",
    }, 
    {
      "image": "../../assets/home-india/Testimonial Icon.png",
      "name": "Farhaan",
      "testimonial": "Trusity is one of the best learning platforms out there and it has the best teachers. Ms. Noor is my Arabic teacher. Initially I found learning Arabic really hard. However, since I joined Trusity, Arabic became easier. If I don’t understand something, my teacher explains it again. She makes it interesting through jokes and stories. That’s why I like my teacher and Trusity.",
    }, 
    {
      "image": "../../assets/home-india/Ayaan.jpg",
      "name": "Ayaan",
      "testimonial": "Through the TruPreneurs program, I learnt a lot. From coding, communication to business skills, to how to be an entrepreneur. I have learnt how I can make my idea into reality.",
    },
    {
      "image": "../../assets/home-india/Aarush.PNG",
      "name": "Aarush",
      "testimonial": "My journey with Trusity has been a great one. I started with Trusity over a year ago where I learnt how to innovate, communicate and how to start a business. It has been a great journey and I have learnt so many skills.",
    }, 
    {
      "image": "../../assets/home-india/Testimonial Icon.png",
      "name": "Myra",
      "testimonial": "Trusity and indeed, Mr. Taoufik has really been a life saver for us, Indian parents when it comes to a third language. My daughter has been learning French since the past few months and I must say it’s taken a lot of load off our minds to see her reaching at par with her classmates. Both, Aleena and Jitha, the coordinators have been extremely prompt with all help. The teacher is approachable and there is easy two-way communication. Thank you Trusity !",
    },
    {
      "image": "../../assets/home-india/Testimonial Icon.png",
      "name": "Ghanesh Kumar",
      "testimonial": "I have really enjoyed attending the classes organized by trusity. The teachers are very interactive, patient, and cool to hang out with. What's being taught is also super interesting. I do not regret taking this course as it has taught a whole plethora of things.",
    },
    {
      "image": "../../assets/home-india/Testimonial Icon.png",
      "name": "Adrian",
      "testimonial": "The learning experience was always fun and challenging and always interactive. The teacher would always find a way to make me understand and enjoy the lessons at the same time",
    },
    {
      "image": "../../assets/home-india/Testimonial Icon.png",
      "name": "Sufyaan",
      "testimonial": "Discovering Trusity this summer was one of the best things that happened to me. I joined their 6-week Future Development course, focusing on communication, workplace etiquette, and interviews. The teacher's guidance and assessments boosted my skills significantly. Trusity also arranged a 2-week internship in Robotics and AI, providing valuable real-world experience. I'm grateful for this opportunity and recommend the youth of today to get involved in such courses and internships through Trusity as they help you develop into a high-value person both professionally and socially.",
    }
  ]
  teacherList: any = [
    // {
    //   img: "../../assets/home-professional/teachers/keinan.png",
    //   teacherName: "Keinan Milliard",
    //   brief: "Business Studies, English & Economics Teacher",
    // },
    {
      img: "../assets/Teachers Profile/Dalia Trupreneurs.jpg",
      teacherName: "Dalia",
      brief: "Life Skill Teacher",
      description:"Dalia, a creative and collaborative coach, engineer, and educator, combines expertise in technology and education to nurture future generations. Integrated learning tools and methods specialist."
    },
    {
      img: "../assets/Teachers Profile/German Svitlana.jpg",
      teacherName: "Svitlana",
      brief: "Language Teacher",
      description:"Svitlana is a versatile English and German teacher with TEFL/TESOL certification and extensive global experience. Fluent in five languages and skilled in communication."
    }, 
    {
      img: "../assets/Teachers Profile/Najwa - Portuguese.jpg",
      teacherName: "Najwa",
      brief: "Language Teacher",
      description:"Najwa, an adept educator, seamlessly integrates technology and pedagogy to foster future leaders. Experienced in crafting innovative learning tools and strategies."
    }, 
    {
      img: "../assets/Teachers Profile/Arjun Raghuvir 2.jpg",
      teacherName: "Arjun",
      brief: "Life Skill Teacher",
      description:"Arjun, a Computer Science Engineer with web development skills, aspires to engage students in innovative, educational experiences, fostering a passion for learning through personalized teaching."
    }, 
    {
      img: "../assets/Teachers Profile/Abhishek Kamble Pic.jpg",
      teacherName: "Abhishek",
      brief: "Life Skill Teacher",
      description:"Educator & Faculty for Business Studies for CAIE & IBDP empaneled with a Cambridge International. MBA in Marketing, MA in English. Melding industry insight with effective pedagogy for 5+ years."
    }, 
    {
      img: "../assets/Teachers Profile/Nourhan Astronomy Arabic pic.jpg",
      teacherName: "Nourhan",
      brief: "Language Teacher",
      description:"Nourhan, a versatile educator skilled in Astronomy, Biology, and Arabic instruction for children. She fosters curiosity, manages classrooms effectively, and tailors lessons to individual needs."
    }, 
    {
      img: "../assets/Teachers Profile/William Spanish.jpg",
      teacherName: "William",
      brief: "Language Teacher",
      description:"William is an experienced educator fostering child-centered curriculum and creativity. Proficient in TEFL, classroom management, and diverse populations."
    }, 
    {
      img: "../assets/Teachers Profile//Reeda.jpg",
      teacherName: "Reeda",
      brief: "Language Teacher",
      description:"Reeda, a versatile Arabic tutor, excels in managing classroom behavior through empathy and adaptive teaching. Tailors’ dynamic lessons for students' growth."
    }, 
    {
      img: "../assets/Teachers Profile/Omar Lars Pic 1.jpg",
      teacherName: "Omar",
      brief: "Language Teacher",
      description:"Omar, a seasoned educator, adeptly instructs learners from A1 to C2 levels worldwide. Specialized in TOEFL, SAT, IELTS, and corporate training. Award-winner with diverse expertise."
    }, 

  ]

partnerList: any = [
  {
    images: "../../assets/home-professional/Banks/enbd.png",
  },
  {
    images: "../../assets/home-professional/Banks/Mashreq.png",
  },
  {
    images: "../../assets/home-professional/Banks/rak.png",
  },
]

accreditationList: any = [
  {
    img: "../../assets/home-professional/STEAM_ORG_Logo.png"
  }, 
  {
    img: "../../assets/home-professional/inventory_KHDA_Permitted.png"
  }
]

percentageList: any = [
  {
    img: "../../assets/home-professional/Teacher_Quality.png",
  },
  {
    img: "../../assets/home-professional/Referral.png",
  },
  {
    img: "../../assets/home-professional/Satisfaction_Rate.png",
  },
]

  top_slider: OwlOptions = { 
    loop: true,
    dots: true,
    navSpeed: 200, 
    items:1,
    nav: true,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    slideTransition: 'linear',
    responsive: {
      0: {
        items:1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1
      }
    },
  }

  courseOptions: OwlOptions = {
    loop: true,
    dots: true,
    navSpeed: 200,
    margin:30,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    slideTransition: 'linear',

    // center:true,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items:1,
        stagePadding: 25,
        margin:10,
      },
      400: {
        items: 1,
        stagePadding: 25,
        margin:10,
      },
      740: {
        items: 1,
        stagePadding: 20,
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:30,
    // center:true,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items:1,
        stagePadding: 25,
        margin:10,
      },
      400: {
        items: 1,
        stagePadding: 25,
        margin:10,
      },
      740: {
        items: 2,
        stagePadding: 20,
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
  
  review_slider: OwlOptions = {
    loop: true,
    dots: true,
    // navSpeed: 200, 
    items:1,
    margin: 30,
    // autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items:1,
      },
      400: {
        items: 1,
      },
      770: {
        items: 2,
      },
      992: {
        items: 1,
      }
    },
  }

  logoOption: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:0, 
    items: 5,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items: 2,
        stagePadding: 25,
        margin:10,
      }, 
      400: {
        items: 2,
       stagePadding: 25,
        margin:10,
      },
      740: {
        items: 3,
        stagePadding: 25,
        margin:10,
      },
      940: {
        items: 5
      }
    },
    nav: true,
    
  }

  percentageOption: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:0, 
    items: 5,
    
    responsive: {
      0: {
        items: 3,
        stagePadding: 25,
        margin:10,
      }, 
      400: {
        items: 3,
       stagePadding: 25,
        margin:10,
      },
      740: {
        items: 3,
        stagePadding: 25,
        margin:10,
      },
      940: {
        items: 5
      }
    },
    nav: true,
    
  }

constructor( private homePageService: HomeProfessionalService, private spinner: NgxSpinnerService, private router: Router, private fooService: FooterNavbarSharedService, private meta: Meta, private titleService: Title) {
    this.fooService.currentDataFetch.subscribe(res => {
    if(res){
      this.actLanguage = res;
      this.getHomepage();
    }
  })
}

ngOnInit(): void {
  this.titleService.setTitle('Summer Camps for Kids Dubai | Trusity Your One Stop Learning Space');
  this.meta.addTags([
  {name: 'keywords', content: 'summer camps for kids dubai, coding for kids, robotics for kids, artificial intelligence course, coding classes for kids dubai, arts and crafts dubai, Robotics Classes for Kids in Dubai, app development for kids in dubai, arabic language for kids dubai, online coding classes for kids in dubai, Artificial Intelligence Courses for Kids in Dubai, writing courses for kids in dubai, abacus classes for kids Dubai, English language classes for kids in Dubai, entrepreneurship course for kids, french classes for kids, space learning for kids in dubai, mental mathematics for kids, leadership programs for kids, public speaking course for kids'},
  {name: 'description', content: "Looking for Summer Camps for Kids in Dubai? Trusity offers top-notch programs, fun-filled activities, and a nurturing atmosphere. secure your spot now!"},
  {name: 'robots', content: 'index, follow'}
  ]);

  sessionStorage.clear()
  this.getHomepage();
}

getCoursePg(courseName){
    this.courseName = courseName.split(/[ ]+/);
    this.courseName = this.courseName.join("-");
    this.router.navigate([`/courses/${this.courseName.toLowerCase()}`]);
}

truPreneursClick() {
  // window.open("https://forms.zohopublic.com/trusity/form/truIntel/formperma/eE6mqqcIVWy-BPWnJY-HRstO5L49zJzRJqR0PyQPDqE");
  this.router.navigate(['/enquire-learning']);
  window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
        "event": "click_professional_enquiry",
        });
  window['dataLayer'] = window['dataLayer'] || {};
}

truPreneursRedirect(){
  this.router.navigate(['/live-classes']);
}

resetLanguage(){
  this.slider  = [];
  console.log(this.slider);
  this.courseData = null
  this.courseResponse  = null;
  this.sliderList  = null;
  this.enrollData  = null;
  this.whyTrusityData  = null;
  this.workshopData  = null;
  this.secThirteenData = null;
  this.featureData  = null;
  this.featureResponse  = null;
  this.descData  = null;
  this.videoSection  = null;
  this.videoSectionLeft  = null;
  this.videoSectionRight  = null;
  this.secEightData  = null;
  this.testimonials  = null;
  this.secTenData  = null;
  this.secOrganizationData = null;
  this.secElevenData  = null;
  this.secTwelveData = null;
}
  
getHomepage() {
  this.spinner.show();
  if(sessionStorage.getItem('activeLanguage')){
    this.actLanguage = sessionStorage.getItem('activeLanguage');
  } else{
    sessionStorage.setItem('activeLanguage', 'English');
    this.actLanguage = 'English'
  }
  let defaultLanguage;
  const payload = {
    getDataByLanguage: this.actLanguage
  };
  this.homePageService.getHomepage(payload).subscribe(
    (res) => {
      this.homeData = res.data;
      defaultLanguage = res.language;
      if(res.data != null){
        this.spinner.hide();
        if(this.nodata == true){
          this.nodata = false;
        }
        for (let i = 0; i < this.homeData.length; i++) {
          if (this.homeData[i].sectionName == "sectionOne") {
            this.slider = this.homeData[i][defaultLanguage].content.images;
          }
          if (this.homeData[i].sectionName == "sectionTwo") {
            this.courseData = this.homeData[i][defaultLanguage].content;
            this.courseResponse = this.homeData[i][defaultLanguage].content.catMain;
            if (this.courseResponse.length > 0){
              this.sliderList = this.courseResponse[0].slideList;
            }
          }
          if (this.homeData[i].sectionName == "sectionThree") {
            this.enrollData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionFour") {
            this.whyTrusityData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionFive") {
            this.workshopData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionThirteen") {
            this.secThirteenData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionSix") {
            this.featureData = this.homeData[i][defaultLanguage].content;
            this.featureResponse = this.homeData[i][defaultLanguage].content.catLeft;
            if (this.featureResponse.length > 0){
              this.descData = this.featureResponse[0].circleDesc;
            }
          }
          if (this.homeData[i].sectionName == "sectionSeven") {
            // console.log(this.homeData[i][defaultLanguage].content)
            this.videoSection = this.homeData[i][defaultLanguage].content;
            this.videoSectionLeft = this.homeData[i][defaultLanguage].content.catLeft[0];
            this.videoSectionRight = this.homeData[i][defaultLanguage].content.catRight;
          }
          if (this.homeData[i].sectionName == "sectionEight") {
            this.secEightData = this.homeData[i][defaultLanguage].content;
          }
          // if (this.homeData[i].sectionName == "sectionNine") {
          //   this.testimonials = this.homeData[i][defaultLanguage].content;
          // }
          if (this.homeData[i].sectionName == "sectionTen") {
            this.secTenData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionOrganization") {
            this.secOrganizationData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionEleven") {
            this.secElevenData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionTwelve") {
            this.secTwelveData = this.homeData[i][defaultLanguage].content;
          }
        }
      }
      else{
        this.resetLanguage();
        this.nodata = true;
        this.slider = null;
        this.spinner.hide();
      }
    },
    (error) => {
      console.log(error);
      this.spinner.hide();
    }
  );
}

}
