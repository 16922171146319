import { Component, OnInit, HostListener, Input, Output, EventEmitter} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { UserLoginService } from "../../services/user.service";
import { LoggedInUserInfo } from '../../model/LoggedInUserInfo';
import { HomePageService } from '../../home-all/homepage/homepage.service';
import { NgxSpinnerService } from "ngx-spinner";
import { HomepageComponent } from "../../home-all/homepage/homepage.component";
import { FooterComponent } from '../footer/footer.component';
import { FooterNavbarSharedService } from '../footer-navbar-shared/footer-navbar-shared.service';
import { StripTagsPipe } from '../../home-all/homepage/removeHtmlPipe';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: []
})
export class NavbarComponent implements OnInit {
    homeData = [];
    menuList: any;
    headerData: any;
    scrolled: boolean = false;
    activeLanguage;
    actLanguage;
    languageList = [];
    nodata = false;
    activatedLang;
    activeLanguageMain: string = 'English';
    @HostListener("window:scroll", [])
    onWindowScroll() { this.scrolled = window.scrollY > 0; }
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    filter: any;
    userName;
    userInfo: LoggedInUserInfo;

    constructor(public location: Location, private router: Router,
        private userService: UserLoginService, private homePageService: HomePageService, private spinner: NgxSpinnerService,
        private homepageComponent: HomepageComponent, private footerComponent: FooterComponent, private fooService: FooterNavbarSharedService, private stripeTags: StripTagsPipe) 
        { }

    ngOnInit() {
      if(sessionStorage.getItem('activeLanguage')){
        this.activatedLang =  sessionStorage.getItem('activeLanguage');
       }
        this.getLangList();
        this.userInfo = this.userService.getUserInfo();
        if(this.userInfo != null){
            this.userName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
        }
        
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });



    }

   

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === 'home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === 'documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
    
    showFooter() {
      return this.router.url === '/dashboard';
    }

    hideFooter() {
        return this.router.url === '/avialable-course';
    }

    hideFooterSchedule() {
        return this.router.url === '/scheduler';
    }

    hideFootermyChildCalender() {
        return this.router.url === '/children-calender';
    }
    hideInvoices() {
        return this.router.url === '/invoices';
    }

    hideEditAccount() {
        return this.router.url === '/edit-account';
    }

    hideAddChild() {
        return this.router.url === '/add-child';
    }

    hideFooterChangePwd() {
        return this.router.url === '/change-password';
    }

    sideoc() {
        if(document.getElementById("side-bar").style.display == "table"){
            document.getElementById("side-bar").style.display = "none";
            document.getElementById("show").style.visibility = "hidden";
            document.getElementById("show2").style.visibility = "visible";
            // document.getElementById("profile").style.display = "block";
        }
        else
        {   
            document.getElementById("side-bar").style.display = "table";
            document.getElementById("show").style.visibility = "visible";
            document.getElementById("show2").style.visibility = "hidden";
            // document.getElementById("profile").style.display = "none";
            }
    }

    side_enl(eid) {
        if(document.getElementById(eid).style.display == "table"){
            document.getElementById(eid).style.display = "none"
        }
        else{
            document.getElementById(eid).style.display = "table"
        }
    }

    // new
    getLangList() {
        this.spinner.show();
        const payload = {
          getaLLanguages: '1',
        };
        this.homePageService.getLangList(payload).subscribe(
          (res) => {
            this.languageList= res.data[0].AllLanguage;
            this.activeLanguageMain = sessionStorage.getItem('activeLanguage');
            this.getHomepage();
            this.spinner.hide();

          },
          (error) => {
            console.log(error);
            this.spinner.hide();
          }
        );
      }

      changeLanguage(event) {
        this.spinner.show();
        let actLanguage = event.target.value;
        this.activeLanguageMain = event.target.value;
        sessionStorage.setItem('activeLanguage', actLanguage);
        const payload = {
            getDataByLanguage: actLanguage
          };
        this.homePageService.getHomepage(payload).subscribe(
          (res) => {
              if(res.data != null){
                this.languageList= res.data[0].AllLanguage;
                this.homepageComponent.getHomepage();
                // this.footerComponent.getHomepage();
                this.fooService.emitData(actLanguage);
              }else{
                this.homepageComponent.getHomepage();
                // this.footerComponent.getHomepage();
              }
              this.getLangList();
              this.activatedLang =  sessionStorage.getItem('activeLanguage');
          },
          (error) => {
            console.log(error);
            this.spinner.hide();
          }
        );
      }

    routeToLink(url){
      var item = this.stripeTags.transform(url.menu);
      console.log("Menu", item);
      var link = url.url;
      // console.log("Url", link);
      var newUrl = this.stripeTags.transform(link)
      // console.log(newUrl);
      if (newUrl.includes('https' || 'http')) {
        // window.location.href = newUrl
        window.open(newUrl, "_blank");
        if (item == 'Book an Intro Session'){
            // console.log("Intro Selected")
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
          "event": "book_session",
          });
          window['dataLayer'] = window['dataLayer'] || {};
        }
      } 
      else{
        this.router.navigate([newUrl])
        if (item == 'Courses'){
          // console.log("Course Selected")
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
          "event": "courses",
          });
          window['dataLayer'] = window['dataLayer'] || {};
        }
        if (item === 'Summer Classes'){
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
          "event": "Summer Classes",
          });
          window['dataLayer'] = window['dataLayer'] || {};
        }
          
      }
    
    }
      getHomepage() {
        this.spinner.show();
        if(sessionStorage.getItem('activeLanguage')){
            this.actLanguage = sessionStorage.getItem('activeLanguage')
        } else{
            this.actLanguage = 'English'
        }
        let defaultLanguage;
        const payload = {
            getDataByLanguage: this.actLanguage
        };
        console.log(payload);
        this.homePageService.getHomepage(payload).subscribe(
          (res) => {
            this.homeData = res.data;
            defaultLanguage = res.language;
            for (let i = 0; i < this.homeData.length; i++) {
              if (this.homeData[i].sectionName == "sectionHeader") {
                this.headerData = this.homeData[i][defaultLanguage].content;
                this.menuList = this.homeData[i][defaultLanguage].content.menuItems;
                console.log("MenuList", this.menuList);
              }
            }
            
            this.spinner.hide();
          },
          (error) => {
            console.log(error);
            this.spinner.hide();
          }
        );
      }
}
