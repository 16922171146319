<nav class="main-menu font" id="side-barsb" >

    <!-- <div class="">
        <a [routerLink]="" href="" class="">
          <img src="../../assets/dashboard/img_avatar.png" class="image">
          </a>
        </div> -->
        
    <!-- <div class="col-sm-6">
        <div class="studentDetails">

            <div class="list-wrap">
                <div class="list-box">
                    <div class="">
                        <div class="name">
                            MA
                        </div>
                    </div>
                </div>
            </div>

               <div class="">
          
           <div class="">
           <h5 class="student_info" style="text-align: center;"> {{userName}} </h5>
        </div>
        <div class="">
            <h5 class="student_info" style="text-align: center;"> {{emailId}} </h5>
         </div>
         <div class="">
         </div>
        </div>
       </div>
    </div> -->






        <ul>
            <li [ngClass]="{'active' : dashboardSelected}" (click)="selectDashboard()">
                <a [routerLink]="['/dashboard']">
                    <i class="fa fa-home fa-2x"></i>
                    <span class="nav-text">
                        Dashboard
                    </span>
                </a>
            </li>

            <li [ngClass]="{'active' : calendarSelected}" (click)="selectCalendar()" (change)="calendarSelected == true">
                <a [routerLink]="['/scheduler']">
                    <i class="fa fa-calendar"></i>
                    <span class="nav-text">
                       Calendar
                    </span>
                </a> 
            </li>

            <li *ngIf="this.userInfo.role == 'Teacher'" [ngClass]="{'active' : studentSelected}" (click)="selectStudent()" (change)="studentSelected == true">
                <a [routerLink]="['/students']">
                     <i class="fa fa-users"></i>
                     <span class="nav-text">
                        Students
                     </span>
                 </a>
            </li>

            <li *ngIf="this.userInfo.role == 'Student' || this.userInfo.role == 'Parent'" [ngClass]="{'active' : teacherSelected}" (click)="selectTeacher()" (change)="teacherSelected == true">
                <a [routerLink]="['/teachers']">
                     <i class="fa fa-users"></i>
                     <span class="nav-text">
                        Teachers
                     </span>
                 </a>
            </li>

             <!-- <li *ngIf="this.userInfo.role == 'Student' || this.userInfo.role == 'Teacher'">
                <a href="https://quiz.trusity.com/home" target="_blank">
                     <i class="fa fa-pencil-square-o fa-2x"></i>
                     <span class="nav-text">
                        Quiz 
                     </span>
                 </a>
            </li> -->

            <!-- <li *ngIf="this.userInfo.role == 'Student' || this.userInfo.role == 'Teacher'">
                <a [routerLink]="['/q&a']">
                     <i class="fa fa-tasks"></i>
                     <span class="nav-text">
                        Assessments
                     </span>
                 </a>
            </li> -->

            <!-- <li>
                <a [routerLink]="['/notes']">
                     <i class="fa fa-sticky-note-o"></i>
                     <span class="nav-text">
                        Notes
                     </span>
                 </a>
            </li> -->

            <!-- <li>
                <a href="https://resources.trusity.com" target="_blank">
                     <i class="fa fa-folder"></i>
                     <span class="nav-text" style="margin-top: 15%;">
                        Resources
                     </span>
                </a>
            </li> -->

            <!-- <li>
                <a [routerLink]="['/dashboard']">
                    <i class="fa fa-check-circle-o"></i>
                    <span class="nav-text">
                        Invoice
                    </span>
                </a>
            </li> -->

            <!-- <li *ngIf="this.userInfo.role == 'Student'">
                <a [routerLink]="['/games']" href="">
                     <i class="fa fa-gamepad fa-2x"></i>
                     <span class="nav-text">
                        Games
                     </span>
                 </a>
            </li> -->

            <li *ngIf="this.userInfo.role == 'Parent'">
                <a [routerLink]="['/add-child']" href="">
                   <i class="fa fa-users fa-2x"></i>
                    <span class="nav-text">
                        My Children
                    </span>
                </a>                  
            </li>
             
            <li *ngIf="this.userInfo.role == 'Parent'">
                 <a [routerLink]="['/add-child']" href="">
                    <i class="fa fa-map-marker fa-2x"></i>
                    <span class="nav-text">
                        Add Children
                    </span>
                  </a>
            </li>

            <!-- <li *ngIf="this.userInfo.role == 'Teacher'">
                <a [routerLink]="['/working-hours']">
                        <i class="fa fa-briefcase fa-2x"></i>
                        <span class="nav-text">
                        Working Hours
                        </span>
                    </a>
            </li> -->

            <li *ngIf="this.userInfo.role == 'Teacher'">
                <a href="https://accounts.trusity.com/portal/trusity/login">
                    <i class="fa fa-credit-card fa-2x"></i>
                    <span class="nav-text">
                    Teacher Payout
                    </span>
                </a>
            </li>
            
            <!-- <li *ngIf="this.userInfo.role == 'Teacher'">
                <a [routerLink]="['/dashboard']">
                     <i class="fa fa-bookmark"></i>
                     <span class="nav-text">
                        Timesheet
                     </span>
                 </a>
            </li> -->

            <li>
                <a [routerLink]="['/change-password']" href="">
                    <i class="fa fa-info fa-2x"></i>
                    <span class="nav-text">
                        Change Password
                    </span>
                </a>
            </li>

            <li  [ngClass]="{'active' : accountSelected}" (click)="selectAccount()" (change)="accountSelected == true">
                <a [routerLink]="['/edit-account']" href="">
                    <i class="fa fa-pencil fa-2x"></i>
                    <span class="nav-text">
                        Edit Account Details
                    </span>
                </a>
            </li>
            
            <li>
                <a *ngIf="this.userInfo.role == 'Student' || this.userInfo.role == 'Parent'" href="https://zohosecurepay.com/checkout/he5157j-3kl2yohaqditb/Your-One-Stop-Learning-Solutio" target="_blank">
                     <i class="fa fa-folder"></i>
                     <span class="nav-text">
                        Payment
                     </span>
                </a>
            </li>

            <!-- <li>
                <a [routerLink]="['/edit-account']" href="">
                     <i class="fa fa-cogs"></i>
                     <span class="nav-text">
                        Settings
                     </span>
                 </a>
            </li> -->

             <!-- <li>
                <a>
                    <i id="showsb" class="fa fa-th-list fa-2x" (click)="sidebaroc()" ></i>
                    <span id="show2sb"class="hide-show fa fa-th-list fa-2x" (click)="sidebaroc()"></span>
                </a>
             </li>     -->
           
        </ul>


            <!-- <div class="mobileApp backgroundPicture">
              <h5 class="app">Download our <br>Mobile App</h5>
                <div class="icons">
                    <a href=""><i class="fa fa-apple icon1"></i></a>
                    <a><i class="fa fa-android icon1"></i></a>
                </div>
            </div> -->
    </nav>
