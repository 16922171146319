import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../services/constants';
import { DataService } from '../../services/data.service';
import { UrlConstants } from '../../services/url.constants';
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import * as _ from 'lodash';
import { EnquiryInfo } from '../../model/enquiry-info';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FooterNavbarSharedService } from '../../shared/footer-navbar-shared/footer-navbar-shared.service';

@Component({
  selector: 'app-enquiry-professional',
  templateUrl: './enquiry-professional.component.html',
  styleUrls: ['./enquiry-professional.component.scss']
})
export class EnquiryProfessionalComponent implements OnInit {
  activeLanguage;
  actLanguage;

  constructor(
    private urlConstants: UrlConstants,
    public constants: Constants,
    private dataservice: DataService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    calendar: NgbCalendar,
    private router: Router,
    private fooService: FooterNavbarSharedService

  ) {
    this.fooService.currentDataFetch.subscribe(res => {
      if(res){
          this.actLanguage = res;
          this.getContent();
      }
   })
   }

  ngOnInit() {
    sessionStorage.clear();
    this.getContent();
  }

  getContent() {
    // this.spinner.show();
    if(sessionStorage.getItem('activeLanguage')){
        this.actLanguage = sessionStorage.getItem('activeLanguage');
    }
    if (this.actLanguage == "Arabic"){
        console.log("ARABIC TAG");
        this.router.navigate(['/ar/enquire']);
    }else if (this.actLanguage == "English"){
        console.log("ENG TAG");
        this.router.navigate(['/enquire']);
    }
}

}