import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { UserLoginService } from "../../services/user.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { SpinnerService } from "../../services/spinner.service";
import { LoggedInUserInfo } from '../../model/LoggedInUserInfo';

@Component({
    selector: 'app-navbar-enquire',
    templateUrl: './navbar-enquire.component.html',
    styleUrls: ['./navbar-enquire.component.scss'],
    providers: [NgbDropdownConfig]
})
export class NavbarEnquireComponent implements OnInit {
    @ViewChild("changePassword") changePasswordPopup: ElementRef;
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    filter: any;
    userName;
    profileName: any;
    public modalRef: BsModalRef;
    public passwordEnteredObservable: Observable<any>;
    public changePasswordClicked: Function;
    public newPassword: string = "";
    public repeatPassword: string = "";
    userInfo: LoggedInUserInfo;

    constructor(public location: Location, private router: Router,
        private userService: UserLoginService,
        private modalService: BsModalService,
        private spinner: SpinnerService,
    ) {
    }

    ngOnInit() {
    }


    // showFooter() {
    //     return this.router.url === '/enquire-now';
    // }

    // hideFooter() {
    //     return this.router.url === '/enquire-now';
    // }

}
