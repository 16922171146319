import { Injectable } from "@angular/core";
// import "rxjs/add/operator/map";
import { map } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from "@angular/common/http";
import { environment } from "./../../environments/environment"; //./environment-variables/environment-variables.module.ts";
import * as _ from "lodash";
@Injectable()
export class UrlConstants {

    //entry point for create,delete ,associate,dissociate and serach data
    //To create students/Register Students
    // public createUsers = "createUsers/{userType}/{accId}/{resend}";
    public teacherApplication = 'teacherApplication/{userType}';
    public createUsers = 'createUsers/{resend}';
    public createQuiz = 'createQuiz';
    public getCourse = 'getCourse';
    public getQuiz = 'getQuiz';
    public getFaq = 'getFaq';

    //get Home page Content
    
    public getimages='getBannerImages';
 
    // contact us screen
    public sendMessage = 'message';
    public getAvailabilityInfo = 'getTeacherSchedule';

    public create = 'create/{object}';
    public get = 'get/{object}/{id}';
    public update = 'update/{object}/{id}';
    public delete = 'delete/{object}/{id}';

    public createEvent = 'createEvent/{accId}';
    public updateEvent = 'updateEvent/{id}/{accId}';
    public listEvents = 'listEvents/{state}/{accId}';
    public cancelEvent = 'cancelEvent/{id}/{accId}';
    public getEventInfo = 'getEvent/{id}/{accId}';
    public getGuestsList = 'getGuestsList/{eventid}/{status}';
    public setAdminResponse = 'setAdminRespond';
    public getpresignurl = 'getpresignurl';
    public createCsvrequest = 'createCsvrequest';
    public getCsv = 'getCsv/{eid}';
    public updateCsvrequest = 'updateCsvrequest/{eid}/{time}';
    public updateTnc = 'updateTnc';
    public getCourseInfo = 'getCourseInfo';
    public getArCourseInfo = 'getArCourseInfo';
    public backupLink = 'studentBackupLink';
    public getScheduledClassByUser = 'getScheduledClassByUser';
    public createOrJoinClass = 'createOrJoinClass/{scheduledId}/{timeStamp}';
    public teacherBackupLink = 'teacherBackupLink';
    // non authenticated
    public getNewTokens = "newTokens";
    public deleteTier = "deleteTier/{eventId}/{tierId}";
    public checkEventNotLive = "checkEventNotLive/{id}";
    public getDashboard = "getdashboard/{id}/{accId}";
    public sendComplemetaryTickets = "postcomps/{accId}";
    public getCompsList = "getCompsList/{eventid}";
    public createAdmin = "createUser/{userType}/{accId}/{resend}";
    public updateAdmin = "updateUser/{userType}/{accId}";
    public getAllAdmins = "getAllAdminAccounts";
    public getUser = "getUser/{userType}/{accId}/{kEId}";
    public getAllKioskUsers = "getkiosklist/{accId}";
    public createCode = "createCode";
    public updateCode = "updateCode/{eid}/{cid}";
    public getCodeList = "getcodelist/{eid}";
    public accountSuspended = "accountSuspended/{eid}/{suspended}/{userType}";
    public getUserInfo = "getUserInfo";
    public userTypeStudent = "Student";
    public userTypeTeacher = "Teacher";
    public studentEnquiry = "studentEnquiry";
    public webinarSession = "webinarSession";
    public campSession = "campSession";
    public getSessionInfo = "scheduledSessions";
    public getStudentDetails = "getStudentDetails";
    public getEnrolledCourseInfo = "enrolledCourseCount";
    public submitTestWeb = "submitTestWeb";
    public detailedProficiency = "detailedProficiency";
    public availableSlots = "availableSlots";
    public bookSlot = "bookSlot";
    public verifySlotToken = "verifySlotToken";
    public verifyDemoToken = "verifyDemoToken";
    public teacherDemoTimings = "teacherDemoTimings";
    public rescheduleSlot = "rescheduleSlot";
    public courseBookDemo = "courseBookDemo";
    public generateSpaceUrl = "generateSpaceUrl";
    public calculateCompleteSession = "calculateCompleteSession";
    public mobileCourseListing = "mobileCourseListing";
    public getCourseTeachers = "getCourseTeachers";
    public getChildInfo = "getChildInfo";
    public getStudentInfo = "getStudentInfo";
    public submitForm = "submitForm";

    constructor() { }

    public getUri(uri: string, params?: Array<UrlParams>): string {
        let newUri = uri;

        if (params) {
            // Sanitize the URL
            // uriSet[0] contains the URL without params
            // uriSet[1] contins only the querystring
            /*let uriSet = _.chain(newUri)
                .split("?")
                .value();

            // Eliminate the params that are not sent by the params Array
            // from the querystring i.e. uriSet[1]

            // List of queryParams from the original String
            let queryParams = _.chain(uriSet[1])
                .split("&")
                .map(_.ary(_.partial(_.split, _, "="), 1))
                .fromPairs().
                value();

            // choose the params that are part of params (incoming)
            let subSetParams = _.invert(_.pick(_.invert(queryParams),_.map(params,"param")));

            // Recrate the URI
            newUri = uriSet[0] + "?";
            _.forEach(subSetParams, function (value, key) {
                newUri = newUri + key + "=" + value + "&";
            })*/

            // Select the queryParams that matches the incoming set from params
            for (let param of params) {
                newUri = newUri.replace(param.param, param.value);
            }
            console.log("newwww = ", newUri);
        }

        return environment.apiURL + newUri;
        // return ("http://127.0.0.1:3000/") + newUri;
        // return environment.apiURL;
    }
}

export class UrlParams {
    public param: string;
    public value: string;
}