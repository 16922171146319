import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasItemInArrayString, HasObjInArrayPipe, GetItemCountFromArray, HasTimeInDayOfWeekCustom, HasVacentTimings, ConvertToArray } from '../array-pipe/array-filter.pipe'


@NgModule({
  declarations: [HasItemInArrayString,
    HasObjInArrayPipe,
    GetItemCountFromArray,
    HasTimeInDayOfWeekCustom,
    HasVacentTimings,
    ConvertToArray
  ],
  imports: [
    CommonModule
  ],
  exports: [HasItemInArrayString,
    HasObjInArrayPipe,
    GetItemCountFromArray,
    HasTimeInDayOfWeekCustom,
    HasVacentTimings,
    ConvertToArray
  ]
})
export class ArrayPipeModule { }
