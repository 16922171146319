import { Component, TemplateRef, OnInit, NgModule, ViewChild, OnDestroy, ElementRef, Inject } from "@angular/core";
import { UserLoginService } from "../services/user.service";
import { Observable } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../environments/environment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [UserLoginService]
})
export class ForgotPasswordComponent implements OnInit {
  focus;
  focus1;
  username: any;
  modalRef: BsModalRef;
  isModalActive: boolean;
  @ViewChild("changePassword") changePasswordPopup: ElementRef;
  passwordObservable: Observable<any>;
  otpObservable: Observable<any>;
  changePasswordClicked: Function;
  repeatPassword: string = "";
  newPassword: string = "";
  otp: string = "";
  passwordPattern: any;
  passwordError: any = "Password should contain";
  public appVersion;
  errorUsername: boolean = false;

  constructor(
    private modalService: BsModalService,
    private userLoginService: UserLoginService,
    private router: Router,
  ) { this.appVersion = environment.version;
    this.passwordPattern = "^";
    if (environment.userpoolPasswordPolicy.hasUpperCase) {
      this.passwordPattern = this.passwordPattern + "(?=.*?[A-Z])";
      this.passwordError = this.passwordError + " 1 uppercase,";
    }
    if (environment.userpoolPasswordPolicy.hasLowerCase) {
      this.passwordPattern = this.passwordPattern + "(?=.*?[a-z])";
      this.passwordError = this.passwordError + " 1 lowercase,";
    }
    if (environment.userpoolPasswordPolicy.hasNumber) {
      this.passwordPattern = this.passwordPattern + "(?=.*?[0-9])";
      this.passwordError = this.passwordError + " 1 number,";
    }
    if (environment.userpoolPasswordPolicy.hasSpecialCharacter) {
      this.passwordPattern = this.passwordPattern + "(?=.*?[#?!@$%^&*-])";
      this.passwordError = this.passwordError + " 1 special character,";
    }
    if (environment.userpoolPasswordPolicy.minLength && environment.userpoolPasswordPolicy.maxLength) {
      this.passwordPattern = this.passwordPattern + ".{" + environment.userpoolPasswordPolicy.minLength + "," + environment.userpoolPasswordPolicy.maxLength + "}";
      this.passwordError = this.passwordError + " minimum " + environment.userpoolPasswordPolicy.minLength + " and maximum " + environment.userpoolPasswordPolicy.maxLength + " characters ";
    }
    this.passwordPattern = this.passwordPattern + "$";
    this.passwordPattern = this.passwordPattern.toString();
  }

  ngOnInit() {
  }


  public openModal(template: TemplateRef<any>, setValuesFlag: any, instanceId: string) {
    this.modalRef = this.modalService.show(template, (setValuesFlag == undefined) ? {} : { ignoreBackdropClick: true, keyboard: false });
    this.isModalActive = true;
  }

  public forgotpassword() {
    console.log("USER NAME = ",this.username);
    if(!this.username) {
      return this.errorUsername = true;
    }
    this.userLoginService.forgotpassword(this.username, this.setPassword.bind(this));
  }

  setPassword(): Observable<any> {
    // this.spinner.hide();
    this.otp = "";
    this.newPassword = "";
    this.repeatPassword = "";
    this.modalRef = this.modalService.show(this.changePasswordPopup);

    this.passwordObservable = Observable.create(observer => {
      this.changePasswordClicked = () => {
        // this.spinner.show();
        observer.next([this.otp, this.newPassword]);
      }
    });
    return this.passwordObservable;
  }

  public login() {
    this.router.navigate(["/login"]);
  }

}
