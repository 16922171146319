import { Injectable } from "@angular/core"


@Injectable()

export class CacheService {

    public appUserType;
    public adminAccountId;
    public appAccountEmail;
    private dictionary = {};

    public showHelp: boolean = true;

    public put(key, value) {
        this.dictionary[key] = value;
    }

    public get(key):any {
        return this.dictionary[key];
    }

    public remove(key) {
        if (this.dictionary[key]) {
            delete this.dictionary[key];
        }
    }

}   