<nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-dark headroom headroom--top headroom--pinned" style="padding-top: 0px; padding-bottom: 0px; height: 70px;">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 container">
    <button class="navbar-toggler" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand" style="margin-right: 3rem;">
      <img src="./assets/img/brand/Trusity_Logo.png">
    </a>
  </div>
</nav>
