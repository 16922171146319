<jw-modal id="custom" [ngStyle]="{'display':displayStyle}">
    <!-- <canvas class="popup" id ="confetti-canvas" width="800" height="600"></canvas> -->
  <div style="z-index: 1;">
        <h3>Looking for courses suited for</h3>
        <button class="btn-custom btn-orange2 mt-4" (click)="closePopup()" style="margin: 3%; cursor: pointer;">
            <a class="text-blue" routerLink="/home-india">6 - 12 years</a>
        </button>
        <button class="btn-custom btn-orange2 mt-4" style="margin: 3%; cursor: pointer;">
            <a class="text-blue" routerLink="/learning">13 - 21 years</a>
        </button>
    </div>
    <!-- <canvas class="popup" id="confetti-canvas" width="800" height="600"></canvas> -->

</jw-modal>

<ngx-spinner bdColor="rgba(255,255,255,0.8)" color="rgb(74 72 72)"></ngx-spinner>
<!-- <app-navbar></app-navbar> -->
<!-- <app-navbar-india></app-navbar-india> -->
<div *ngIf="nodata" class="nodata-wrap">
    <p>Sorry!! There is no data available.</p>
</div>
<!-- <app-sections></app-sections> -->
<div class="c_ontainer" style="max-width:100%; padding:0px; margin-bottom: 50px;" *ngIf="slider">
    <div class="row">
        <div class="col-lg-12 top_slider_holder">
            <owl-carousel-o [options]="top_slider" class=""> 
                <ng-template carouselSlide>
                    <img class="img-fluid cursor" src="../../assets/home-india/inventory_home_page_banner.png"  (click)="truPreneursClick()"  alt="Proficiency Test" />
                    <!-- <a href="https://www.trusity.com/proficiency"></a> -->
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
<!-- <section class="c_ontainer"  style="max-width:100%; padding:0px; margin-bottom: 50px;">
    <div class="col-lg-12 top_slider_holder">
        <div class="col-sm-6 app_background">
            <img class="img-fluid" src="../../assets/home-latest/banner_home.png" (click)="truPreneursClick()" alt="Proficiency Test" />
        </div>
    </div>
</section> -->
<section>
    <div class="title_align">
    <!-- <div class="main_title_journey" [innerHTML]="secElevenData.heading | stripTags"></div> -->
        <h1 class="main_title_journey">Start Your Child's Learning Journey </h1>
        <!-- <h5 class="title_sub"> Trusity Learning is the first holistic edtech platform in the UAE that has been approved by the KHDA</h5> -->
    </div>
    <div class="c_ontainer" >
        <div class="background">
            <img class="img_learning" src="../assets/home-india/inventory_Std_Journey_Learning.png">
            <!-- <img class="img_learning" src={{news.image}} /> -->
        </div>
    </div>
</section>

<section class="why_trusity trulntel_project">
    <div class="c_ontainer">
        <div class="why_trusity_box">
            <div class="w_box bottomDiv">
                <animated-digit [digit]="23200" [duration]="4000"></animated-digit>
                <p>Students Registered</p>
            </div>
            <div class="w_box bottomDiv">
                <animated-digit [digit]="450" [duration]="4000"></animated-digit>
                <p>Professional Teachers</p>
            </div>
            <div class="w_box bottomDiv">
                <animated-digit [digit]="25000" [duration]="4000"></animated-digit>
                <p>Sessions Delivered</p>
            </div>
        </div>
    </div>
</section>
<div class="grid-container">
    <div class="grid-item">
        <img class="precent_img" src="../../assets/home-professional/Teacher-Quality.png">
    </div>
    <div class="grid-item">
        <img class="precent_img" src= "../../assets/home-professional/Referral.png">
    </div>
    <div class="grid-item">
        <img class="precent_img" src="../../assets/home-professional/Satisfaction_Rate.png">
    </div>
</div>

<section class="mobile_app" >
    <div class="col-sm-12 c_ontainer_app row">
        <div class="col-sm-6 app_background">
            <img class="img_app" src="../../assets/home-latest/App.png" alt="Trusity App">
        </div>
        <div class="col-sm-6 align_left">
            <h2 class="heading_app" > Learning Simplified with Trusity</h2>
            <h3 class="brief_app"> Download our app and enjoy free access to learning materials, assessments and quizzes.</h3>
            <div class="row app_sec_align">
                <a href="https://play.google.com/store/apps/details?id=com.trusity" _target="blank" aria-label="Play Store" class="cursor app_icon"> <img src="../assets/home-latest/google_play_app.webp" alt="Play Store"> </a>
                <a href="https://apps.apple.com/ae/app/trusity/id1591877200"  target="_blank" aria-label="App Store" class="cursor app_icon"> <img src="../assets/home-latest/app_store.webp" alt="App Store"> </a>
            </div>
        </div>
    </div>
</section>

<section id="popular_course" class="course_align">
    <img class="wave_courses" src="../assets/home-latest/wave_2.png">
        <div class="cards-list">
            <div class="card_info">
                <div class="card_title title-white">
                    <h1 class="courses"> Popular Courses </h1>
                    <h5 class="courses_sub">Please Choose What Best Suits Your Needs</h5>
                    <div class="cursor courses session-btn">
                        <a [routerLink]="['/live-classes']">Learn More</a>
                    </div>
                </div>
            </div>
            <div (click)="getCourse(course.name)" class="card 1"*ngFor="let course of ExamPrep;">
                <div class="card_image"> 
                    <img class="image_style" src={{course.image}}/> 
                </div>
                <div class="session-btn1"(click)="getCourse(course.courseName)">
                    <a routerLink="/courses/{{course.courseName.toLowerCase()}}">Join Class</a>
                </div>
                <div class="card_title title-white">
                    <h3 class="course_title"> {{course.courseName}} </h3>
                    <p class="col-sm-6 course_cont">{{course.brief}}</p>
                    <!-- <div class="col-sm-6 row course_row">
                        <p class="col-sm-6 course_age">Age</p> 
                    </div>
                    <div class="col-sm-6 row course_row">
                        <p class="col-sm-6 course_age">{{course.age}}</p>
                        <p class="col-sm-5 course_live">{{course.session}}</p>
                    </div> -->
                </div>    
            </div>
        </div>
</section>
<div class="c_ontainer" >
    <div class="course_main_title discover_head_align" >Your Voice is the Heart of our Purpose </div>
    <div class="backimage">
        <div class="two-Landing p-0">
            <div class="col-12 p-0 courses-info-holder">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide *ngFor="let title of discoverList">
                        <div class="courses-info-box">
                            <div class="">
                                <div>   
                                    <img class="mask_img" src={{title.img}} />
                                    <h6 class="text-light" >{{title.name}}</h6>
                                    <p class="text-light">{{title.content}}</p>
                                </div> 
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>

<!-- <section class="course_offred mt-4">
    <div class="c_ontainer" *ngIf="secThirteenData">
        <div class="main_title mb-4" [innerHTML]="secThirteenData.heading | stripTags"></div>
        <div class="backimage">
            <div class="two-Landing p-0">
                <div class="col-12 p-0 courses-info-holder">
                    <owl-carousel-o [options]="customOptions" >
                        <ng-template carouselSlide *ngFor="let content of secThirteenData.catOne;">
                            <div class="purpose courses-info-box">
                                <div >
                                    <div>   
                                        <img class="mask_img" src={{content.url}} />
                                        <h6 [innerHTML]="content.headinglabel | stripTags"></h6>
                                        <p [innerHTML]="content.content | stripTags"></p>
                                        <div class="session-btn">
                                            <a href={{content.date}} [innerHTML]="content.subheading | stripTags"></a>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="teachers_offred">
    <div class="container1">
        <div class="float-right">
            <img src='../assets/home-latest/wave_2.png'/>
        </div> 
       <br>
       <div class="title3">Meet The Teachers</div>
       <h5 class="sub_title">We Develop Love for Learning and work to Inspire and Change Lives Positively!</h5> 
       <br>
                <div>
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide *ngFor="let teacher of teachers;">
                                <div class="container3">
                                    <img class="mask_img1" src={{teacher.image}}/>
                                    <button class="btn1">
                                        <h6 class="teacher_name">{{teacher.name}}</h6>
                                        <h6 class="teacher_brief">{{teacher.breif}}</h6>
                                    </button>
                                </div>               
                        </ng-template>
                    </owl-carousel-o>
                </div>
    </div>
</section> -->

<section class="teachers_offred">
    <div class="container1" *ngIf="teachers">
        <div class="float-right" >
            <img src='../assets/home-latest/wave_2.png'/>
        </div> 
       <br>
        <div>
        <div class="title3">Meet The Teachers</div>
        <h5 class="sub_title">We Develop Love for Learning and work to Inspire and Change Lives Positively!</h5> 
        <br>
                    <div >
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let content of teachers;">
                                    <div class="container3">
                                        <img class="mask_img1" src={{content.image}}/>
                                        <button class="btn1">
                                            <h6 class="teacher_name" >{{content.name}}</h6>
                                            <h6 class="teacher_brief">{{content.breif}}</h6>
                                        </button>
                                    </div>               
                            </ng-template>
                        </owl-carousel-o>
                    </div>
        </div>
    </div>
</section>

<section id="review_box" class="review_box">
    <div class="c_ontainer" *ngIf="testimonials">
        <div class="main_title">Our Students Love Us</div>
        <ngb-carousel [options]="review_slider" [items]="images" [carouselClasses]="['owl-theme', 'sliding']"> 
            <ng-template ngbSlide *ngFor="let test of testimonials;">
                <div class="card courses-info-box">
                     <div id="review_image" alt="Testimonials">
                         <img class="float-left" src={{test.image}} />

                     </div>
                     <div class="session-btnTest">
                        <h4 >{{test.name}}</h4>
                   
                     </div>
                     <div class="card_title title-white">
                        <img class ="float-left top" src="../../assets/home-latest/quote_org.png" />
                        <p class="paragraph" >{{test.testimonial}}</p>  
                        <img class ="float-right bottom" src="../../assets/home-latest/quote_org.png" />
                    </div>  
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</section>


<div class="c_ontainer logo_slider slider_container" *ngIf="secTenData">
    <div class="main_title">Our Partners</div>

    <div class="slider_container_owl" id="secTenData.slider">
        <button class="next" (click)="owlCar.prev()"><img src="../../assets/home-new/arrow_left.png" alt="Left Arrow" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar> 
            <ng-template carouselSlide *ngFor="let school of secTenData;">
                <img src={{school.image}}  class="logo_img" style="height: auto !important" alt="School Logo"/>
              

            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" alt="Right Arrow"/></button>
    </div>
</div> 

    
<div class="c_ontainer logo_slider slider_container" *ngIf="secOrganizationData">
        <h1 class="main_title">Accreditation</h1>
    <div class="slider_container_owl">
        <button class="next" (click)="owlCar.prev()"><img src="../../assets/home-new/arrow_left.png" alt="Left Arrow" /></button>
        <owl-carousel-o [options]="logoOption" class="" #owlCar> 
            <ng-template carouselSlide *ngFor="let organization of secOrganizationData;">
                <img src={{organization.image}}  class="logo_img" alt="Organization Logo" />
            </ng-template>
        </owl-carousel-o>
        <button class="prev" (click)="owlCar.next()"><img src="../../assets/home-new/arrow_right.png" alt="Right Arrow"/></button>
    </div>
</div>


