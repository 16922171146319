<!-- ============== paege not found ========== -->
<!-- <ma-navigation></ma-navigation>  -->
<div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 m-t-60 shape-container d-flex row m-l-0 m-r-0 no-padding width-full">
    <!-- <div class="col-sm-6 col-xs-6 col-md-6 col-lg-6 col-xl-7 v-align-center text-center content div-left"> 
    <h1>Oops..</h1>
    <h1 class="b title margin">404</h1>
    <h1>Page not found</h1>
    <hr/>
    <h3>We are really sorry,</h3>
    <h3>But your search is out of syllabus or link is broken :(</h3>
    <h3>Perhaps search again or Go back to <a [routerLink]="[ ]" class="color: #32325d;">Home</a>.</h3>
    </div> -->
    <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 div-padding"> 
           <img src="../../assets/404/404-Error.png" class="img-size">
    </div>
</div>
