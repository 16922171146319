<div *ngIf="!showArFooter() && !showFooter() && !hideFooter() && !hideFooterGallery() && !hideFooterProfessional() && !hideEnquiryProfessional() && !hideRegisterGeorgia() && !hideFooterSchedule() && !hideFootermyChildCalender() && !hideInvoices() && !hideEditAccount() && !hideAddChild() && !hideFooterChangePwd() && !hideFooterMyFiles() && !hideFooterGames() && !hideFooterQA() && !hideFooterNQ() && !hideFooterQ() && hideHeader() == 0">
    <app-navbar></app-navbar>
</div>
<div *ngIf="hideFooterProfessional() && hideEnquiryProfessional()" >
    <app-set-navbar></app-set-navbar>
</div>

<!-- <div *ngIf="hideFooterIndia" >
    <app-navbar-india></app-navbar-india>
</div> -->
<router-outlet></router-outlet>
<div *ngIf="!showArFooter() && !hideHeaderFooter() && !hideFooterTrusity() &&  !hideLiveClasses() && !showFooter() && !hideFooterEnquiry() && !hideFooterSpringCamp() && !hideFooterScholarSpringCamp() && !hideRegisterGeorgia() && !hideFooter() && !hideFooterSchedule() && !hideFootermyChildCalender() && !hideInvoices() && !hideEditAccount() && !hideAddChild() && !hideFooterChangePwd() && !hideFooterMyFiles() && !hideFooterGames() && !hideFooter404() &&!hideFooterQA() && !hideFooterNQ()  && !hideFooterQ()">
    <app-footer></app-footer>
</div>

<div *ngIf="hideHeaderFooter() && hideFooterTrusity() && hideLiveClasses()">
    <app-footer-redirect></app-footer-redirect>
</div>
<toaster-container></toaster-container>