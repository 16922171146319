<button class="str-chat__channel-preview-messenger str-chat__channel-preview"
  [class.str-chat__channel-preview-messenger--active]="isActive" [class.str-chat__channel-preview--active]="isActive"
  (click)="setAsActiveChannel()" data-testid="channel-preview-container">
  <div class="str-chat__channel-preview-messenger--left">
    <stream-avatar-placeholder
      name="{{ avatarName }}"
      imageUrl="{{ avatarImage }}"
      type="channel"
      [channel]="channel"
      location="channel-preview"
      [size]="40"
    ></stream-avatar-placeholder>
  </div>
  <div class="
      str-chat__channel-preview-messenger--right str-chat__channel-preview-end
    ">
    <div class="str-chat__channel-preview-end-first-row">
      {{ title}}
      <!-- {{channel?.data | json}} -->
      <div data-testid="unread-badge" *ngIf="unreadCount" class="str-chat__channel-preview-unread-badge">
        {{ unreadCount }}
      </div>
    </div>
    <div data-testid="latest-message" class="str-chat__channel-preview-messenger--last-message">
      {{ latestMessage}}
    </div>
  </div>
</button>