import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from "ngx-spinner";
import { HomeIndiaService } from './home-india.service';
import { Subscription, interval } from 'rxjs';
import { FooterNavbarSharedService } from '../shared/footer-navbar-shared/footer-navbar-shared.service';

@Component({
  selector: 'app-home-india',
  templateUrl: './home-india.component.html',
  styleUrls: ['./home-india.component.scss']
})
export class HomeIndiaComponent implements OnInit {
  public courseName: any;
  // slider: any;
  homeData = [];
  courseData: any;
  whyTrusityData: any;
  enrollData: any;
  workshopData: any;
  secThirteenData: any;
  // secOrganizationData = [];
  secElevenData = [];
  secTwelveData: any;
  featureData: any;
  videoSection = [];
  secEightData = [];
  // testimonials = [];
  // secTenData = [];
  videoSectionLeft : any;
  videoSectionRight : any;
  slideList: any;
  sliderList: any = [];
  courseResponse: any;
  featureResponse: any;
  descData: any;
  activeLanguage;
  actLanguage;
  
  images;
  nodata = false;
  languageList = [];
  private subscription: Subscription;
  public dateNow = new Date();
  public dDay = new Date('Feb 26 2022 10:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;
  displayStyle = "block";

  top_slider: OwlOptions = { 
    loop: true,
    dots: true,
    navSpeed: 200, 
    items:1,
    nav: true,
    // autoplay:true,
    // autoplayTimeout:5000,
    // autoplayHoverPause:true,
    slideTransition: 'linear',
    responsive: {
      0: {
        items:1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1
      }
    },
  }

  customOptions: OwlOptions = {
    loop: true,
    // autoplay: true,
    dots: false,
    navSpeed: 700,
    margin:30,
    // center:true,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items:1,
        stagePadding: 25,
        margin:10,
      },
      400: {
        items: 2,
        stagePadding: 25,
        margin:10,
      },
      740: {
        items: 2,
        stagePadding: 20,
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
  
  review_slider: OwlOptions = {
    loop: true,
    dots: true,
    // navSpeed: 200, 
    items:1,
    margin: 30,
    // autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items:1,
      },
      400: {
        items: 1,
      },
      770: {
        items: 2,
      },
      992: {
        items: 1,
      }
    },
  }

  openPopup() {
    this.displayStyle = "block";
  }
  
  closePopup(){
   this.displayStyle = "none";
  }
  
  logoOption: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 100, 
    nav: true,
    // autoplay:true,
    // autoplayTimeout:5000,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items: 2,
        stagePadding: 25,
        margin:10,
      }, 
      400: {
        items: 2,
       stagePadding: 25,
        margin:10,
      },
      740: {
        items: 3,
        stagePadding: 25,
        margin:10,
      },
      940: {
        items: 5
      }
    },
    
  }
slider =[
  {
    "image": '../../assets/home-india/inventory_home_banner.png',
    "link": "https://www.trusitylearning.com/proficiency"
  }
  // {
  //   "image": '../../assets/home-india/inventory_STEAMCompetitionBanner1.png'
    
  // },
  // {
  //   "image": '../../assets/home-india/inventory_WinterCampBanner.png',
  //   "link": "https://www.trusity.com/winter-camp"
  // }
]
      public workshops = [
      {
        "image": '../assets/home-latest/students_joy.png',
        "name": "Students Joy",
        "breif": "Trusity encourages lifelong learning through the holistic approach by making learning fun and easy for students.",
        "link": "#review_box",
        // "trainer": "Parveen Sharma",
        // "date": "27th November",
        // "timings": "10 am - 11 am"
      },
      {
        "image": '../assets/home-latest/parent_trust.png',
        "name": "Parents Trust",
        "breif": "Parents play a crucial role in their child’s upbringing and Trusity works hand-in-hand with parents to support their goals.",
        "link": "#review_box",
        // "trainer": "Mohamad Beiruty",
        // "date": "27th November",
        // "timings": "11 am - 12 pm"
      },
      {
        "image": '../assets/home-india/inventory_teacher_quality.png',
        "name": "Teachers Quality",
        "breif": "350+ handpicked tutors who come from 5 continents with an extensive experience from reputable colleges and universitiies.",
        "link": "https://www.trusity.com/become-a-teacher",
        // "trainer": "Kinan Elayaan",
        // "date": "27th November",
        // "timings": "12 pm - 1 pm"te

      }
    ]
    public ExamPrep = [
      {
        "image": '../../assets/home-latest/truPreneurs_home.png',
        "courseName": "truPreneurs",
        "brief": "truPreneurs programme aims to provide youth with the ability to build knowledge and entrepreneurial skills. ", 
        "age": "11+",
        "session": "Live Class"
      },
      {
        "image": '../../assets/home-latest/mmath_home.png',
        "courseName": "Mental Mathematics",
        "brief": "Mental Math allows your child to solve mathematical problems without the use of tools.", 
        "age": "7-11",
        "session": "Live Class"
      },
      {
        "image": '../../assets/home-latest/robotics_home.png',
        "courseName": "Robotics",
        "brief": "Learn STEM concepts, that deals with making, programming and designing of robots.", 
        "age": "6+",
        "session": "Live Class"
      },
  
    ]
    discoverList: any = [
      {
        img: "../../assets/home-india/inventory_teacher_quality.png",
        name: "Teachers Quality",
        content: "350+ handpicked tutors who come from 5 continents with an extensive experience from reputable colleges and universitiies.",
      },
      {
        img: "../../assets/home-latest/students_joy.png",
        name: "Student Joy",
        content: "Trusity encourages lifelong learning through the holistic approach by making learning fun and easy for students.",
      },
      {
        img: "../../assets/home-latest/parent_trust.png",
        name: "Parents Trust",
        content: "Parents play a crucial role in their child’s upbringing and Trusity works hand-in-hand with parents to support their goals.",
      },
    ]
    public teachers = [
      {
        "image": '../assets/home-latest/teacher_1.jpg',
        "name": "Margarita Knight",
        "breif": "English Teacher",
      },
      {
        "image": '../assets/home-latest/teacher_2.jpg',
        "name": "Sarah Jumaah",
        "breif": "Arabic Teacher",
      },
      {
        "image": '../assets/home-latest/teacher_3.jpg',
        "name": "Faiza Mubeen",
        "breif": "Communication Teacher ",
      },
      {
        "image": '../assets/home-latest/teacher_4.jpg',
        "name": "Sumera Mumtaz",
        "breif": "Film Making Teacher",
      },
      {
        "image": '../assets/home-latest/teacher_5.jpg',
        "name": "Farook K",
        "breif": "Keyboard Teacher",
      }
    ]
    public testimonials = [
      {
        "image": "../../assets/home-india/Mythili.png",
        "name": "Mythili",
        "testimonial": "I have been with Trusity over a year now and it has been a great experience. Have learnt so many things and I would trade it for nothing. I have learnt from coding to communication, create and present a pitch deck. It has been an amazing experience and it has given so many valuable insights to the real world, which I will definitely be using in the future.",
      }, 
      {
        "image": "../../assets/home-india/Alec.PNG",
        "name": "Alec",
        "testimonial": "Being a truPreneurs was an amazing experience. I got to learn how to innovate, make elevator pitches and so much more. I would never trade this for anything else in my life. Thank you Trusity for everything you have done for me.",
      }, 
      {
        "image": "../../assets/home-india/Testimonial Icon.png",
        "name": "Farhaan",
        "testimonial": "Trusity is one of the best learning platforms out there and it has the best teachers. Ms. Noor is my Arabic teacher. Initially I found learning Arabic really hard. However, since I joined Trusity, Arabic became easier. If I don’t understand something, my teacher explains it again. She makes it interesting through jokes and stories. That’s why I like my teacher and Trusity.",
      }, 
      {
        "image": "../../assets/home-india/Ayaan.jpg",
        "name": "Ayaan",
        "testimonial": "Through the TruPreneurs program, I learnt a lot. From coding, communication to business skills, to how to be an entrepreneur. I have learnt how I can make my idea into reality.",
      },
      {
        "image": "../../assets/home-india/Aarush.PNG",
        "name": "Aarush",
        "testimonial": "My journey with Trusity has been a great one. I started with Trusity over a year ago where I learnt how to innovate, communicate and how to start a business. It has been a great journey and I have learnt so many skills.",
      }, 
      {
        "image": "../../assets/home-india/Testimonial Icon.png",
        "name": "Myra",
        "testimonial": "Trusity and indeed, Mr. Taoufik has really been a life saver for us, Indian parents when it comes to a third language. My daughter has been learning French since the past few months and I must say it’s taken a lot of load off our minds to see her reaching at par with her classmates. Both, Aleena and Jitha, the coordinators have been extremely prompt with all help. The teacher is approachable and there is easy two-way communication. Thank you Trusity !",
      },
      {
        "image": "../../assets/home-india/Testimonial Icon.png",
        "name": "Ghanesh Kumar",
        "testimonial": "I have really enjoyed attending the classes organized by trusity. The teachers are very interactive, patient, and cool to hang out with. What's being taught is also super interesting. I do not regret taking this course as it has taught a whole plethora of things.",
      },
      {
        "image": "../../assets/home-india/Testimonial Icon.png",
        "name": "Adrian",
        "testimonial": "The learning experience was always fun and challenging and always interactive. The teacher would always find a way to make me understand and enjoy the lessons at the same time",
      },
      {
        "image": "../../assets/home-india/Testimonial Icon.png",
        "name": "Sufyaan",
        "testimonial": "Discovering Trusity this summer was one of the best things that happened to me. I joined their 6-week Future Development course, focusing on communication, workplace etiquette, and interviews. The teacher's guidance and assessments boosted my skills significantly. Trusity also arranged a 2-week internship in Robotics and AI, providing valuable real-world experience. I'm grateful for this opportunity and recommend the youth of today to get involved in such courses and internships through Trusity as they help you develop into a high-value person both professionally and socially.",
      }
    ]
    public secTenData = [ 
      {
        "image" : "../../assets/schools/ADIS.png"
      },
      {
        "image" : "../../assets/schools/Al-zuhour.png"
      },
      {
        "image" : "../../assets/schools/Ambassador-School-Sharjah.png"
      },
      {
        "image" : "../../assets/schools/aspam-indian-international-school.png"
      },
      {
        "image" : "../../assets/schools/Delhi-private-school.png"
      },
      {
        "image" : "../../assets/schools/Duness.png"
      },
      {
        "image" : "../../assets/schools/Ideal-English-School.png"
      },
      {
        "image" : "../../assets/schools/our-own-english-high-school-sharjah-boys.png"
      },
      {
        "image" : "../../assets/schools/Pakistan-Higher-Secondary-School.png"
      },
      {
        "image" : "../../assets/schools/scholars-indian-school.png"
      },
      {
        "image" : "../../assets/schools/Scholars-International-Academy.png"
      },
      {
        "image" : "../../assets/schools/springDales.png"
      },
      {
        "image" : "../../assets/schools/Sunrise-english.jpg"
      },
    ]
    public secOrganizationData =[ 
      {
        "image" : "../../assets/home-india/KHDA Permitted.png"
      },
      {
        "image" : "../../assets/home-india/inventory_STEAM_ORG (1).png"
      },

    ]
  constructor( private homePageService: HomeIndiaService, private spinner: NgxSpinnerService, private router: Router, private fooService: FooterNavbarSharedService,) {
    this.fooService.currentDataFetch.subscribe(res => {
    if(res){
      this.actLanguage = res;
      this.getHomepage();
    }
  })
   }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }



truPreneursClick() {
  // window.open("https://forms.zohopublic.com/trusity/form/truIntel/formperma/eE6mqqcIVWy-BPWnJY-HRstO5L49zJzRJqR0PyQPDqE");
  this.router.navigate(['/proficiency']);
  window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
        "event": "click_take_proficiency_test",
        });
  window['dataLayer'] = window['dataLayer'] || {};
}

mathRedirect(){
  this.router.navigate(['/courses/mental!mathematics']);
}

truPreneursRedirect(){
  this.router.navigate(['/courses/trupreneurs']);
}

roboticsRedirect(){
  this.router.navigate(['/courses/robotics']);
}

  resetLanguage(){
    this.slider  = [];
    console.log(this.slider);
    this.courseData = null
    this.courseResponse  = null;
    this.sliderList  = null;
    this.enrollData  = null;
    this.whyTrusityData  = null;
    this.workshopData  = null;
    this.secThirteenData = null;
    this.featureData  = null;
    this.featureResponse  = null;
    this.descData  = null;
    this.videoSection  = null;
    this.videoSectionLeft  = null;
    this.videoSectionRight  = null;
    this.secEightData  = null;
    this.testimonials  = null;
    this.secTenData  = null;
    this.secElevenData  = null;
    this.secTwelveData = null;
    this.secOrganizationData = null;
  }
  getCourse(courseName) {
    console.log(courseName);
    this.courseName = courseName.split(/[ ]+/);
    this.courseName = courseName.replace(/<.*?>/g, '');
    console.log(this.courseName);
    this.router.navigate([`/courses/${this.courseName}`]);
}
  
  getHomepage() {
    this.spinner.show();
    if(sessionStorage.getItem('activeLanguage')){
      this.actLanguage = sessionStorage.getItem('activeLanguage');
    } else{
      sessionStorage.setItem('activeLanguage', 'English');
      this.actLanguage = 'English'
    }
    let defaultLanguage;
    const payload = {
      getDataByLanguage: this.actLanguage
    };
    this.homePageService.getHomepage(payload).subscribe(
      (res) => {
        this.homeData = res.data;
        defaultLanguage = res.language;
        if(res.data != null){
         this.spinner.hide();
          if(this.nodata == true){
            this.nodata = false;
          }
          for (let i = 0; i < this.homeData.length; i++) {
            if (this.homeData[i].sectionName == "sectionOne") {
              this.slider = this.homeData[i][defaultLanguage].content.images;
            }
            if (this.homeData[i].sectionName == "sectionTwo") {
              this.courseData = this.homeData[i][defaultLanguage].content;
              this.courseResponse = this.homeData[i][defaultLanguage].content.catMain;
              if (this.courseResponse.length > 0){
                this.sliderList = this.courseResponse[0].slideList;
              }
            }
            if (this.homeData[i].sectionName == "sectionThree") {
              this.enrollData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionFour") {
              this.whyTrusityData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionFive") {
              this.workshopData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionThirteen") {
              this.secThirteenData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionSix") {
              this.featureData = this.homeData[i][defaultLanguage].content;
              this.featureResponse = this.homeData[i][defaultLanguage].content.catLeft;
              if (this.featureResponse.length > 0){
                this.descData = this.featureResponse[0].circleDesc;
              }
            }
            if (this.homeData[i].sectionName == "sectionSeven") {
              // console.log(this.homeData[i][defaultLanguage].content)
              this.videoSection = this.homeData[i][defaultLanguage].content;
              this.videoSectionLeft = this.homeData[i][defaultLanguage].content.catLeft[0];
              this.videoSectionRight = this.homeData[i][defaultLanguage].content.catRight;
            }
            if (this.homeData[i].sectionName == "sectionEight") {
              this.secEightData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionNine") {
              this.testimonials = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionTen") {
              this.secTenData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionOrganization") {
              this.secOrganizationData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionEleven") {
              this.secElevenData = this.homeData[i][defaultLanguage].content;
            }
            if (this.homeData[i].sectionName == "sectionTwelve") {
              this.secTwelveData = this.homeData[i][defaultLanguage].content;
            }
          }
        }
        else{
          this.resetLanguage();
          this.nodata = true;
          this.slider = null;
          this.spinner.hide();
        }
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  selectCourse(id){
    this.sliderList = this.courseResponse[id].slideList;
  }
  selectFeature(id){
    this.descData = this.featureResponse[id].circleDesc;
  }

}
