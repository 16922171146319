import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalConfettiComponent } from './modal-confetti.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ModalConfettiComponent],
    exports: [ModalConfettiComponent]
})
export class NewModalConfettiModule { }