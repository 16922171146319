import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { UserLoginService } from "../services/user.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { SpinnerService } from "../services/spinner.service";
import { LoggedInUserInfo } from '../model/LoggedInUserInfo';
import { StudentListService } from '../Dashboard-Main/student-list/student-list.service';

@Component({
    selector: 'app-chat-header',
    templateUrl: './chat-header.component.html',
    styleUrls: ['./chat-header.component.scss'],
    providers: [NgbDropdownConfig]
})
export class ChatHeaderComponent implements OnInit {
    @ViewChild("changePassword") changePasswordPopup: ElementRef;
    public isCollapsed = true;
    public userInfoData;
    private lastPoppedUrl: string;
    public notificationStudentList = [];
    public notificationteacherList=[];
    public notificationList = [];
    public notificationTeacherLength :any;
    public notificationStudentLength :any;
    private yScrollStack: number[] = [];
    filter: any;
    userName;
    profileName: any;
    public modalRef: BsModalRef;
    public passwordEnteredObservable: Observable<any>;
    public changePasswordClicked: Function;
    public newPassword: string = "";
    public repeatPassword: string = "";
    userInfo: LoggedInUserInfo;

    constructor(public location: Location, private router: Router, private studentListService: StudentListService,
        private userService: UserLoginService,
        private modalService: BsModalService,
        private spinner: SpinnerService,
    ) {
    }

    ngOnInit() {
        this.userInfoData = JSON.parse(localStorage.getItem("userInfo"));
        this.getNotications();
        this.userInfo = this.userService.getUserInfo();
        console.log("userInfo ==>  ", this.userInfo);
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
            if (event instanceof NavigationStart) {
                if (event.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (event instanceof NavigationEnd) {
                if (event.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
    }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === 'home') {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === 'documentation') {
            return true;
        }
        else {
            return false;
        }
    }
    // if(this.userInfoData.key.includes("teacherID")){
 
    getNotications() {
        if (this.userInfoData.key.studentID) {
            const payload = {
                type: 1,
                timestamp: this.userInfoData.key.timestamp,
                studentID: this.userInfoData.key.studentID
            }
            this.studentListService.getNotification(payload).subscribe(
                (res) => {
                    this.notificationStudentList = res.data;
                    this.notificationStudentLength = res.data.length;
                }, 
                (error) => {
                }
            );
        }
        else {
            const payload = {
                type: 2,
                timeStamp: this.userInfoData.key.timeStamp,
                teacherID: this.userInfoData.key.teacherID
            }
            this.studentListService.getNotification(payload).subscribe(
                (res) => {
                    this.notificationteacherList = res.data;
                    this.notificationTeacherLength = res.data.length;
                    console.log(this.notificationTeacherLength);
                    console.log(this.notificationteacherList)
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    // getNotications() { 
    //     this.spinner.show();
    //     const payload = {
    //         type: 2,
    //         timeStamp: this.userInfoData.key.timeStamp,
    //         teacherID: this.userInfoData.key.teacherID
    //     }
    //     this.studentListService.getNotification(payload).subscribe(
    //         (res) => {
    //             this.notificationList = res.data;
    //             this.spinner.hide();
    //         },
    //         (error) => {
    //             console.log(error);
    //             this.spinner.hide();
    //         }
    //     );
    // }

    showFooter() {
        return this.router.url === '/dashboard';
    }

    hideFooter() {
        return this.router.url === '/avialable-course';
    }

    hideFooterSchedule() {
        return this.router.url === '/scheduler';
    }

    hideFootermyChildCalender() {
        return this.router.url === '/children-calender';
    }
    hideInvoices() {
        return this.router.url === '/invoices';
    }

    hideEditAccount() {
        return this.router.url === '/edit-account';
    }

    hideAddChild() {
        return this.router.url === '/add-child';
    }

    hideFooterChangePwd() {
        return this.router.url === '/change-password';
    }

    setPassword(): Observable<any> {
        console.log("set password");
        // this.spinner.hide();
        this.modalRef = this.modalService.show(this.changePasswordPopup);
        this.passwordEnteredObservable = Observable.create(observer => {
            this.changePasswordClicked = () => {
                // this.spinner.show();
                observer.next(this.newPassword);
                this.modalRef.hide();
            }
        });
        return this.passwordEnteredObservable;
    }

    loggedOut() {
        if (this.userService.isAuthenticated()) {
            this.userService.logout();
            this.router.navigate(["/home"]);
            localStorage.setItem("userInfo", null);
            // this.userName='null';
        }
    }
}
