import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { SEOService } from './services/seo.service';
// import{GeolocationService} from './CountryService/CountryService.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 0;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    isLoading: boolean;
    // userIP = ''
    
    // constructor(private seoService: SEOService, private renderer : Renderer2, private router: Router, @Inject(DOCUMENT,) private document: any, private element : ElementRef, public location: Location) {
    constructor(private seoService: SEOService, private httpClient: HttpClient,  private renderer : Renderer2, private router: Router, @Inject(DOCUMENT,) private document: any, private element : ElementRef, public location: Location) {
        // Spinner for lazyload modules
    router.events.forEach((event) => {
        if (event instanceof RouteConfigLoadStart) {
          this.isLoading = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.isLoading = false;
        }
      });
    }
    @HostListener('window:scroll', ['$event'])
    hasScrolled() {

        var st = window.pageYOffset;
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        var navbar = document.getElementsByTagName('.nav')[0];

        // If they scrolled down and are past the navbar, add class .headroom--unpinned.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
    
            // if (navbar.classList.contains('headroom--pinned')) {
            //     navbar.classList.add('headroom--pinned');
            //     navbar.classList.remove('headroom--unpinned');
            // }
            // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
        } else {
            // Scroll Up
            //  $(window).height()
            if(st + window.innerHeight < document.body.scrollHeight) {
                // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
                // if (navbar.classList.contains('headroom--unpinned')) {
                //     navbar.classList.remove('headroom--unpinned');
                //     navbar.classList.add('headroom--pinned');
                // }
            }
        }

        lastScrollTop = st;
    };
    ngOnInit() {
        // this.loadIp();
    this.createLinkForCanonicalURL();

      var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          if (window.outerWidth > 991) {
              window.document.children[0].scrollTop = 0;
          }else{
              window.document.activeElement.scrollTop = 0;
          }
          this.renderer.listen('window', 'scroll', (event) => {
              const number = window.scrollY;
              if (number > 150 || window.pageYOffset > 150) {
                  // add logic
                //   navbar.classList.add('headroom--not-top');
              } else {
                  // remove logic
                //   navbar.classList.remove('headroom--not-top');
              }
          });
      });
      this.hasScrolled();
      }
      

createLinkForCanonicalURL() {
 this.seoService.createLinkForCanonicalURL();
} 

    showFooter() {
        return this.router.url === '/dashboard';
    }

    showArFooter() {
        return this.router.url === '/ar/dashboard';
    }

    hideFooter() {
        return this.router.url === '/avialable-course';
    }

    hideFooterProfessional() {
        return this.router.url === '/learning';
    }
    hideHeaderFooter(){
        return this.router.url === '/'
    }
    hideFooterTrusity(){
        return this.router.url === '/trusity'
    }
    hideLiveClasses(){
        return this.router.url === '/live-classes'
    }
    hideFooterIndia() {
        return this.router.url === '/home-india';
    }
    hideFooterGallery(){
        return this.router.url === '/gallery';
    }

    hideEnquiryProfessional() {
        return this.router.url === '/enquire-learning';
    }

    hideRegisterGeorgia() {
        return this.router.url === '/register';
    }

    hideFooterSchedule() {
        return this.router.url === '/scheduler';
    }

    hideFooterEnquire() {
        return this.router.url === '/enquire';
    }

    hideFootermyChildCalender() {
        return this.router.url === '/children-calender';
    }
    hideInvoices() {
        return this.router.url === '/invoices';
    }

    hideEditAccount() {
        return this.router.url === '/edit-account';
    }

    hideAddChild() {
        return this.router.url === '/add-child';
    }

    hideFooterChangePwd() {
        return this.router.url === '/change-password';
    }

    hideFooterMyFiles() {
        return this.router.url === '/my-files';
    }

    hideFooterEnquiry() {
        return this.router.url === '/enquire-now';
        // return this.router.url === '/enquire';
    }

    hideFooterScholarSpringCamp() {
        return this.router.url === '/ScholarSpringCamp';
    }

    hideFooterGames() {
        return this.router.url === '/games';
    }

    hideFooter404() {
        return this.router.url == '**';
    }

    hideFooterQA() {
        return this.router.url === '/q&a', this.router.url === '/new-q&a';
    }

    hideFooterNQ() {
        return this.router.url === '/new-q&a';
    }

    hideFooterQ() {
        return this.router.url === '/quiz';
    }

    hideFooterSpringCamp() {
        return this.router.url === '/camp';
    }
  // manual merging added on 21 july 2022 start
    hideHeader() {
        if(window.location.href.includes('/pilot/')){
            return 1;
        } else{
            return 0;
        }
    }

}

// function resolve(arg0: string) {
//     throw new Error('Function not implemented.');
// }

