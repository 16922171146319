import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from "@angular/common/http";
// import { Observable } from "rxjs";
import { Observable, of } from 'rxjs'
import { map, filter, scan, catchError} from 'rxjs/operators';
// import {Observable} from 'rxjs/Rx';
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
// import "rxjs/add/observable/throw";
import * as _ from "lodash";
import { Constants } from "./constants";
import { CacheService } from "./cache.service";
import { environment } from "./../../environments/environment";

@Injectable()
export class DataService {

    private  headers = new Headers({ "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" });
    private  options = { headers: this.headers};

    constructor(
        private http: HttpClient,
        private constants: Constants,
        private cache: CacheService
    ) {
        console.log("Hello dataservice Provider");
        this.resetAuthHeader();
    }

    //post data with params and body
    public postData(uri: string, bodyString?: string) {
        return this.http.post(uri, bodyString
        ).catch(this.handleError);
    }

    // get data with params
    public getData(uri: string) {
        return this.http.get(uri)
            .map(res => res)
            .catch(this.handleError);
    }

    private handleError(error: Response | any) {
        return Observable.throw(error);
    }

    public resetAuthHeader() {

        // We do not want to set Authorization header in SAM Local environment
        // As we get CORS issue

        // if (environment.nonproduction.local == true) {
        //     this.headers = new Headers({
        //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        //         // "Authorization": (this.cache.get(this.constants.COGNITO_IDTOKEN)) ? this.cache.get(this.constants.COGNITO_IDTOKEN).jwtToken : ""
        //         "Authorization": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2NjZiZDBjYi1mZjIxLTQ2ZWUtYmE3NC1kODcyMWJlOTlmZGUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZ2VuZGVyIjoiTWFsZSIsImlzcyI6Imh0dHBzOi8vY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb20vdXMtZWFzdC0xX3RZQmNDWHp0VyIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6ImFkbWluLm5pa2libHUiLCJnaXZlbl9uYW1lIjoiSGFyaSIsImF1ZCI6IjY0bjUxZGNhYXE0ZzZiN3ExbW1qZGdvMzRmIiwiZXZlbnRfaWQiOiI4NjRhODQ3My1iNGRlLTExZTgtYjQ2Yi1hNzc5MjE1YWFhMzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTUzNjU3Mjg0NiwicGhvbmVfbnVtYmVyIjoiKzkxNzAxOTcwMzMyMSIsImV4cCI6MTUzNjU2Njc2NCwiaWF0IjoxNTM2NTcyODQ2LCJmYW1pbHlfbmFtZSI6IkJhYnUiLCJlbWFpbCI6ImhhcmliYWJ1LmphbmphbGFAdGVjaHBlYXJsLmNvbSJ9.PLgYedbf057RX7brsTlwXMih7Z3bL6b0g5cOGpB1FeQ"
                
        //     });
        // } else {
        //     this.headers = new Headers({
        //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        //         // "Authorization": (this.cache.get(this.constants.COGNITO_IDTOKEN)) ? this.cache.get(this.constants.COGNITO_IDTOKEN).jwtToken : ""
        //         "Authorization": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2NjZiZDBjYi1mZjIxLTQ2ZWUtYmE3NC1kODcyMWJlOTlmZGUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZ2VuZGVyIjoiTWFsZSIsImlzcyI6Imh0dHBzOi8vY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb20vdXMtZWFzdC0xX3RZQmNDWHp0VyIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6ImFkbWluLm5pa2libHUiLCJnaXZlbl9uYW1lIjoiSGFyaSIsImF1ZCI6IjY0bjUxZGNhYXE0ZzZiN3ExbW1qZGdvMzRmIiwiZXZlbnRfaWQiOiI4NjRhODQ3My1iNGRlLTExZTgtYjQ2Yi1hNzc5MjE1YWFhMzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTUzNjU3Mjg0NiwicGhvbmVfbnVtYmVyIjoiKzkxNzAxOTcwMzMyMSIsImV4cCI6MTUzNjU2Njc2NCwiaWF0IjoxNTM2NTcyODQ2LCJmYW1pbHlfbmFtZSI6IkJhYnUiLCJlbWFpbCI6ImhhcmliYWJ1LmphbmphbGFAdGVjaHBlYXJsLmNvbSJ9.PLgYedbf057RX7brsTlwXMih7Z3bL6b0g5cOGpB1FeQ"
        //     });
        // }

        this.options = { headers: this.headers };
    }

    public getOptions(): any {
        return this.options;
    }
}


// old

// import { Injectable, Inject } from "@angular/core";
// import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/catch";
// import "rxjs/add/operator/map";
// import "rxjs/add/observable/throw";
// import { Constants } from "./constants";
// import { CacheService } from "./cache.service";
// import { environment } from "../../environments/environment";

// @Injectable()
// export class DataService {

//     private headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" });

//     constructor(
//         private http: HttpClient,
//         private constants: Constants,
//         private cache: CacheService
//     ) {
//         console.log("Hello dataservice Provider");
//         // cache.put(constants.COGNITO_IDTOKEN, sessionStorage.getItem(constants.COGNITO_IDTOKEN));
//         cache.put(constants.COGNITO_IDTOKEN, sessionStorage.getItem(constants.COGNITO_IDTOKEN));
        
//         cache.put(constants.COGNITO_ACCESSTOKEN,sessionStorage.getItem(constants.COGNITO_ACCESSTOKEN));
//         cache.put(constants.COGNITO_REFRESHTOKEN, sessionStorage.getItem(constants.COGNITO_REFRESHTOKEN));
//         this.resetAuthHeader();
//     }

//     // post data with params and body
//     public postData(uri: string, bodyString?: string) {
//         return this.http.post(uri, bodyString, { headers: this.headers }).catch(this.handleError);
//     }

//     // get data with params
//     public getData(uri: string) {
//         return this.http.get(uri, { headers: this.headers }).catch(this.handleError);
//     }

//     private handleError(error: Response | any) {
//         return Observable.throw(error);
//     }

//     public resetAuthHeader() {

//         // We do not want to set Authorization header in SAM Local environment
//         // As we get CORS issue

//         const auth = this.cache.get(this.constants.COGNITO_IDTOKEN);
//         console.log("auth = ", auth);
//         const auth1 = sessionStorage.getItem(this.constants.COGNITO_IDTOKEN)
//         console.log("session auth = ", auth1)

//        // var abc = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjZiZDBjYi1mZjIxLTQ2ZWUtYmE3NC1kODcyMWJlOTlmZGUiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZ2VuZGVyIjoiTWFsZSIsImlzcyI6Imh0dHBzOi8vY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb20vdXMtZWFzdC0xX3RZQmNDWHp0VyIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6ImFkbWluLm5pa2libHUiLCJnaXZlbl9uYW1lIjoiSGFyaSIsImF1ZCI6IjY0bjUxZGNhYXE0ZzZiN3ExbW1qZGdvMzRmIiwiZXZlbnRfaWQiOiIwODc2MmU3ZS1iNGQwLTExZTgtYjQ2Yi1hNzc5MjE1YWFhMzAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTUzNjU2NjYyMiwicGhvbmVfbnVtYmVyIjoiKzkxNzAxOTcwMzMyMSIsImV4cCI6MTUzNjU2Njc2NCwiaWF0IjoxNTM2NTY2NjIyLCJmYW1pbHlfbmFtZSI6IkJhYnUiLCJlbWFpbCI6ImhhcmliYWJ1LmphbmphbGFAdGVjaHBlYXJsLmNvbSJ9.VwFc1CPx6qX2YKXAZjjj96Ej9Av-Ud8zXF7_9LwYm8g";

//         if (environment.nonproduction.local === true) {
//             this.headers = new HttpHeaders({
//                 "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
//                 "Authorization": (this.cache.get(this.constants.COGNITO_IDTOKEN)) ? this.cache.get(this.constants.COGNITO_IDTOKEN) : "",
//                 //"Authorization": abc,
//             });
//         } else {
//             this.headers = new HttpHeaders({
//                 "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
//                 "Authorization": (this.cache.get(this.constants.COGNITO_IDTOKEN)) ? this.cache.get(this.constants.COGNITO_IDTOKEN) : "",
//                 //"Authorization": abc
//             });
//         }
//     }

//     // public getOptions(): any {
//     //     return this.options;
//     // }
// }
