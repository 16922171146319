import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';

@Injectable()
export class DetailedQuestionService {
  constructor() {}

  getQuestions(): Observable<any[]> {
    const q = [
      {
        title: "1. Does you child enjoy learning new languages?",
        type: "Linguistic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "1"
      },
      {
        title: "2. Can your child tell a story confidently in another language?",
        type: "Linguistic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "2"
      },
      {
        title: "3. Does your child enjoy writing and/or listening to stories?",
        type: "Linguistic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "3"
      },
      {
        title: "4. Does your child enjoy playing word games and making up poetry?",
        type: "Linguistic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "4"
      },
      {
        title: "5. Does your child confidently express opinions?",
        type: "Linguistic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "5"
      },
      {
        title: "6. Does your child enjoy designing new games?",
        type: "Logic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "6"
      },
      {
        title: "7. Does your child enjoy solving logical challenges such as puzzles and games (chess, crosswords and suduko)?",
        type: "Logic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "7"
      },
      {
        title: "8. Does your child enjoy working on new projects at school, building/creating something with their own hands?",
        type: "Logic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "8"
      },
      {
        title: "9. Does your child enjoy space videos / books or science project experiments?",
        type: "Logic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "9"
      },
      {
        title: "10. Is your child organized and loves to plan ahead?",
        type: "Logic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "10"
      },
      {
        title: "11. Can your child easily rearrange shapes, patterns as required, such as in Rubik's cube, puzzles and lego?",
        type: "Spatial",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "11"
      },
      {
        title: "12. Can your child understand and corelate number, visual and color concepts?",
        type: "Spatial",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "12"
      },
      {
        title: "13. Can your child make decisions on their own with respect to their day to day activities?",
        type: "Intrapersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "13"
      },
      {
        title: "14. Does your child understand, accept and appreciate their own strengths and weaknesses?",
        type: "Intrapersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "14"
      },
      {
        title: "15. Does your child love dancing?",
        type: "Kinesthetic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "15"
      },
      {
        title: "16. Does your child prioritize outdoor games to video games?",
        type: "Kinesthetic",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "16"
      },
      {
        title: "17. Does your child feel comfortable in making new friends?",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "17"
      },
      {
        title: "18. Does your child enjoy being on their own to being in a group?",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "18"
      },
      {
        title: "19. Does your child provide a helping hand to those in need without being asked?",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "19"
      },
      {
        title: "20. Does your child connect and work comfortably in group tasks?",
        type: "Interpersonal",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "20"
      },
      {
        title: "21. Does your child love to take care of animals, plants and nature in general?",
        type: "Naturalist",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "21"
      },
      {
        title: "22. Does your child love outings at zoos, parks and beaches?",
        type: "Naturalist",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "22"
      },
      {
        title: "23. Does your child love listening to music?",
        type: "Musical",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "23"
      },
      {
        title: "24. Does your child play or show interest in playing a musical instrument?",
        type: "Musical",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "24"
      },
      {
        title: "25. Does your child create their own music?",
        type: "Musical",
        selected_answer: [
          {
            option: "Strongly disagree",
            value: "0"
          },
          {
            option: "Disagree",
            value: "1"
          },
          {
            option: "Somewhat disagree",
            value: "2"
          },
          {
            option: "Neutral",
            value: "3"
          },
          {
            option: "Agree",
            value: "4"
          },
          {
            option: "Strongly agree",
            value: "5"
          }
        ],
        questionId: "25"
      }
    ];
    return of(q);
  }
}
