import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ChatClientService, ChannelService, ChannelPreviewContext, CustomTemplatesService, DefaultStreamChatGenerics, EmojiPickerContext } from 'stream-chat-angular';
import { Channel, StreamChat } from 'stream-chat';
import { TeacherListService } from './teacher-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StudentListService } from './student-list.service';
// dynamic implement streamapikey by Kunal bansal on 14 july 2022 start
import { environment } from 'src/environments/environment';
import { NgbNavChangeEvent, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
// .......end.......

@Component({
  selector: 'app-streamio',
  templateUrl: './streamio.component.html',
  styleUrls: ['./streamio.component.scss']
})

export class StreamIoComponent implements OnInit,AfterViewInit {
  public userInfo;
  public teacherList = [];
  public studentList = [];
  channelname: string = '';
  activechatwindow: number = 1;
  uniquechannelid: string = '';
  checkurl: number | undefined;
  active = 1;
  activeChannel: Channel<DefaultStreamChatGenerics> | undefined;
  @ViewChild('channelPreviewTemplate')
  private channelPreviewTemplate!: TemplateRef<ChannelPreviewContext>;
  @ViewChild('teacherTabset') teacherTabset;
  @ViewChild('studentTabset') studentTabset;
  @ViewChild('channelListComponent') channelListComponent;

  constructor(private chatService: ChatClientService, private channelService: ChannelService, private teacherListService: TeacherListService, private studentListService: StudentListService, private spinner: NgxSpinnerService,
    private customTemplatesService: CustomTemplatesService) {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    // ..................important note if url is teachers checkurl remain o and if students checkurl remain 1.........
    if (window.location.href.includes('teachers')) {
      this.checkurl = 0;
      let user1 = {
        id: this.userInfo.key.studentID,
        name: this.userInfo.firstName + " " + this.userInfo.lastName,
        timeStamp: this.userInfo.key.timestamp
      }
      console.log('user',user1)
      const chatClient = StreamChat.getInstance(environment.getstreamapikey);
      const apiKey = environment.getstreamapikey;
      this.chatService.init(apiKey, user1, chatClient.devToken(user1.id));
    }
    else {
      this.checkurl = 1;
      let user1 = {
        id: this.userInfo.key.teacherID,
        name: this.userInfo.firstName + " " + this.userInfo.lastName,
        timeStamp: this.userInfo.key.timeStamp,
      }
      console.log('get stream user',user1)
      const chatClient = StreamChat.getInstance(environment.getstreamapikey);
      const apiKey = environment.getstreamapikey;
      this.chatService.init(apiKey, user1, chatClient.devToken(user1.id));
    }
    // ...........................end......................

    this.channelService.activeChannel$.subscribe((c) => {
      this.activeChannel = c;
      console.log('active channel',c)
      const capabilities = this.activeChannel?.data
        ?.own_capabilities as string[];
      if (!capabilities) {
        return;
      }
      
    });
  
  }


  ngOnInit() {
    this.spinner.show();
  // ................function for fetching channel on based  of passed user ids by kunal bansal on 07 july 2022 start.........
    this.channelService.init({
      type: 'messaging',
      // members: { $in:[ 'e28053f9-7ef9-474f-9015-60682bab231e']}
      members: { $in:[this.checkurl == 0 ? this.userInfo.key.studentID : this.userInfo.key.teacherID]}
    })
    // .............end.........
    this.checkurl == 0 ? this.getteachers() : this.getstudents();
    
  }
 
  async checkopenwindow(channelcode: string, userid?: string) {
    const chatClient = StreamChat.getInstance(environment.getstreamapikey);

    console.log('upsert', this.channelname)
    await chatClient.upsertUsers([{ id: userid, name: this.channelname }])
    let date = new Date();
    const channel = this.chatService.chatClient.channel("messaging", channelcode, {
      name: this.channelname,
      
      // name: channelcode + date,
      members: [this.checkurl == 0 ? this.userInfo.key.studentID : this.userInfo.key.teacherID, userid]
    })
    // await channel.updatePartial({ set: { config_overrides: { grants: {
    //   user: ["!add-links", "create-reaction"],
    // }, } } });

    await channel.watch();
    try {
      const channel2 = this.chatService.chatClient.channel("messaging", channelcode)
      this.channelService.setAsActiveChannel(channel2);
    }
    catch (error) {
      console.log(error)
      //  Block of code to handle errors
    }

  }

  async changechannel(username: string, userid: string, usertimestamp: string, channelid: string) {
    // this.activechatwindow = 1;
    console.log()
    this.uniquechannelid = channelid;
    this.channelname = username;
    this.checkopenwindow(channelid, userid);
  }
 
  getteachers() {
    this.spinner.show();
    const payload = {
      studentID: this.userInfo.key.studentID,
      timestamp: this.userInfo.key.timestamp
    }
    this.teacherListService.getTeachers(payload).subscribe(
      (res) => {
        this.teacherList = res.data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getstudents() {
    this.spinner.show();
    const payload = {
      teacherID: this.userInfo.key.teacherID,
      timeStamp: this.userInfo.key.timeStamp
    }
    this.studentListService.getStudents(payload).subscribe(
      (res) => {
        this.studentList = res.data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  ngAfterViewInit(): void {
    this.customTemplatesService.channelPreviewTemplate$.next(
      this.channelPreviewTemplate
    );
    this.channelListComponent.channels$.subscribe((channels) => {
      if (channels && channels.length > 0) {
        this.uniquechannelid = channels[0].id;
        console.log('Initial Channel ID:', this.uniquechannelid);
      }
    });    
  }

  switchTeacherTab(id: string) {
    this.teacherTabset.select(id);
  }

  switchStudentTab(id: string) {
    this.studentTabset.select(id);
  }
  setChannel(channelId){
    this.uniquechannelid = channelId;
  }

  moveTab(event){
    if(event == true){
      if(this.checkurl==0)
      this.switchTeacherTab('tab1');
      else
      this.switchStudentTab('tab1');
      this.gotoTop();
    }
  }
  gotoTop() {
    // window.scrollTo(0,0)
    const element = document.getElementById("chat-wrapper");
    element.scrollTo(0,0);
  }

}
