import { Component, OnInit ,Input, Output, EventEmitter, ChangeDetectionStrategy} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarousel } from 'ngx-owl-carousel';
import { NgxSpinnerService } from "ngx-spinner";
import { HomeLatestService } from './home-latest.service';
import { Subscription, interval } from 'rxjs';
import { FooterNavbarSharedService } from '../shared/footer-navbar-shared/footer-navbar-shared.service';
import { NewModalModule } from '../_modal';
import { NewModalConfettiModule } from '../_modal-confetti';
// import * as confetti from 'canvas-confetti';
// import { ConfettiService } from '../services/confetti.service';
// import ConfettiGenerator from 'confetti-js';
// import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import{GeolocationService} from '../CountryService/CountryService.service';

@Component({
  selector: 'app-home-latest',
  templateUrl: './home-latest.component.html',
  styleUrls: ['./home-latest.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // animations: [
  //   trigger('pop', [
  //     state('void', style({
  //       transform: 'scale(0)'
  //     })),
  //     transition('void => *', [
  //       animate('300ms ease-out', style({
  //         transform: 'scale(1)'
  //       }))
  //     ])
  //   ])
  // ]
})
export class HomeLatestComponent implements OnInit {
  public courseName: any;
  slider: any;
  homeData = [];
  courseData: any;
  whyTrusityData: any;
  enrollData: any;
  workshopData: any;
  secThirteenData: any;
  featureData: any;
  videoSection = [];
  secEightData = [];
  testimonials = [];
  secTenData = [];
  secOrganizationData = [];
  secElevenData = [];
  secTwelveData: any;
  videoSectionLeft : any;
  videoSectionRight : any;
  slideList: any;
  sliderList: any = [];
  courseResponse: any;
  featureResponse: any;
  descData: any;
  activeLanguage;
  actLanguage;
  nodata = false;
  languageList = [];
  private subscription: Subscription;
  public dateNow = new Date();
  public dDay = new Date('Feb 26 2022 10:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;
  displayStyle = "none";
 
  top_slider: OwlOptions = { 
    loop: true,
    dots: true,
    navSpeed: 200, 
    items:1,
    nav: true,
    autoplay:true,
    autoplayTimeout:5000,
    autoplayHoverPause:true,
    slideTransition: 'linear',
    responsive: {
    0: {
        items:1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1
      }
    },
  }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:30,
    // center:true,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items:1,
        stagePadding: 25,
        margin:10,
      },
      400: {
        items: 2,
        stagePadding: 25,
        margin:10,
      },
      740: {
        items: 2,
        stagePadding: 20,
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  review_slider: OwlOptions = {
    loop: true,
    dots: true,
    // navSpeed: 200, 
    items:1,
    margin: 30,
    // autoplay:true,
    autoplayHoverPause:true,
    responsive: {
      0: {
        items:1,
      },
      400: {
        items: 1,
      },
      770: {
        items: 2,
      },
      992: {
        items: 1,
      }
    },
  }


  logoOption: OwlOptions = {
    loop: true,
    dots: false,
    navSpeed: 700,
    margin:0, 
    items: 8,
    //navText: ['', ''],
    
    responsive: {
      0: {
        items: 2,
        stagePadding: 25,
        margin:10,
      }, 
      400: {
        items: 2,
       stagePadding: 25,
        margin:10,
      },
      740: {
        items: 3,
        stagePadding: 25,
        margin:10,
      },
      940: {
        items: 5
      }
    },
    nav: true,
    
  }
  
constructor( private homePageService: HomeLatestService,private geolocationService: GeolocationService, private spinner: NgxSpinnerService, private router: Router, private fooService: FooterNavbarSharedService, private meta: Meta, private titleService: Title) {
    this.fooService.currentDataFetch.subscribe(res => {
    if(res){
      this.actLanguage = res;
      this.getHomepage();
    }
  })
  
}

ngOnInit(): void {
  this.titleService.setTitle('Trusity Your One Stop Learning Space');
  this.meta.addTags([
  {name: 'keywords', content: 'summer camps for kids dubai, coding for kids, robotics for kids, artificial intelligence course, coding classes for kids dubai, arts and crafts dubai, Robotics Classes for Kids in Dubai, app development for kids in dubai, arabic language for kids dubai, online coding classes for kids in dubai, Artificial Intelligence Courses for Kids in Dubai, writing courses for kids in dubai, abacus classes for kids Dubai, English language classes for kids in Dubai, entrepreneurship course for kids, french classes for kids, space learning for kids in dubai, mental mathematics for kids, leadership programs for kids, public speaking course for kids'},
  {name: 'description', content: "Looking for Summer Camps for Kids in Dubai? Trusity offers top-notch programs, fun-filled activities, and a nurturing atmosphere. secure your spot now!"},
  {name: 'robots', content: 'index, follow'}
  ]);
  // const canvas = document.getElementById('confetti-canvas');
  // this.confettiGenerator = new ConfettiGenerator({ target: canvas });
  // this.confettiGenerator.render();

    this.openPopup();
    sessionStorage.clear()
    this.getHomepage();
    this.subscription = interval(1000)
          .subscribe(x => { this.getTimeDifference(); });
          this.geolocationService.getCurrentLocation().then(coords => {
            // Handle the user's location coordinates here
            this.handleLocation(coords.latitude, coords.longitude);
          }).catch(error => {
            // Handle geolocation error
            console.error('Geolocation error:', error);
          });
        }
        handleLocation(latitude, longitude) {
            // Define the latitude and longitude boundaries for India
            const indiaLatitudeMin = 6.5000;
            const indiaLatitudeMax = 35.5000;
            const indiaLongitudeMin = 68.7000;
            const indiaLongitudeMax = 97.4000;
          
            // Check if the user is in India
            const isInIndia =
              latitude >= indiaLatitudeMin &&
              latitude <= indiaLatitudeMax &&
              longitude >= indiaLongitudeMin &&
              longitude <= indiaLongitudeMax;
    
            if (isInIndia) {
              this.router.navigate(['/home-india']);
              console.log("Welcome to India");}
            else {
              this.router.navigate(['']);
              console.log("Location not in India");
            }
}

openPopup() {
  this.displayStyle = "block";
}

closePopup(){
 this.displayStyle = "none";
}

// openConfetti() {
//   this.displayConfetti = "block";
// }

// closeConfetti(){
//  this.displayConfetti = "none";
// }


private getTimeDifference () {
      this.timeDifference = this.dDay.getTime() - new  Date().getTime();
      this.allocateTimeUnits(this.timeDifference);
}

private allocateTimeUnits (timeDifference) {
      this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
      this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
      this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
      this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
}

truPreneursClick() {
  // window.open("https://forms.zohopublic.com/trusity/form/truIntel/formperma/eE6mqqcIVWy-BPWnJY-HRstO5L49zJzRJqR0PyQPDqE");
  this.router.navigate(['/proficiency']);
  window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
        "event": "click_take_proficiency_test",
        });
  window['dataLayer'] = window['dataLayer'] || {};
}

truPreneursRedirect(){
  this.router.navigate(['/courses/truPreneurs']);
}

getCourse(courseName) {
  // console.log("Course Name", courseName);
  this.courseName = courseName.split(/[ ]+/);
  this.courseName = this.courseName.join("-");
  // console.log("test  ",this.courseName );
  // this.router.navigate(["/courses"], { queryParams: { courseName: this.courseName} });
  this.router.navigate([`/courses/${this.courseName}`]);
}

resetLanguage(){
  this.slider  = [];
  console.log(this.slider);
  this.courseData = null
  this.courseResponse  = null;
  this.sliderList  = null;
  this.enrollData  = null;
  this.whyTrusityData  = null;
  this.workshopData  = null;
  this.secThirteenData = null;
  this.featureData  = null;
  this.featureResponse  = null;
  this.descData  = null;
  this.videoSection  = null;
  this.videoSectionLeft  = null;
  this.videoSectionRight  = null;
  this.secEightData  = null;
  this.testimonials  = null;
  this.secTenData  = null;
  this.secOrganizationData = null;
  this.secElevenData  = null;
  this.secTwelveData = null;
}
  
getHomepage() {
  this.spinner.show();
  if(sessionStorage.getItem('activeLanguage')){
    this.actLanguage = sessionStorage.getItem('activeLanguage');
  } else{
    sessionStorage.setItem('activeLanguage', 'English');
    this.actLanguage = 'English'
  }
  let defaultLanguage;
  const payload = {
    getDataByLanguage: this.actLanguage
  };
  this.homePageService.getHomepage(payload).subscribe(
    (res) => {
      this.homeData = res.data;
      defaultLanguage = res.language;
      if(res.data != null){
        this.spinner.hide();
        if(this.nodata == true){
          this.nodata = false;
        }
        for (let i = 0; i < this.homeData.length; i++) {
          if (this.homeData[i].sectionName == "sectionOne") {
            this.slider = this.homeData[i][defaultLanguage].content.images;
          }
          if (this.homeData[i].sectionName == "sectionTwo") {
            this.courseData = this.homeData[i][defaultLanguage].content;
            this.courseResponse = this.homeData[i][defaultLanguage].content.catMain;
            if (this.courseResponse.length > 0){
              this.sliderList = this.courseResponse[0].slideList;
            }
          }
          if (this.homeData[i].sectionName == "sectionThree") {
            this.enrollData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionFour") {
            this.whyTrusityData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionFive") {
            this.workshopData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionThirteen") {
            this.secThirteenData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionSix") {
            this.featureData = this.homeData[i][defaultLanguage].content;
            this.featureResponse = this.homeData[i][defaultLanguage].content.catLeft;
            if (this.featureResponse.length > 0){
              this.descData = this.featureResponse[0].circleDesc;
            }
          }
          if (this.homeData[i].sectionName == "sectionSeven") {
            // console.log(this.homeData[i][defaultLanguage].content)
            this.videoSection = this.homeData[i][defaultLanguage].content;
            this.videoSectionLeft = this.homeData[i][defaultLanguage].content.catLeft[0];
            this.videoSectionRight = this.homeData[i][defaultLanguage].content.catRight;
          }
          if (this.homeData[i].sectionName == "sectionEight") {
            this.secEightData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionNine") {
            this.testimonials = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionTen") {
            this.secTenData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionOrganization") {
            this.secOrganizationData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionEleven") {
            this.secElevenData = this.homeData[i][defaultLanguage].content;
          }
          if (this.homeData[i].sectionName == "sectionTwelve") {
            this.secTwelveData = this.homeData[i][defaultLanguage].content;
          }
        }
      }
      else{
        this.resetLanguage();
        this.nodata = true;
        this.slider = null;
        this.spinner.hide();
      }
    },
    (error) => {
      console.log(error);
      this.spinner.hide();
    }
  );
}

}
