import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs";
import { Subject } from "rxjs/Subject";
import { Router, ActivatedRoute } from "@angular/router";
import { ToasterService } from "angular2-toaster";
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from "@angular/common/http";
import * as _ from "lodash";
import { Constants } from "./constants"; //./environment-variables/environment-variables.module.ts";
import { DataService } from "./data.service";
// import { AlertService } from "./confirmAlert.service";
import { UrlConstants } from "./url.constants";
import { CacheService } from "./cache.service";


@Injectable()
export class HelperService {

    public conferenceID;
    public enableScrollDown;
    public c = 0;
    public result = [];

    constructor(
        private router: Router,
        private constants: Constants,
        private toasterService: ToasterService,
        private dataservice: DataService,
        // private alertService: AlertService,
        private urlConstants: UrlConstants,
        private cacheService: CacheService
    ) { }

    // api call error handler
    public errorHandler(status, resText) {
        if (status === 400 || status === 500) {
            // bad request         
            this.toasterService.pop(this.constants.TOAST_TYPE_ERROR, "", resText);
        } else if (status === 401) {
            // unauthorized
            this.toasterService.pop(this.constants.TOAST_TYPE_ERROR, this.constants.ERROR_UNAUTHORIZED, "");
            //this.redirectLogin();
        } else if (status === 0) {
            // internet disconnected
            this.toasterService.pop(this.constants.TOAST_TYPE_ERROR, "", this.constants.ERROR_NET_DISCONNECT);
            //this.redirectLogin();
        } else if (status != 200 || status != 201) {
            // failed
            this.toasterService.pop(this.constants.TOAST_TYPE_ERROR, this.constants.ERROR_SERVER_CALL_FAIL, "");
        }
    }

    public redirectLogin() {
        setTimeout(() => {
            return this.router.navigate(["/login"]);
        }, 1500);
    }


    public objToParam(obj) {

        return Object.keys(obj).reduce(function (str, key, i) {
            let delimiter, val;
            delimiter = (i === 0) ? '?' : '&';
            key = encodeURIComponent(key);
            val = encodeURIComponent(obj[key]);
            return [str, delimiter, key, '=', val].join('');
        }, '');
    }

    public getDatabaseTimezone(zone){
        // return databse timezone of a timezone
        var dbzone ;

        for (var key in this.constants.timezones) {
            if (this.constants.timezones[key].zone == zone) {
                dbzone = this.constants.timezones[key].dbzone;
            }
          } 
          return dbzone
    }

    // public getNewTokens(): any {
    //     const self = this;
    //     var token: any;
    //     console.log("1111111111111111111 = ",this.cacheService.get(this.constants.COGNITO_REFRESHTOKEN))
    //     token = {
    //         "refreshToken": this.cacheService.get(this.constants.COGNITO_REFRESHTOKEN)
    //     };
    //     console.log("token = ",token);
    //     return self.dataservice.postData(self.urlConstants.getUri(self.urlConstants.getNewTokens), token)
    //         .flatMap(
    //             res => {
    //                 console.log("new token in helper = ", res);
    //                 // console.log("id token = ",res.AuthenticationResult.IdToken);
    //                 this.cacheService.put(this.constants.COGNITO_IDTOKEN, res.AuthenticationResult.IdToken);
    //                 this.cacheService.put(this.constants.COGNITO_ACCESSTOKEN, res.AuthenticationResult.AccessToken);
    //                 sessionStorage.setItem(this.constants.COGNITO_IDTOKEN, res.AuthenticationResult.IdToken);
    //                 sessionStorage.setItem(this.constants.COGNITO_ACCESSTOKEN, res.AuthenticationResult.AccessToken);
    //                 //this.dataservice.resetAuthHeader();
    //                 return Observable.of(res.AuthenticationResult.IdToken);
    //             });
    // }


    // public add(params, postData) {
    //     var self = this;
    //     //make an api call to create data
    //     return self.dataservice.postData(self.urlConstants.getUri(self.urlConstants.create, params),  postData)
    //         .flatMap(
    //             res => {
    //                 self.toasterService.pop(self.constants.TOAST_TYPE_SUCCESS, "", self.constants.SUCCESS_CREATE);
    //                 console.log("Success!!");
    //                 return Observable.of(res);
    //             })
    // }

    // public partialAdd(params, postData) {
    //     var self = this;
    //     //make an api call to create data
    //     return self.dataservice.postData(self.urlConstants.getUri(self.urlConstants.partialCreate, params), postData)
    //         .flatMap(
    //             res => {
    //                 self.toasterService.pop(self.constants.TOAST_TYPE_SUCCESS, "", self.constants.SUCCESS_CREATE);
    //                 console.log("Success!!");
    //                 return Observable.of(res);
    //             })
    // }
  

    // public get(params) {
    //     var self = this;
    //     //make an api call to get data
    //     return self.dataservice.getData(self.urlConstants.getUri(self.urlConstants.getNonAuth, params))
    //         .flatMap(
    //             res => {
    //                 return Observable.of(res);
    //             })
    // }

    // public update(params, postData) {
    //     var self = this;
    //     //make an api call to create data
    //     return self.dataservice.postData(self.urlConstants.getUri(self.urlConstants.update, params),  postData)
    //         .flatMap(
    //             res => {
    //                 self.toasterService.pop(self.constants.TOAST_TYPE_SUCCESS, "", self.constants.SUCCESS_UPDATE);
    //                 console.log("Success!!");
    //                 return Observable.of(res);
    //             })
    // }
  
    // public delete(params) {
    //     var self = this;
    //     //make an api call to delete data
    //     return self.dataservice.postData(self.urlConstants.getUri(self.urlConstants.delete, params))
    //         .flatMap(

    //             res => {
    //                 self.toasterService.pop(self.constants.TOAST_TYPE_SUCCESS, "", self.constants.SUCCESS_DELETE);
    //                 console.log("Success!!");
    //                 return Observable.of(res);
    //             })
    // }

}


