<!-- <app-navbar-dashboard></app-navbar-dashboard> -->
<!-- ........................new chat header created and implemented by Kunal bansal on 15 july 2022 start -->
<app-chat-header></app-chat-header>
<!-- ................end............ -->
<!-- <app-sidebar></app-sidebar> -->
<ngx-spinner bdColor="rgba(230, 230, 230, 0.4)" size="large" color="#111F43"></ngx-spinner>
<main class="background">
  <!-- ........................new chat sidebar created and implemented by Kunal bansal on 15 july 2022 start -->
  <app-chat-sidebar></app-chat-sidebar>
  <!-- ......end.... -->
  <div class="chattestingzone">
    <div class="window-wrap">
      <div class="left-bar">
        <div *ngIf="checkurl==0">
          <h3 class="align-items">Teachers List</h3>
          <ngb-tabset #teacherTabset="ngbTabset" [justify]="'center'" class="custom-tab-content flex-column flex-md-row"
            type="pills">
            <ngb-tab id="tab1">
              <ng-template ngbTabTitle>
                <i class="fa fa-comments-o mr-2"></i> Chat
              </ng-template>
              <ng-template ngbTabContent>
                <div class="list" id="chat-wrapper">
                  <stream-channel-list #channelListComponent></stream-channel-list>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab id="tab2">
              <ng-template ngbTabTitle>
                <i class="fa fa-plus mr-2"></i> New Chat
              </ng-template>
              <ng-template ngbTabContent>
                <div class="list">
                  <div *ngFor="let list of teacherList"
                    (click)="changechannel(list.teacherName,list.teacherKey.teacherID,list.teacherKey.timeStamp,list.conversation_id)">
                    <div class="chat-box d-flex mousehover"
                      [ngStyle]="uniquechannelid==list.conversation_id?{'border-left':'2px solid #32325d'}:{'border-left':'2px solid #fff'}">
                      <div class="icon">{{list.initial}}</div>
                      <div class="info ml-3">
                        <h4 class="mb-0">{{list.teacherName}}</h4>
                        <p>{{list.className}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>

        </div>

        <div *ngIf="checkurl==1">
          <h3 class="align-items">Students List</h3>
          <ngb-tabset #studentTabset="ngbTabset" [justify]="'center'" class="custom-tab-content flex-column flex-md-row"
            type="pills">
            <ngb-tab id="tab1">
              <ng-template ngbTabTitle>
                <i class="fa fa-comments-o mr-2"></i> Chat
              </ng-template>
              <ng-template ngbTabContent>
                <div class="list" id="chat-wrapper">
                  <stream-channel-list #channelListComponent></stream-channel-list>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab id="tab2">
              <ng-template ngbTabTitle>
                <i class="fa fa-plus mr-2"></i> New Chat
              </ng-template>
              <ng-template ngbTabContent>
                <div class="list">
                  <div *ngFor="let list of studentList"
                    (click)="changechannel(list.studentName,list.studentKey.studentID,list.studentKey.timeStamp,list.conversation_id)">
                    <div class="chat-box d-flex mousehover"
                      [ngStyle]="uniquechannelid==list.conversation_id?{'border-left':'2px solid #32325d'}:{'border-left':'2px solid #fff'}">
                      <div class="icon">
                        {{list.initial}}
                      </div>
                      <div class="info ml-3">
                        <h4 class="mb-0">{{list.studentName}}</h4>
                        <p>{{list.className}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>

        </div>
      </div>
      <div class="right-bar d-flex justify-content-center align-items-center" *ngIf="activechatwindow==0">
        <h4>Click on user to start chat..</h4>
      </div>

      <div class="right-bar" *ngIf="activechatwindow==1">
        <stream-channel>
          <stream-channel-header></stream-channel-header>
          <!-- <h3>{{channelname}}</h3> -->
          <stream-message-list></stream-message-list>
          <app-customstreaminput (channelTab)="moveTab($event)" [channelid]="uniquechannelid"> </app-customstreaminput>
        </stream-channel>
      </div>
    </div>
  </div>

  <ng-template #channelPreviewTemplate let-channel="channel">
    <app-stream-channel-preview [channel]="channel" (channelEvent)="setChannel($event)"></app-stream-channel-preview>
  </ng-template>

</main>