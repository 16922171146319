import { Injectable } from '@angular/core';
import {ToasterService} from 'angular2-toaster';

@Injectable()
export class ToastMsgService {

  constructor(public toasterService: ToasterService) { 

  }

  dispalyMsg(toastMesage,timeout?){
    // ToastMessae -  is expected to be an object
    // var toast = {
    //   type: '',
    //   title: '',
    //   body:'',
    //   showClosseButton: true
    // };
    if(timeout){
      toastMesage['timeout'] = timeout
    }
    else{
      toastMesage['timeout'] = 2000
    }
    this.toasterService.pop(toastMesage)
    // this.toasterService.pop('success', 'Args Title', 'Args Body');
    

  }

}
