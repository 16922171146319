import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
// import * as AWS from 'aws-sdk/global';
// import * as S3 from 'aws-sdk/clients/s3';
import { Observable } from 'rxjs/Observable';
//import { promise } from 'selenium-webdriver';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap'
import { Constants } from './constants';

@Injectable()
export class UploadFileService {
  public inputFile: any;

  public uploadResult: any;

  public isDoc: boolean = false;

  constructor(
    private http: HttpClient,
    private constants: Constants
  ) { }

  uploadfile(s3preSignedUrl) {
    console.log("Inout file =", this.inputFile);
    console.log("isDoc = ", this.isDoc);

    let req;
    let headers: HttpHeaders;

    if (!this.isDoc) {
      headers = new HttpHeaders({ "Content-Type": this.inputFile.files[0].type, [this.constants.InterceptorSkipHeader]: " " });
      req = new HttpRequest('PUT', s3preSignedUrl, this.inputFile.files[0],
        {
          headers: headers
          // reportProgress: true,
        });
    }
    else {  // if doc
      headers = new HttpHeaders({ "Content-Type": this.inputFile.type, [this.constants.InterceptorSkipHeader]: " " });
      req = new HttpRequest('PUT', s3preSignedUrl, this.inputFile.doc,
        {
          headers: headers
          //  reportProgress: true,
        });
    }
    //console.log("req = ", req)

    return this.http.request(req)
      .flatMap((response) => {
        console.log(response);
        return Observable.of(response);
      });
  }
}

// hari

// public uploadfile(file) {

  //   const bucket = new S3(
  //     {
  //       accessKeyId: 'AKIAI4AMTYGXQGYLZESQ',
  //       secretAccessKey: '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',
  //       region: 'us-east-1'
  //     }
  //   );

  //   let randomNum = Math.floor((Math.random() * (9999999999 + 1)) + 1111111111);

  //   const params = {
  //     Bucket: 'nikiblu-dev-images',
  //     Key: randomNum + file.name,
  //     Body: file,
  //     ACL: 'public-read'
  //   };
  //   console.log("image par = ",params)

  //   var bucketPromise = bucket.upload(params).promise();
  //   return bucketPromise.then(function (data) {
  //     return Observable.of({ data })
  //   })

  // }