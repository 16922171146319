import { Component, OnInit, HostListener} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { UserLoginService } from "../../services/user.service";
import { LoggedInUserInfo } from '../../model/LoggedInUserInfo';

@Component({
    selector: 'app-set-navbar',
    templateUrl: './navbar-set.component.html',
    styleUrls: ['./navbar-set.component.scss'],
    providers: []
})
export class NavbarSetComponent implements OnInit {

    scrolled: boolean = false;

    @HostListener("window:scroll", [])
    onWindowScroll() {
    this.scrolled = window.scrollY > 0;
    }

    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    filter: any;
    userName;
    userInfo: LoggedInUserInfo;

    constructor(public location: Location, private router: Router,
        private userService: UserLoginService) {
            
    }

    ngOnInit() {
        this.userInfo = this.userService.getUserInfo();
        console.log("userInfo ==>  ",this.userInfo);
        if(this.userInfo != null){
            this.userName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
            console.log("userName ==>  ",this.userName);
        }
        
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
    }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === 'home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === 'documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    
    showFooter() {
        return this.router.url === '/dashboard';
    }

    hideFooter() {
        return this.router.url === '/avialable-course';
    }

    hideFooterSchedule() {
        return this.router.url === '/scheduler';
    }

    hideFootermyChildCalender() {
        return this.router.url === '/children-calender';
    }
    hideInvoices() {
        return this.router.url === '/invoices';
    }

    hideEditAccount() {
        return this.router.url === '/edit-account';
    }

    hideAddChild() {
        return this.router.url === '/add-child';
    }

    hideFooterChangePwd() {
        return this.router.url === '/change-password';
    }

    sideoc() {
        if(document.getElementById("side-bar").style.display == "table"){
            document.getElementById("side-bar").style.display = "none";
            document.getElementById("show").style.visibility = "hidden";
            document.getElementById("show2").style.visibility = "visible";
            // document.getElementById("profile").style.display = "block";
        }
        else
        {   
            document.getElementById("side-bar").style.display = "table";
            document.getElementById("show").style.visibility = "visible";
            document.getElementById("show2").style.visibility = "hidden";
            // document.getElementById("profile").style.display = "none";
            }
    }

    side_enl(eid) {
        if(document.getElementById(eid).style.display == "table"){
            document.getElementById(eid).style.display = "none"
        }
        else{
            document.getElementById(eid).style.display = "table"
        }
    }
}

